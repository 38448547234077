import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from '../../../core/services';
import { ContentItemModel } from '../../../shared/models/contentItem.model';
import { take } from 'rxjs/operators';
import { CTAModel } from 'src/app/shared/models/callToAction.model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit {
  content: ContentItemModel;
  data: any;
  callToAction: CTAModel.CallToActionFields;

  constructor(private readonly commonService: CommonService,
    private readonly ngxService: NgxUiLoaderService

  ) { }

  ngOnInit(): void {
    this.ngxService.start();
    this.commonService.getContentItem(this.data)
      .pipe(take(1))
      .subscribe(r => {
        this.content = r;
        this.callToAction = this.content.ctAsList[0];
        this.ngxService.stop();
      });
  }

}
