@if (content != null ) {
  <div class="quote-content">
    @if (content != null && content.contentItemTitle != null) {
      <h2 class="header-title h3" [innerHTML]="content.contentItemTitle | safeHtml"
      ></h2>
    }
    @if (content != null && content.contentItemBody != null) {
      <div class="m-b-20 quote-content-message">
        <p [innerHTML]="content.contentItemBody | safeHtml"></p>
      </div>
    }
  </div>
}

@if (callToAction) {
  <div>
    <div class="row d-sm-none">
      <div class="col-12">
        <app-aon-cta [callToAction]="callToAction"></app-aon-cta>
      </div>
    </div>
    <div class="float-start d-none d-sm-block">
      <app-aon-cta [callToAction]="callToAction"></app-aon-cta>
    </div>
  </div>
}