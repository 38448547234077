function triggerLinkOsso(ssoID) {
  let userAccessToken = JSON.parse(sessionStorage.getItem('currentUser'));
  let curLanguageCode = JSON.parse(localStorage.getItem('SelectedLanguage'));
  let currentCulture = curLanguageCode.cultureCode;
  let stateData = "";
  let tPRequestItem = [];
  sendOSSOForm(ssoID, userAccessToken.access_token, currentCulture, stateData, tPRequestItem);
}

function sendOSSOForm(id, acessToken, culture = 'en-us', stateData = null, tPRequestItem = []) {
  let clientSsoId = id;
  let takeOverBrowser = false;
  if (id) {
    let idArr = id.split('|');
    if (idArr.length > 1) {
      clientSsoId = idArr[0];
      takeOverBrowser = (idArr[1] == 'True');
    }
  }
  const OssoViewModel = {
    culture: culture,
    stateData: stateData,
    TPRequestItem: tPRequestItem,
    ssoID: clientSsoId,
    userToken: acessToken,
    employee_EventRecordID: ''
  };
  const url = '/SSO/OSSOInner?SSOID=' + clientSsoId;
  const stateModel = JSON.stringify(OssoViewModel);
  const form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', url);
  if (sessionStorage.getItem('IsFromMobileApp') == 'true') {
    takeOverBrowser = true;
  }
  if (takeOverBrowser) {
    form.setAttribute('target', '_self');
  }
  else {
    form.setAttribute('target', 'OutboundExchange');
  }
  let el = document.createElement('input');
  el.setAttribute('type', 'hidden');
  el.setAttribute('name', 'ssoModelData');
  el.setAttribute('value', stateModel);
  form.append(el);
  document.querySelector('#ssoFormContainer').append(form);
  if (!takeOverBrowser) {
    window.open(
      '',
      'OutboundExchange',
      'height=600,width=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=yes, resizable=yes,location=no, status=no'
    );
  }
  form.submit();
  form.remove();
}

function jwtDecodeToken(token) {
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = jwtUrlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

function jwtUrlBase64Decode(str) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw 'Illegal base64url string!';
    }
  }
  return decodeURIComponent(escape(window.atob(output)));
}

////////////////////////////////////////////////////////////// Below comes from origial aon-customize-googleanalytics.js, we merge together for performance things////////////////////////////////


function gtag() {
  dataLayer.push(arguments);
}

function setDefaultStorage() {
  let ad_storage_default = analytics_storage_default = 'denied';
  if (window.OnetrustActiveGroups.indexOf('6') != -1) {
    analytics_storage_default = 'granted';
  }

  if (window.OnetrustActiveGroups.indexOf('C0004') != -1) {
    ad_storage_default = 'granted';
  }

  gtag('consent', 'default', {
    ad_storage: ad_storage_default,
    analytics_storage: analytics_storage_default,
  });

  setDefaultStorage = function () { };
}

function listenToConsentChanges() {
  if (window.OnetrustActiveGroups) {
    setDefaultStorage();
  }

  OneTrust.OnConsentChanged && OneTrust.OnConsentChanged(function (event) {
    let ad_storage = 'denied', analytics_storage = 'denied';

    if (event.detail.indexOf('6') != -1) {
      analytics_storage = 'granted';
    }

    if (event.detail.indexOf('C0004') != -1) {
      ad_storage = 'granted';
    }

    gtag('consent', 'update', {
      ad_storage: ad_storage,
      analytics_storage: analytics_storage,
    });
  });
}


////////////////////////////////////////////////////////////// Below are section download important file////////////////////////////////
async function getSecureFile(fileName, path = "/Secured-Documents") {
  if (isFromIOSApp()) {
    getSecureFileFromIOS(fileName, path);
  } else {
    getSecureFileFromNonIOS(fileName, path);

  }

}
async function getSecureFileFromNonIOS(fileName, path) {
  const baseUrl = window.BootstrapRequestContext().apiBaseUrl; 
  let curLanguageCode = JSON.parse(localStorage.getItem('SelectedLanguage'));
  let culture = curLanguageCode.cultureCode
  let siteName = window.BootstrapRequestContext().siteName;
  const url = `${baseUrl}${culture}/${siteName}/secureFile?path=${encodeURIComponent(path)}&fileName=${encodeURIComponent(fileName)}`;
    const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${currentUser.access_token}`,
               TimeZoneOffSet: new Date().getTimezoneOffset().toString(),
              'Accept-Language': curLanguageCode == null ? 'en-US' : curLanguageCode.cultureCode
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

      const data = await response.json();
      downLoadFileByBase64(data);
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}
async function getSecureFileFromIOS(fileName, path) {
  let curLanguageCode = JSON.parse(localStorage.getItem('SelectedLanguage'));
  let culture = curLanguageCode.cultureCode
  let siteName = window.BootstrapRequestContext().siteName;
  let downloadModel = { path: path, fileName: fileName, culture: culture, siteName: siteName };

  downloadFile('/Download/SecureFile', downloadModel);
}
function downLoadFileByBase64(response){
  const filename = response.document_File_Name;
  const downloadLink = document.createElement('a');
  downloadLink.href = `data:${response.document_MIMEType};headers=Content-Disposition: attachment; filename=${filename};base64,${response.base64}`;
  downloadLink.setAttribute('download', filename);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
// Usage example
//* sends a request to the specified url from a form. this will change the window location.
// * @param {string} path the path to send the post request to
//* @param {object} params the parameters to add to the url
//* @param {string} [method=post] the method to use on the form

function downloadFile(path, formparam, method = 'post') {

  const userAccessToken = JSON.parse(sessionStorage.getItem('currentUser'));
  formparam.userToken = userAccessToken.access_token;
  const formvalue = JSON.stringify(formparam);
  const params = { downloadModelData: formvalue };
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement('form');
  form.method = method;
  form.action = path;
  for (const key in params) {
    if (Object.hasOwn(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];
      form.appendChild(hiddenField);
    }
  }
  if (sessionStorage.getItem("IsFromMobileApp") == 'true') {
    form.setAttribute('target', '_self');
  }

  document.body.appendChild(form);
  form.submit();
  form.remove();
}
function isFromIOSApp() {
  if (sessionStorage.getItem("IsFromMobileApp") == 'true') {
    return sessionStorage.getItem("IsFromAndroidApp") != 'true';
  }
  return false;

}


////////////////////////////////////////////////////////////// End Download important file////////////////////////////////

