import { Component, Inject, Injector, Input, OnInit, OnDestroy } from '@angular/core';
import { GlobalObjectsService } from '../../shared/services/global-objects.service';
import { Product } from '../../shared/models/product.model';
import { NotificationService } from '../services/notification.service';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { BootstrapRequestContext } from 'tbs-typings';
import { CommonService } from 'src/app/core/services/common.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  products: Product[];
  @Input() FooterStyleSwitch: boolean;
  baseUrl: string;
  resourcestrings = {};
  uiConfig: UIConfigurationType;
  enableOneTrust = false;
  enableOneTrustOptionalCookies = false;
  privacyPolicyUrl: string;
  private productsSub: Subscription;
  private getMessageSub: Subscription;
  PRIVACY_POLICY_ROUTING_URL: string = "/home/privacy-policy";

  constructor(
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly injector: Injector,
    private readonly globalojectService: GlobalObjectsService,
    private readonly notificationService: NotificationService,
    private readonly commonService: CommonService,
    private readonly uiconfigrationService: UiconfigrationService) {
    super(injector, new ChildComponentOptions(true, false, true));
    this.addResourceStringKeys(this.resService.footerStringKeys);
    this.enableOneTrust = bootstrapRequestContext.enableOneTrust;
    this.enableOneTrustOptionalCookies = bootstrapRequestContext.enableOneTrustOptionalCookies;
  }

  ngOnInit(): void {
    this.privacyPolicyUrl = this.helperService.getPrivacyPolicyUrl();
    if (this.privacyPolicyUrl.includes(window.location.origin)) {
      this.privacyPolicyUrl = this.PRIVACY_POLICY_ROUTING_URL;
    }
    this.globalojectService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.baseUrl = this.helperService.getBaseUrl();
        this.uiConfig = this.uiconfigrationService.getUIConfiguration();
        if (!sessionStorage.getItem('uiConfigGuest')) {
          this.uiconfigrationService.getDefaultUIConfigrationFromService()
            .pipe(take(1))
            .subscribe(du => {
              if (du) {
                sessionStorage.setItem('uiConfigGuest', JSON.stringify(du));
              }
            });
        }
        if (!this.uiConfig.uiConfiguration.hideProducts) {
          this.productsSub = this.globalojectService.Products.subscribe(r => {
            this.products = r;
          });
        }
        this.loadResourceStringAsObservable()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(res => this.getResouceStringsAsObject(res));
        this.getMessageSub = this.notificationService.getMessage().subscribe(
          () => {
            this.getCurrentPathRoleSettingStatus(this.injector);
            this.loadResourceStringAsObservable()
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(res => this.getResouceStringsAsObject(res));
          }
        );
      }
    });
  }

  showLightFooter(): boolean {
    //When showLightFooter enabled, if user is on quote pages/benefit pages then we will show app-footer-light.
    //When showLightFooter enabled, if user not on quote pages/benefit pages then we will show app-footer.
    //When showLightFooter not enabled, irrespective of user flow we will show app-footer.
    if (this.uiConfig.uiConfiguration?.showLightFooter) {
      return this.helperService.isQuoteRoute() || this.helperService.isBenefitRoute();
    }
  }

  ngOnDestroy(): void {
    if (this.productsSub) {
      this.productsSub.unsubscribe();
    }
    this.getMessageSub.unsubscribe();
  }

}
