import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastService } from '../../shared/services/toast.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private readonly ngxService: NgxUiLoaderService,
    public toastService: ToastService) {
  }


  handleError(error: Error | HttpErrorResponse) {
    console.log(error);
    //this.toastService.show('Some unexpected error happens', { classname: 'bg-danger text-light', delay: 3000 });
    this.ngxService.stopAll();
  }

}
