import { Component, Injector, Input, OnInit } from '@angular/core';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { ShowDataByAmountType } from '../models/helper.service.model';
import { DependantNameType } from '../models/benefitReview.model';

@Component({
  selector: 'app-eoi-information',
  templateUrl: './eoi-information.component.html'
})
export class EoiInformationComponent extends TBSBaseComponent implements OnInit {

  @Input() options: {
    displayInCard?: boolean,
    currencyCode: string
  } = {
      displayInCard: false,
      currencyCode: ""
    };

  @Input() coverage: {
    employeeCoverage: {
      interim: string,
      requested: string
    },
    volume: {
      interim: string | number,
      requested: string | number,
      extra: {
        visibility: boolean,
        showDataByAmountTypeInterim: {
          planData: ShowDataByAmountType.plan
        },
        showDataByAmountTypeRequested: {
          planData: ShowDataByAmountType.plan,
          lineData?: ShowDataByAmountType.line
        }
      }
    },
    employeeContribution: {
      interim: string | number,
      requested: string | number,
      extra: {
        visibility: boolean,
        label: string
      }
    },
    employerContribution: {
      interim: string | number,
      requested: string | number,
      extra: {
        visibility: boolean,
        label: string
      }
    },
    totalContribution: {
      interim: string | number,
      requested: string | number,
      extra: {
        label: string
      }
    }
  };

  @Input() participants: Array<{
    interim: DependantNameType,
    requested: DependantNameType
  }>

  constructor(private readonly injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Benefit.ExpansionPanelTable.EOI.Coverage',
      'Benefit.ExpansionPanelTable.EOI.Costs',
      'Benefit.ExpansionPanelTable.EOI.Interim',
      'Benefit.ExpansionPanelTable.EOI.Requested',
      'Aon.BenefitDetails.YourCoverageTitle',
      'Benefit.ExpansionPanelTable.EOI.Volume',
      'Benefit.ExpansionPanelTable.EOI.Participants'
    ])
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }
}
