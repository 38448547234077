<div class="express-login">
  <div class="modal-header">
    <h3 class="modal-title">{{resourceStrings['Aon.Login.ContinueToCheckout']}}</h3>
    @if (!hideCloseButton) {
      <button type="button" class="close" title="" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="onClose()">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    }
  </div>
  <div class="modal-body">
    <div class="m-b-20">
      {{resourceStrings['Aon.Login.GuestLoginEnterEmailToReceiveOTP']}}
    </div>
    <div>
      <form [formGroup]="loginForm">
        <div class="form-group">
          @if (curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress) {
            <label for="username"
            [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Account.Email']}}</label>
            @if (f.username.invalid && (f.username.dirty || f.username.touched)) {
              <div class="alert contextual error">
                @if (f.username.errors.required) {
                  <div role="alert">
                    <i class="fas fa-exclamation-triangle"
                    aria-hidden="true"></i>{{resourceStrings['Aon.Account.EmailIsRequired']}}
                  </div>
                }
                @if (!f.username.errors.required) {
                  <div>
                    <i class="fas fa-exclamation-triangle"
                    aria-hidden="true"></i>{{resourceStrings['Aon.Account.InvalidEmail']}}
                  </div>
                }
              </div>
            }
            <input type="text" formControlName="username" id="username" class="m-b-8"
              [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
            }

            @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
              <div>
                <label>{{resourceStrings['Aon.Account.Email']}}<i class="edit-email fal fa-edit m-l-10 focus-visibility"
                  role="button" tabindex="0" (keyup.enter)="editEmail()" (click)="editEmail()"
                [attr.aria-label]="resourceStrings['Aon.Common.Edit']"></i></label>
                <span>{{f.username.value}}</span>
              </div>
            }
          </div>

          @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
            <div class="form-group m-b-20">
              <label for="password"
              [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.OneTimePassword']}}</label>
              @if (f.password.invalid && (f.password.dirty || f.password.touched)) {
                <div class="alert contextual error">
                  @if (f.password.errors.required) {
                    <div role="alert">
                      <i class="fas fa-exclamation-triangle"
                      aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                    </div>
                  }
                </div>
              }
              <aon-input-password formControlName="password" [id]="'password'"
                [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
              [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
              <div class="d-flex align-items-center m-t-8 resend-code">
                <a href="javascript:void(0);" (click)="getOTP()" role="button" class="m-r-8 btn"
                  [ngClass]="isResendCodeButtonDisabled? 'disabled':''">{{resourceStrings['Aon.Login.ResendCode']}}</a><span>{{countDownTimerForDisplay}}</span>
                </div>
              </div>
            }
            @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
              @if (showPrivacyPolicySection) {
                <div tabindex="0" (click)="acceptPrivacyPolicy()" class="checkbox-wrapper">
                  <input type="checkbox" [disabled]="!isPrivacyPolicyLinkClicked" [checked]="isPrivacyPolicyAccepted"
                    [attr.aria-label]="resourceStrings['Aon.Login.PrivacyPolicyReadClaim']" />
                    <label class="privacy-policy-checkbox secondary">
                      <span class="check-toggle" [ngClass]="{'error':(!isPrivacyPolicyAccepted)}"></span>
                      <span class="label content">{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimBeforeLink']}}<a
                        href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                      target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimAfterLink']}}<a
                      href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                    target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a></span>
                  </label>
                </div>
              }
              @if (showMarketingPreferenceSection && employeeServiceCommsPreferences?.length > 0 && uiConfig.uiConfiguration.enableMarketingConsent) {
                <div
                  (click)="isMarketingSelectionSel=!isMarketingSelectionSel" tabindex="0" class="checkbox-wrapper">
                  <input type="checkbox" [checked]="isMarketingSelectionSel"
                    [attr.aria-label]="resourceStrings['Aon.Login.MarketingSelection']" />
                    <label class="marketing-checkbox secondary">
                      <span class="check-toggle" [ngClass]="{'error':(!isMarketingSelectionSel)}"></span>
                      <span class="label content"
                      [innerHTML]="resourceStrings['Aon.Login.MarketingSelection'] | safeHtml"></span>
                    </label>
                  </div>
                }
              }
              @if (submitted && error) {
                <div>
                  @if (error) {
                    <div class="alert contextual error mt-3 mb-2" role="alert"><i
                    class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{error}}</div>
                  }
                </div>
              }
            </form>
          </div>
          <div class="row m-b-40">
            @if (curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress) {
              <div class="col-md-6">
                <button class="login-submit spinner login-generate-password"
                  [disabled]="f.username.pristine || (f.username.invalid && (f.username.dirty || f.username.touched))"
                  (click)='getOTP()'>
                  {{resourceStrings['Aon.Login.GeneratePassword']}}
                  @if (loading) {
                    <span class="spinner-border spinner-border-sm"></span>
                  }
                </button>
              </div>
            }
            @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
              <div class="col-md-6">
                <button class="login-submit spinner login-verify-email" [disabled]="loading || !isPrivacyPolicyAccepted"
                  (click)='onSubmit()'>
                  {{resourceStrings['Aon.Login.VerifyEmail']}}
                  @if (loading) {
                    <span class="spinner-border spinner-border-sm"></span>
                  }
                </button>
              </div>
            }
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="sm" id="not-registered">{{resourceStrings['Aon.Login.HaveAccount']}} <a id="go-to-login"
                href="javascript:void(0);" (click)="goToLogin()"
              aria-labelledby="registered-go-to-login">{{resourceStrings['Aon.Login.LoginText']}}</a></p>
            </div>
            <div class="col-md-12">
              <p class="sm" id="need-help">{{resourceStrings['Aon.Account.NeedHelp']}} <a id="contact-help"
                [routerLink]="[baseUrl+'/home/help']" (click)="linkClick()"
              aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a></p>
            </div>
          </div>

        </div>
      </div>