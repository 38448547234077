@if (options && options.items) {
  <div class="row justify-content-between">
    @if (showLegends && summaryTotal) {
      <div class="col-12" [ngClass]="showTRSImage?'col-md-6':'col-md-7'">
        <p class="strong">{{summaryTotalKey | uppercase}}: {{summaryTotal | currency}}</p>
        <ng-container *ngTemplateOutlet="legend"></ng-container>
      </div>
    }
    <div class="col-12" [ngClass]="showTRSImage? (showLegends && summaryTotal?'col-md-6':'col-md-12') : 'col-md-5'"
      class="kendo-chart-size" aria-hidden="true">
      <kendo-chart #chart [transitions]="animateChart" class="kendo-chart">
        <kendo-chart-series>
          <kendo-chart-series-item #series [type]="this.chartType" [data]="options.items" categoryField="name"
            title="name" field="total" [holeSize]="holeSize" [autoFit]="true">
            <kendo-chart-series-item-tooltip background="green">
              <ng-template let-value="value" let-category="category">
                <span [innerHTML]="category | safeHtml"></span>: {{ getFormattedValue(value)}}
              </ng-template>
            </kendo-chart-series-item-tooltip>
          </kendo-chart-series-item>
        </kendo-chart-series>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item>
            <kendo-chart-category-axis-item-labels [content]="axisLabelContent">
            </kendo-chart-category-axis-item-labels>
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-legend position="left" [visible]="false">
        </kendo-chart-legend>
      </kendo-chart>
    </div>
    @if (showLegendsBelowTheChart) {
      <div class="m-t-20">
        <ng-container *ngTemplateOutlet="legend"></ng-container>
      </div>
    }
  </div>
}

<ng-template #legend>
  <ul class="legend" role="list">
    @for (item of options.items; track item; let index = $index) {
      <li class="legend-item focus-visibility" role="listitem"
        [class.legend-item-inactive]="!item.active" (click)="toggleSeries(item, index)"
        (keyup.enter)="toggleSeries(item, index)" (mouseenter)="toggleSeriesHighlight(true, item.name)"
        (mouseleave)="toggleSeriesHighlight(false, item.name)">
        <div class="d-flex align-items-center">
          <p class="legend-marker sm" [style.background]="item.color"></p>
          <p class="sm"><span [innerHTML]="item.name | safeHtml"></span> <span
        class="m-l-4">({{getFormattedValue(item.total)}})</span></p>
      </div>
    </li>
  }
</ul>
</ng-template>