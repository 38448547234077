import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { EmployeeServiceCommsPreference } from '../../modules/profile/models/employeeServiceCommsPreference.model';
import { ProfileService } from '../../modules/profile/profile.service';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { HelperService } from '../services/helper.service';
import { take, takeUntil } from 'rxjs/operators';
import { TBSBaseComponent } from '../models/tbsBaseComponent';

@Component({
  selector: 'app-marketing-preference',
  templateUrl: './marketing-preference.component.html'
})
export class MarketingPreferenceComponent extends TBSBaseComponent implements OnInit {
  @Input() isNoEmailProvided: boolean = false;
  @Output() emitEmployeeServiceCommsPreferences: EventEmitter<EmployeeServiceCommsPreference[]> = new EventEmitter();
  employeeServiceCommsPreferences: EmployeeServiceCommsPreference[];
  public employeeServiceCommsPreferencesForTheValidation: EmployeeServiceCommsPreference[];
  uiConfig: UIConfigurationType;
  saveEmployeeServiceCommsPreferencesSuccess = false;
  employeeServiceCommsPreferencesErrorMessage = "";
  saveEmployeeServiceCommsPreferencesError = false;
  baseUrl: string;
  submitted: boolean = false;
  @Output('closeCaptureConsentModal') closeCaptureConsentModal: EventEmitter<any> = new EventEmitter();
  commsPrefDataSaveInProgress = false;
  showNoEmailProvidedAlert: boolean = false;

  constructor(
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly profileService: ProfileService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public helperService: HelperService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Profile.CommunicationPreferences',
      'Aon.Profile.Comms.SubscriptionStatus.Description',
      'Aon.Profile.Marketing.Selection',
      'Aon.Common.Save',
      'Aon.Profile.AboutMe',
      'Aon.Profile.SaveCommunicationPreferencesSuccessPreAboutMe',
      'Aon.Profile.SaveCommunicationPreferencesSuccessAfterAboutMe',
      'Aon.Profile.Alert.NoEmailProvided'
    ]);
  }

  ngOnInit(): void {
    this.baseUrl = this.helperService.getBaseUrl();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.getEmployeeServiceCommsPreferences();
  }

  private getEmployeeServiceCommsPreferences() {
    this.profileService.getEmployeeServiceCommsPreferences()
      .pipe(take(1))
      .subscribe(data => {
        if (data != null) {
          this.emitEmployeeServiceCommsPreferences.emit(data);
          this.employeeServiceCommsPreferences = data;
          this.employeeServiceCommsPreferencesForTheValidation = JSON.parse(JSON.stringify(data));
        }
      })
  }

  saveElectedEmployeeServiceCommsPreferences(escp: EmployeeServiceCommsPreference): void {
    if (this.isNoEmailProvided && !escp.isOptedOutOfComms) {
      this.showNoEmailProvidedAlert = true;
      return;
    }
    this.showNoEmailProvidedAlert = false;
    this.commsPrefDataSaveInProgress = true;
    this.saveEmployeeServiceCommsPreferencesSuccess = false;
    this.submitted = true;
    this.profileService.saveEmployeeServiceCommsPreferences(escp)
      .pipe(take(1))
      .subscribe(
        data => {
          this.submitted = false;
          if (data && data.didSave) {
            this.getEmployeeServiceCommsPreferences();
            sessionStorage.setItem('IsCaptureMarketingPreferenceRequired', 'False');
            this.saveEmployeeServiceCommsPreferencesSuccess = true;
            this.commsPrefDataSaveInProgress = false;
          }
          else {
            this.saveEmployeeServiceCommsPreferencesError = true;
            this.commsPrefDataSaveInProgress = false;
            for (let err in data.alertMessages) {
              this.employeeServiceCommsPreferencesErrorMessage += err;
            }
          }
          this.ScrollMarketingPreference();
        },
        error => {
          this.submitted = false;
          this.saveEmployeeServiceCommsPreferencesError = true;
          this.commsPrefDataSaveInProgress = false;
          this.employeeServiceCommsPreferencesErrorMessage = this.resourceStrings['Aon.Profile.ContactPreferencesError'];
          this.ScrollMarketingPreference();
        }
      );
  }
  ScrollMarketingPreference() {
    let showMarketingPreferenceScroll = document.querySelector("#marketingpreference");
    if (showMarketingPreferenceScroll) {
      showMarketingPreferenceScroll.scrollIntoView({ block: "start", inline: "nearest" });
    }
  }
  goToAboutMe() {
    if (this.closeCaptureConsentModal != undefined) {
      this.closeCaptureConsentModal.emit();
    }
    this.router.navigate([this.baseUrl + "/profile"], { fragment: "aboutme" });
  }
}
