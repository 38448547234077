import { Component, ElementRef, Inject, Injector, input } from '@angular/core';
import { IEditableMBRSiteToolbar } from './editable-mbr-site-toolbar.model';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { BootstrapRequestContext } from 'tbs-typings';
import { WebSiteType } from '../models/constants';

@Component({
  selector: 'app-editable-mbr-site-toolbar',
  templateUrl: './editable-mbr-site-toolbar.component.html'
})
export class EditableMbrSiteToolbarComponent extends TBSBaseComponent {
  //#region Input Properties
  instance = input.required();
  addSpacer = input<boolean>(false);
  header = input<IEditableMBRSiteToolbar.Header>();
  type = input<IEditableMBRSiteToolbar.ToolbarType>(IEditableMBRSiteToolbar.ToolbarType.Local);
  controls = input.required<IEditableMBRSiteToolbar.Controls>();
  isInsideCard = input<boolean>(false);
  setMaxWidthBasedOnRelativeNextSiblingContainer = input<boolean>(false);
  //#endregion
  //#region Properties
  public readonly uuid = self?.crypto?.randomUUID();
  public readonly toolbarType = IEditableMBRSiteToolbar.ToolbarType;
  public readonly ManagedBy = IEditableMBRSiteToolbar.ManagedBy;
  //#endregion

  constructor(injector: Injector,
    public elRef: ElementRef,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.SelfService.Managed.Internally',
      'Aon.SelfService.Managed.Externally'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable().pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
  }

  onClick(callbackFnName: string, args: any[]): void {
    if (this.instance()[callbackFnName] instanceof Function) {
      if (args?.length > 0) {
        this.instance()[callbackFnName](...args);
      } else {
        this.instance()[callbackFnName]();
      }
    }
  }

  get styleProperties() {
    if (this.setMaxWidthBasedOnRelativeNextSiblingContainer()) {
      return {
        "max-width.px": this.elRef.nativeElement?.nextElementSibling?.clientWidth + 1
      }
    }
    return {};
  }

  get isExternalEditorSite() { return this.bootstrapRequestContext.webSiteType == WebSiteType.ExternalEditorSite }
}
