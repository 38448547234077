<ng-template #accessibilityW3screenReaderPageLoad>
  <span role="alert" class="visually-hidden" role="alert" aria-atomic="true"
    [ngClass]="{'d-none': !globalObjService.w3ScreenReader.pageLoadingAnnouncement}"
  [innerHTML]="resourceStrings['Aon.Common.PageLoading']"></span>
  <span role="alert" class="visually-hidden" role="alert" aria-atomic="true"
    [ngClass]="{'d-none': !globalObjService.w3ScreenReader.pageLoadedAnnouncement}"
  [innerHTML]="resourceStrings['Aon.Common.PageLoaded']"></span>
</ng-template>
@if (noDarkSpin) {
  <ng-container [ngTemplateOutlet]="accessibilityW3screenReaderPageLoad"></ng-container>
}
@if (!noDarkSpin) {
  <div class="aon-spin-nested-loading" [class.dark-spin]="DarkSpin">
    @if (IsLoading) {
      <div class="d-flex flex-column align-items-center justify-content-center aon-spin-spinning">
        <ng-container [ngTemplateOutlet]="accessibilityW3screenReaderPageLoad"></ng-container>
        <div class="row">
          <div class="col-12">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">{{LoadingText}}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <strong>{{LoadingText}}</strong>
          </div>
        </div>
      </div>
    }
    <div class="aon-content-box">
      <ng-content></ng-content>
    </div>
  </div>
}