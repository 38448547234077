import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { Observable, map, mergeMap, of } from 'rxjs';
import { GlobalObjectsService } from './global-objects.service';
import { Constants } from '../models/constants';
import { OptionalRelationshipFilter, RelationshipFilter } from '../models/relationship.model';
import { Currencies } from '../models/currencies.model';
import { Populations } from '../models/populations.model';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {

  apiBaseUrl: string;
  getAllowedLanguagesUrl: string;
  siteName: string;
  private readonly RELATIONSHIPCACHEFLAG = 'RELATIONSHIPCACHEFLAG'


  constructor(
    private readonly http: HttpClient,
    private readonly globalOjectService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.getAllowedLanguagesUrl = this.apiBaseUrl + 'allowedlanguages';
    this.siteName = bootstrapRequestContext.siteName;
  }

  setNoCahceFlagForRelatioshipsAPI() {
    sessionStorage.setItem(this.RELATIONSHIPCACHEFLAG, 'no-cache');
  }

  clearNoCahceFlagForRelatioshipsAPI() {
    sessionStorage.removeItem(this.RELATIONSHIPCACHEFLAG);
  }

  getQualifyingQuestions() {
    return this.http.get('countries');
  }

  countries() {
    return this.LoadAndGetMetaData('countries', 'MetaData.CountriesKey');
  }

  states() {
    return this.LoadAndGetMetaData('states', 'MetaData.StatesKey');
  }

  genders() {
    return this.LoadAndGetMetaData('genders', 'MetaData.GendersKey');
  }

  maritalStatus() {
    return this.LoadAndGetMetaData('maritalStatus', 'MetaData.MaritalStatusKey');
  }

  countryPhoneCodes() {
    return this.LoadAndGetMetaData('countryPhoneCodes', 'MetaData.CountryPhoneCodesKey');
  }

  titles() {
    return this.LoadAndGetMetaData('titles', 'MetaData.TitlesKey');
  }

  relationships(rsFilter: OptionalRelationshipFilter = null) {
    return this.globalOjectService.RelationshipFilter
      .pipe(
        mergeMap((data) => {
          const filter = new RelationshipFilter();
          if (data) {
            Object.assign(filter, data);
          }

          if (rsFilter) {
            Object.assign(filter, rsFilter);
          }

          const EmployeeBenefitID = filter.EmployeeBenefitID ?? Constants.GuidEmpty;
          const Line_RecordID = filter.Line_RecordID ?? Constants.GuidEmpty;
          const BenefitPlan_RecordID = filter.BenefitPlan_RecordID ?? Constants.GuidEmpty;
          let cacheKey = `MetaData.RelationshipsKey.${EmployeeBenefitID}.${Line_RecordID}.${BenefitPlan_RecordID}.${filter.Mode_RecordID}.${filter.IsPartial}.${filter.LimitParticipantsByType}`;
          if (filter.ReasonRelationshipID && filter.ReasonRelationshipID != "") {
            cacheKey += '.' + filter?.ReasonRelationshipID;
          } else {
            delete filter.ReasonRelationshipID;
          }

          return this.LoadAndGetRelationships('relationships', cacheKey, filter);
        })
      );
  }

  LoadAndGetRelationships<T>(apiKey: string, cacheKey: string, filter: RelationshipFilter) {
    const isCacheRequired = sessionStorage.getItem(this.RELATIONSHIPCACHEFLAG) !== 'no-cache'
    if (!isCacheRequired) {
      return this.LoadAndGetRelationshipsWithoutCaching(apiKey, filter);
    }

    if (sessionStorage.getItem(cacheKey) != null) {
      return of(JSON.parse(sessionStorage.getItem(cacheKey)));
    }

    return this.LoadAndGetRelationshipsWithoutCaching(apiKey, filter).pipe(map(resultList => {
      sessionStorage.setItem(cacheKey, JSON.stringify(resultList));
      return resultList;
    }))
  }

  private LoadAndGetRelationshipsWithoutCaching<T>(apiKey: string, filter: RelationshipFilter) {
    const requestUrl = this.apiBaseUrl + apiKey;
    return this.http.post<T[]>(requestUrl, filter).pipe(map(resultList => {
      return resultList;
    }));
  }

  LoadAndGetMetaData<T>(apiKey: string, cacheKey: string) {
    if (sessionStorage.getItem(cacheKey) != null) {
      return of(JSON.parse(sessionStorage.getItem(cacheKey)));
    } else {
      const requestUrl = this.apiBaseUrl + apiKey;
      return this.http.get<T[]>(requestUrl).pipe(map(resultList => {
        sessionStorage.setItem(cacheKey, JSON.stringify(resultList));
        return resultList;
      }))
    }
  }

  LoadAndGetGenericDataView(dataViewKey: string) {
    let cacheKey = 'Generic.' + dataViewKey;
    if (sessionStorage.getItem(cacheKey) != null) {
      return of(JSON.parse(sessionStorage.getItem(cacheKey)));
    } else {
      const data = { "GenericDataViewKey": dataViewKey }
      const requestUrl = this.apiBaseUrl + 'getemployeegenericdataview';
      return this.http.post<any>(requestUrl, data).pipe(map(dataViewResult => {
        let resultViewObj = JSON.parse(dataViewResult.json);
        sessionStorage.setItem(cacheKey, JSON.stringify(resultViewObj));
        return resultViewObj;
      }))
    }
  }

  public getTheCountryCodeFromTheCountryName(name: string, trimPlusSymbol: boolean = false): string {
    try {
      if (trimPlusSymbol) {
        return name.split(" (")[0].replace('+', '');
      }
      return name.split(" (")[0];
    } catch { }
    return name;
  }

  get currencies(): Observable<Currencies[]> {
    return this.LoadAndGetMetaData('currencies', 'MetaData.Currencies').pipe(map((currencies: Currencies[]) => {
      currencies?.forEach(x => this.globalOjectService.currencies[x.currencyCode] = x.currencySymbol)
      return currencies;
    }));
  }
  get populations(): Observable<Populations[]> {
    return this.LoadAndGetMetaData('populations', 'MetaData.Populations').pipe(map((populations: Populations[]) => {
      this.globalOjectService.Populations = populations;
      return populations;
    }));
  }

  nationalIDTypes() {
    return this.LoadAndGetMetaData('nationalIDTypes', 'MetaData.NationalIDTypes');
  }

}