import { Injectable, Inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { UiconfigrationService } from '../services/uiconfigration.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { BootstrapRequestContext } from 'tbs-typings';


export interface DefaultRoute {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({ providedIn: 'root' })
export class DefaultRouteGuard {

  constructor(
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly router: Router,
    private readonly helperService: HelperService,
    private readonly globalObjectsService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let uiConfig: UIConfigurationType = this.uiconfigrationService.getUIConfiguration();
    let baseUrl: string = this.helperService.getBaseUrl();
    this.globalObjectsService.suppressNavigation = true;
    if (uiConfig.uiConfiguration.setWrapperPage && this.bootstrapRequestContext.enableWrapperPreAuthentication && !this.helperService.isLoggedOn()) {
      this.router.navigate([baseUrl + '/login']);
    }
    else if (uiConfig.uiConfiguration.setWrapperPage) {
      this.router.navigate([baseUrl + '/wrapper']);
    }
    else {
      this.router.navigate([baseUrl + '/home']);
    }
    return true;
  }
}
