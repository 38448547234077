<div class="page" id="inbox">
  <div class="page-content-sleeve container-fluid masthead-inbox" WatchDomTree (dom-changed)="domChanged($event)">
    <div class="row page-content-sleeve p-t-40">
      <div class="col-md-8 col-12 p-l-0">
        <h1 [innerHTML]="resourceStrings['Aon.MastheadInbox.PageTitle.Notification']"></h1>
        <p class="xl m-b-50" [innerHTML]="resourceStrings['Aon.MastheadInbox.PageSubTitle.Mailbox']"> </p>

      </div>
      <kendo-grid [data]="gridView" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
        (pageChange)="pageChange($event)" (selectedKeysChange)="keyChange($event)" kendoGridSelectBy="recordID"
        [selectedKeys]="mySelection" [selectable]="true" (dblclick)="openInbox()" [sortable]="true" [navigable]="true"
        [sort]="sort" (sortChange)="sortChange($event)" filterable="menu" (dataStateChange)="dataStateChange($event)"
        (remove)="removeHandler()" [kendoGridBinding]="gridData" class="m-b-40">
        @if (!uiConfig.uiConfiguration.hideDeleteButtonInInboxGrid) {
        <ng-template kendoGridToolbarTemplate>
          <div class="d-flex m-y-20 col-12 align-items-center">
            <i class="fal fa-trash-alt" aria-hidden="true"></i>
            <a tabindex="0" class="p-l-8" [innerHTML]="resourceStrings['Aon.MastheadInbox.Delete']"
              kendoGridRemoveCommand></a>
          </div>
        </ng-template>
        }
        <kendo-grid-column field="subject" title="{{resourceStrings['Aon.MastheadInbox.subject']}}">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
              operator="startswith">
              <kendo-filter-eq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsEqualTo']}}"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.Isnotequalto']}}"></kendo-filter-neq-operator>
              <kendo-filter-isnull-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsNull']}}"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsNotNull']}}"></kendo-filter-isnotnull-operator>
              <kendo-filter-isempty-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsEmpty']}}"></kendo-filter-isempty-operator>
              <kendo-filter-isnotempty-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsNotEmpty']}}"></kendo-filter-isnotempty-operator>
              <kendo-filter-startswith-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.StartsWith']}}"></kendo-filter-startswith-operator>
              <kendo-filter-contains-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.Contains']}}"></kendo-filter-contains-operator>
              <kendo-filter-not-contains-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.DoesNotContain']}}"></kendo-filter-not-contains-operator>
              <kendo-filter-endswith-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.EndsWith']}}"></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="receivedDate" filter="date" title="{{resourceStrings['Aon.MastheadInbox.Received']}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.receivedDate | customdate}}
          </ng-template>
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-date-filter-menu [column]="column" [filter]="filter" format="{{AON_LOCALE.calendar.patterns.d}}"
              placeholder="{{placeholder}}" [filterService]="filterService" operator="eq">
              <kendo-filter-eq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsEqualTo']}}"></kendo-filter-eq-operator>
              <kendo-filter-neq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.Isnotequalto']}}"></kendo-filter-neq-operator>
              <kendo-filter-after-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsAfter']}}"></kendo-filter-after-operator>
              <kendo-filter-after-eq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsAfterorEqualTo']}}"></kendo-filter-after-eq-operator>
              <kendo-filter-before-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsBefore']}}"></kendo-filter-before-operator>
              <kendo-filter-before-eq-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsBeforeorequalTo']}}"></kendo-filter-before-eq-operator>
              <kendo-filter-isnull-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsNull']}}"></kendo-filter-isnull-operator>
              <kendo-filter-isnotnull-operator
                text="{{resourceStrings['Aon.MastheadInbox.filter.IsNotNull']}}"></kendo-filter-isnotnull-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>

        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          {{resourceStrings['Aon.MastheadInbox.NoData']}}
        </ng-template>
        <kendo-grid-messages filterClearButton="{{resourceStrings['Aon.MastheadInbox.filter.Clear']}}"
          filterFilterButton="{{resourceStrings['Aon.MastheadInbox.filter.Filter']}}"></kendo-grid-messages>
        <kendo-grid-messages [pagerItems]="resourceStrings['Aon.MastheadInbox.Item']"
          [pagerOf]="resourceStrings['Aon.MastheadInbox.PageOf']">
        </kendo-grid-messages>

      </kendo-grid>
    </div>
  </div>
  <ng-template #inboxMessage let-modal>
    <div (keydown.esc)="modal.dismiss('Cross click')">
      <div class="modal-header">
        <h1 class="modal-title" [innerHTML]="selectedItemSubject | safeHtml" id="modal-basic-title"></h1>
        <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
          id="closeBtn" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="basic-card edit-state">
          <p [innerHtml]="selectedItem"></p>
        </div>
      </div>
      <div class="button-sleeve m-t-32">
        <button type="button" class="prominent" (click)="modal.dismiss('Cross click')"
          [innerHTML]="resourceStrings['Aon.MastheadInbox.close']">
        </button>
      </div>
    </div>
  </ng-template>
</div>