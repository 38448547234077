@for (toast of toastService.toasts; track toast) {
  <ngb-toast [class]="toast.classname || 'bg-success text-light'"
    [autohide]="true" [delay]="toast.delay || 5000" (hidden)="toastService.remove(toast)">
    @if (isTemplate(toast)) {
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    } @else {
      {{ toast.textOrTpl }}
    }
    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </ngb-toast>
}