import { Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'src/app/shared/services/helper.service';

@Pipe({
  name: 'textTransform'
})
export class TextTransformPipe implements PipeTransform {
  constructor(
    private readonly helperService: HelperService
  ) {
  }

  transform(value: any, data: any) {
    if (!value) {
      return '';
    }

    if (data && typeof data === 'object') {
      for (let key in data) {
        if (data[key] != null) {
          if (key === 'clientcode' && this.helperService.IsVanityURLForMultiTenantEnabled()) {
            let find = 'href=\"';
            let replace = 'href="/' + this.helperService.getClientCode();
            value = value.replace(new RegExp(find, 'g'), replace);
          } else {
            value = value.replaceAll(`{{${key}}}`, data[key].toString());
          }
        }
      }
    }

    return value;
  }
}
