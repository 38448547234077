<div class="page-content-sleeve mfa-section p-t-40 p-b-100">
  <div class="row">
    <div [ngClass]="inLoginProcess?'col-md-6 col-12':'col-12'">
      @if (step == MFAStep.EnterSsnNumber) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.EnterUniqueIdentifier'] | safeHtml"
        ></h1>
      }
      @if (step == MFAStep.SelectEmail) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.SelectAnEmail'] | safeHtml">
        </h1>
      }
      @if (step == MFAStep.AddNewEmail) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.AddNewEmail'] | safeHtml">
        </h1>
      }
      @if (step == MFAStep.EnterCodeForAddNewEmail || (step == MFAStep.EnterCode && selectedOption == MFAOption.Email)) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.CheckYourEmail'] | safeHtml"
          >
        </h1>
      }
      @if (step == MFAStep.AddNewPhone) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.AddNewPhone'] | safeHtml">
        </h1>
      }
      @if (step == MFAStep.EnterCodeForAddNewPhone || (step == MFAStep.EnterCode && selectedOption == MFAOption.SMS)) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.CheckYourPhone'] | safeHtml"
          >
        </h1>
      }
      @if (step == MFAStep.SelectOption) {
        <h1 [innerHTML]="resourceStrings['Aon.MFA.Header.SelectAutenticationMethod'] | safeHtml"
        ></h1>
      }
      @if (step == MFAStep.NoAuthInfo) {
        <div class="no-options">
          @if (isMFAOptionsExisting) {
            <div>
              @if (errorMessage!='') {
                <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                  id="dismissable-error-1">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                      (click)="helperService.closeInfo('dismissable-error-1')"
                      (keydown.enter)="helperService.closeInfo('dismissable-error-1')"
                    [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="resourceStrings['Aon.MFA.NoValues'] | safeHtml"></p>
                </div>
              }
            </div>
          }
        </div>
      }
      @if (step == MFAStep.SelectOption) {
        <div class="select-options">
          @if (!isMFAOptionsExisting) {
            <div class="no-values">
              <div class="row">
                <div class="col-12 d-flex content align-items-center m-b-20">
                  <div class="d-flex align-items-center">
                    <span class="m-r-10 m-b-0" [innerHTML]="resourceStrings['Aon.MFA.NoValues'] | safeHtml"></span>
                  </div>
                </div>
              </div>
            </div>
          }
          @if (isMFAOptionsExisting) {
            <div>
              @if (errorMessage!='') {
                <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                  id="dismissable-error-2">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                      (click)="helperService.closeInfo('dismissable-error-2')"
                      (keydown.enter)="helperService.closeInfo('dismissable-error-2')"
                    [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="errorMessage | safeHtml"></p>
                </div>
              }
              <div>
                <p class="lg"
                  [innerHTML]="resourceStrings['Aon.MFA.Instruction'] | textTransform : {FirstName:preferredName} | safeHtml">
                </p>
              </div>
              <div class="form-group">
                <div role="radiogroup" class="aon-form-input-wrapper aon-form-radio-group"
                  aria-labelledby="mfa-auth-method">
                  @if (mfaOptions.length > 1 ) {
                    <p id="mfa-auth-method" [innerHTML]="resourceStrings['Aon.MFA.ChooseOption'] | safeHtml"
                    ></p>
                  }
                  @for (item of mfaOptions; track item; let i = $index) {
                    <div class="aon-form-radio">
                      <input type="radio" [id]="'mfa-option-' + item.name" class="aon-form-input" name="rdbMFAOption"
                        (click)="selectOption(item.name, item.value)" [checked]="i===0" />
                        <label [attr.for]="'mfa-option-' + item.name" class="aon-form-radio-label">
                          @if (item.name == 'Email') {
                            <i class="fas fa-envelope" aria-hidden="true"></i>
                          }
                          @if (item.name == 'SMS') {
                            <i class="fas fa-comment" aria-hidden="true"></i>
                          }
                          @if (item.name == 'Email') {
                            <span [innerHTML]="resourceStrings['Aon.MFA.GetACodeEmailedTo'] | safeHtml"
                            class="left-spaces"></span>
                          }
                          @if (item.name == 'SMS') {
                            <span [innerHTML]="resourceStrings['Aon.MFA.GetACodeTextedTo'] | safeHtml"
                            class="left-spaces"></span>
                          }
                          <span class="text-format strong" [innerHTML]="item.value"></span>
                        </label>
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        }

        @if (step == MFAStep.EnterCode || step == MFAStep.EnterCodeForAddNewEmail || step == MFAStep.EnterCodeForAddNewPhone) {
          <div class="enter-code"
            >
            <div>
              @if (errorMessage!='') {
                <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                  id="dismissable-error-3">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                      (click)="helperService.closeInfo('dismissable-error-3')"
                      (keydown.enter)="helperService.closeInfo('dismissable-error-3')"
                    [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="errorMessage | safeHtml"></p>
                </div>
              }
              <label class="header-title">
                <p class="m-r-10 lg" [innerHTML]="resourceStrings['Aon.MFA.SentACodeTo']| safeHtml"></p>
                @if (selectedOption == MFAOption.Email) {
                  <p class="lg strong" [innerHTML]="maskedEmail | safeHtml">
                  </p>
                }
                @if (selectedOption == MFAOption.SMS) {
                  <p class="lg strong" [innerHTML]="maskedPhoneNumber | safeHtml">
                  </p>
                }
              </label>
              <label>
                <p [innerHTML]="resourceStrings['Aon.MFA.BrowserMessage']| safeHtml"></p>
              </label>
              <div class="form-group">
                <label for="otpCode" class="text-format strong"
                [innerHTML]="resourceStrings['Aon.MFA.EnterCode']| safeHtml"></label>
                @if (isOTPFieldBlank) {
                  <div class="alert contextual error">
                    <div role="alert" id="otp-required">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyOTP']}}
                    </div>
                  </div>
                }
                <div class="resend-code-wrapper">
                  <input id="otpCode" type="text" maxlength="6" [(ngModel)]="otpCode[selectedOption]"
                    (keypress)="numberOnly($event)" />
                  </div>
                  <div class="m-t-8">
                    <p [innerHTML]="resourceStrings['Aon.MFA.CodeValidPeriodInfo']| safeHtml"></p>
                  </div>
                </div>
              </div>
            </div>
          }
          @if (step == MFAStep.SelectEmail || step == MFAStep.AddNewEmail || step == MFAStep.AddNewPhone) {
            <div class="mfa-registration"
              >
              @if (errorMessage!='') {
                <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                  id="dismissable-error-4">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                      (click)="helperService.closeInfo('dismissable-error-4')"
                      (keydown.enter)="helperService.closeInfo('dismissable-error-4')"
                    [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="errorMessage | safeHtml"></p>
                </div>
              }
              <div class="row">
                <div class="col-12">
                  <h2 [innerHTML]="resourceStrings['Aon.MFA.Title'] | safeHtml"></h2>
                  <div>
                    <span
                    [innerHTML]="resourceStrings['Aon.MFA.Hi'] | textTransform: {UserName: preferredName} | safeHtml"></span>
                  </div>
                  <div>
                    <p [innerHTML]="resourceStrings['Aon.MFA.Verification'] | safeHtml"></p>
                  </div>
                </div>
              </div>
              @if (step == MFAStep.SelectEmail) {
                <div class="row">
                  <div class="col-12 col-xs-6">
                    <h5 id="mfa-auth-email-preference"
                    [innerHTML]="resourceStrings['Aon.MFA.ChoosePreferenceEmailOrPhone'] | safeHtml"></h5>
                    <div class="form-group">
                      <div role="radiogroup" class="aon-form-input-wrapper aon-form-radio-group"
                        aria-labelledby="mfa-auth-email-preference">
                        @if (workEmail) {
                          <div class="aon-form-radio">
                            <input type="radio" class="aon-form-input" [checked]="preferenceEmail == MFAPreferenceEmail.WorkEmail"
                              (change)="selectPreferenceEmail(MFAPreferenceEmail.WorkEmail)" [id]="'firstTimeUserEmail-workEmail'"
                              name="firstTimeUserEmailOptions">
                              <label class="aon-form-radio-label" [attr.for]="'firstTimeUserEmail-workEmail'">
                                <span class="m-l-0 check-toggle"></span>
                                <span class="label content" [innerHTML]="workEmail | safeHtml"></span>
                              </label>
                            </div>
                          }
                          @if (personalEmail && personalEmail != workEmail) {
                            <div class="aon-form-radio">
                              <input type="radio" class="aon-form-input"
                                [checked]="preferenceEmail == MFAPreferenceEmail.PersonalEmail"
                                (change)="selectPreferenceEmail(MFAPreferenceEmail.PersonalEmail)"
                                [id]="'firstTimeUserEmail-personalEmail'" name="firstTimeUserEmailOptions">
                                <label class="aon-form-radio-label" [attr.for]="'firstTimeUserEmail-personalEmail'">
                                  <span class="m-l-0 check-toggle"></span>
                                  <span class="label content" [innerHTML]="personalEmail | safeHtml"></span>
                                </label>
                              </div>
                            }
                            @if (alternateEmail && alternateEmail != personalEmail && alternateEmail != workEmail) {
                              <div class="aon-form-radio"
                                >
                                <input type="radio" class="aon-form-input" [id]="'firstTimeUserEmail-alternateEmail'"
                                  [checked]="preferenceEmail == MFAPreferenceEmail.AlternateEmail"
                                  (change)="selectPreferenceEmail(MFAPreferenceEmail.AlternateEmail)"
                                  name="firstTimeUserEmailOptions">
                                  <label class="aon-form-radio-label" [attr.for]="'firstTimeUserEmail-alternateEmail'">
                                    <span class="m-l-0 check-toggle"></span>
                                    <span class="label content" [innerHTML]="alternateEmail | safeHtml"></span>
                                  </label>
                                </div>
                              }
                              @if (mfaEmail && mfaEmail!= alternateEmail && mfaEmail != personalEmail && mfaEmail != workEmail) {
                                <div class="aon-form-radio"
                                  >
                                  <input type="radio" class="aon-form-input" [id]="'firstTimeUserEmail-mfaEmail'"
                                    [checked]="preferenceEmail == MFAPreferenceEmail.MFAEmail"
                                    (change)="selectPreferenceEmail(MFAPreferenceEmail.MFAEmail)" name="firstTimeUserEmailOptions">
                                    <label class="aon-form-radio-label" [attr.for]="'firstTimeUserEmail-mfaEmail'">
                                      <span class="m-l-0 check-toggle"></span>
                                      <span class="label content" [innerHTML]="mfaEmail | safeHtml"></span>
                                    </label>
                                  </div>
                                }
                                <div class="aon-form-radio">
                                  <input type="radio" class="aon-form-input" [id]="'firstTimeUserEmail-newEmail'"
                                    [checked]="preferenceEmail == MFAPreferenceEmail.NewEmail"
                                    (change)="selectPreferenceEmail(MFAPreferenceEmail.NewEmail)" name="firstTimeUserEmailOptions">
                                    <label class="aon-form-radio-label" [attr.for]="'firstTimeUserEmail-newEmail'">
                                      <span class="m-l-0 check-toggle"></span>
                                      <span class="label content" [innerHTML]="resourceStrings['Aon.MFA.AddNewEmail'] | safeHtml"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        @if (step == MFAStep.AddNewEmail) {
                          <div class="row">
                            <form [formGroup]="mfaAddNewEmail">
                              <div class="col-12 col-xs-6">
                                <div class="form-group">
                                  <label for="username">{{resourceStrings['Aon.Common.EmailLabel']}}</label>
                                  @if (isFieldValid('username')) {
                                    <div class="alert contextual error" id="email-required">
                                      <div role="alert">
                                        <i class="fas fa-exclamation-triangle"
                                        aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyEmail']}}
                                      </div>
                                    </div>
                                  }
                                  <input type="text" formControlName="username" id="username" [(ngModel)]="userEmail" class="m-b-8" />
                                </div>
                                @if (!inLoginProcess && uiConfig.uiConfiguration.enableMarketingConsent) {
                                  <div class="checkbox-wrapper">
                                    <input type="checkbox" (change)="useSameEmailForMarketingCommunication()"
                                      name="checkUseSameEmailForMarketingCommunication" id="checkUseSameEmailForMarketingCommunication"
                                      [checked]="useSameEmailForMarketingCommunicationChecked" />
                                      <label class="secondary privacy-link" for="checkUseSameEmailForMarketingCommunication"
                                        [innerHTML]="resourceStrings['Aon.MFA.UseNewEmailForMarketing'] | safeHtml">
                                      </label>
                                    </div>
                                  }
                                </div>
                              </form>
                            </div>
                          }
                          @if (step == MFAStep.AddNewPhone) {
                            <div class="row">
                              <form [formGroup]="mfaAddNewPhone">
                                <div class="col-12 col-sm-9">
                                  <div class="form-group">
                                    <label for="Phone">{{resourceStrings['Aon.Profile.Phone']}}</label>
                                    @if (isFieldValid('countryPhoneCode') || isFieldValid('phone')) {
                                      <div class="alert contextual error">
                                        <div role="alert" id="country-code-required">
                                          <i class="fas fa-exclamation-triangle"
                                          aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyPhone']}}
                                        </div>
                                      </div>
                                    }
                                    <div class="aon-two-part-telephone-input">
                                      <select formControlName="countryPhoneCode" id="countryPhoneCode" class="country-code col-sm-4 col-12">
                                        <option [ngValue]="null">{{resourceStrings['Aon.MFA.ChooseOption']}}</option>
                                        @for (rc of countryPhoneCodes; track rc; let i = $index) {
                                          <option [ngValue]="rc"
                                            [attr.aria-label]="rc.name + ' ' + resourceStrings['Aon.Common.Selected']">
                                            {{rc.name}}
                                          </option>
                                        }
                                      </select>
                                      <input type="text" (keypress)="numberOnly($event)" formControlName="phone" id="phone"
                                        [(ngModel)]="userPhone" class="col-12 col-sm-8" />
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            }
                          </div>
                        }
                        @if (step == MFAStep.EnterSsnNumber) {
                          <div class="enter-code">
                            <div class="row">
                              <div class="col-12 content m-b-20">
                                <div class="d-flex align-items-center">
                                  <p class="lg"
                                    [innerHTML]="resourceStrings['Aon.MFA.Instruction'] | textTransform : {FirstName:preferredName} | safeHtml">
                                  </p>
                                </div>
                              </div>
                            </div>
                            @if (errorMessage!='') {
                              <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                                id="dismissable-error-5">
                                <div class="title">
                                  <div class="title-content">
                                    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                    {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                                  </div>
                                  <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                                    (click)="helperService.closeInfo('dismissable-error-5')"
                                    (keydown.enter)="helperService.closeInfo('dismissable-error-5')"
                                  [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                                </div>
                                <p [innerHTML]="errorMessage | safeHtml"></p>
                              </div>
                            }
                            <div class="item">
                              <label class="header-title">
                              </label>
                              <label>
                                <p [innerHTML]="resourceStrings['Aon.MFA.SSNMessage']| safeHtml"></p>
                              </label>
                              @if (mfaUniqueIdeIndex == '1') {
                                <div class="form-group">
                                  <label for="enterUniqueCode" class="text-format strong"
                                  [innerHTML]="resourceStrings['Aon.MFA.EnterNationIdMsg']| safeHtml"></label>
                                  @if (isUniqueIdentifierFieldBlank) {
                                    <div class="alert contextual error">
                                      <div role="alert" id="phone-number-required">
                                        <i class="fas fa-exclamation-triangle"
                                        aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyUniqueIdentifier']}}
                                      </div>
                                    </div>
                                  }
                                  <input id="enterUniqueCode" type="text" class="col-12 col-sm-3" maxlength="4"
                                    [(ngModel)]="enterUniqueCode" />
                                  </div>
                                }
                                @if (mfaUniqueIdeIndex == '2') {
                                  <div class="form-group">
                                    <label for="enterUniqueCode" class="text-format strong"
                                    [innerHTML]="resourceStrings['Aon.MFA.EnterEmpNumberIdMsg']| safeHtml"></label>
                                    @if (isUniqueIdentifierFieldBlank) {
                                      <div class="alert contextual error">
                                        <div role="alert" id="phone-number-required">
                                          <i class="fas fa-exclamation-triangle"
                                          aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyUniqueIdentifier']}}
                                        </div>
                                      </div>
                                    }
                                    <input id="enterUniqueCode" type="text" class="col-12 col-sm-3" maxlength="50"
                                      [(ngModel)]="enterUniqueCode" />
                                    </div>
                                  }
                                  @if (mfaUniqueIdeIndex == '3') {
                                    <div class="form-group">
                                      <label for="enterUniqueCode" class="text-format strong"
                                      [innerHTML]="resourceStrings['Aon.MFA.EnterSystemIdMsg']| safeHtml"></label>
                                      @if (isUniqueIdentifierFieldBlank) {
                                        <div class="alert contextual error">
                                          <div role="alert" id="phone-number-required">
                                            <i class="fas fa-exclamation-triangle"
                                            aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyUniqueIdentifier']}}
                                          </div>
                                        </div>
                                      }
                                      <input id="enterUniqueCode" type="text" class="col-12 col-sm-3" maxlength="50"
                                        [(ngModel)]="enterUniqueCode" />
                                      </div>
                                    }
                                    @if (mfaUniqueIdeIndex == '4') {
                                      <div class="form-group">
                                        <label for="enterUniqueCode" class="text-format strong"
                                        [innerHTML]="resourceStrings['Aon.MFA.EnterOtherIdMsg']| safeHtml"></label>
                                        @if (isUniqueIdentifierFieldBlank) {
                                          <div class="alert contextual error">
                                            <div role="alert" id="phone-number-required">
                                              <i class="fas fa-exclamation-triangle"
                                              aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyUniqueIdentifier']}}
                                            </div>
                                          </div>
                                        }
                                        <input id="enterUniqueCode" type="text" class="col-12 col-sm-3" maxlength="256"
                                          [(ngModel)]="enterUniqueCode" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                                <div class="button-sleeve">
                                  @if (step == MFAStep.EnterSsnNumber) {
                                    <button class="prominent sendOtpCode" (click)="sendSsnCode()">
                                      {{resourceStrings['Aon.MFA.Continue']}}
                                      @if (isUniIdenVerifying) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.SelectOption) {
                                    <button class="prominent sendOtpCode" (click)="sendCode()"
                                      [disabled]="isSending">
                                      {{resourceStrings['Aon.MFA.Continue']}}
                                      @if (isSending) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.SelectEmail) {
                                    <button class="prominent submitCode" (click)="savePreferenceEmail()">
                                      {{resourceStrings['Aon.MFA.Continue']}}
                                      @if (ismfaRegistering) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.AddNewEmail) {
                                    <button class="prominent submitCode" (click)="sendCodeToNewAddedEmail()"
                                      [disabled]="isSending">
                                      {{resourceStrings['Aon.MFA.SendCode']}}
                                      @if (isSending) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.AddNewPhone) {
                                    <button class="prominent submitCode" (click)="sendCodeToNewAddedMobile()"
                                      [disabled]="isSending">
                                      {{resourceStrings['Aon.MFA.SendCode']}}
                                      @if (isSending) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.EnterCode || step == MFAStep.EnterCodeForAddNewEmail || step == MFAStep.EnterCodeForAddNewPhone) {
                                    <button
                                      class="prominent verifyCode" (click)="verifyCode()" [disabled]="isVerifying">
                                      {{resourceStrings['Aon.MFA.Verify']}}
                                      @if (isVerifying) {
                                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                                      }
                                    </button>
                                  }
                                  @if (step == MFAStep.EnterSsnNumber) {
                                    <button class="subtle" [hidden]="isLoading" [innerHTML]="resourceStrings['Aon.MFA.Cancel'] | safeHtml"
                                    (click)="cancel()"></button>
                                  }
                                  @if (showBackButton()) {
                                    <button class="subtle" [hidden]="isLoading" [innerHTML]="resourceStrings['Aon.Common.Back'] | safeHtml"
                                    (click)="backToPreviousStep()"></button>
                                  }
                                  <div>
                                    @if (enableMFAEmail && step == MFAStep.AddNewPhone) {
                                      <a role="button" href="javascript:void(0);" (click)="skipAddNewPhone()"
                                        [attr.aria-label]="resourceStrings['Aon.Common.Skip']"
                                      [innerHTML]="resourceStrings['Aon.Common.Skip'] | safeHtml"></a>
                                    }
                                  </div>
                                </div>

                                @if (step == MFAStep.EnterCode || step == MFAStep.EnterCodeForAddNewEmail || step == MFAStep.EnterCodeForAddNewPhone) {
                                  <div class="row"
                                    >
                                    <div class="d-flex align-items-center m-t-8 resend-code">
                                      <span>{{resourceStrings['Aon.MFA.ResendCode.Info']}}</span>
                                      @if (step == MFAStep.EnterCode) {
                                        <a href="javascript:void(0);" (click)="resendCode()" role="button" class="btn"
                                          [ngClass]="isReSending || isInDisablePeriod? 'disabled':''">{{resourceStrings['Aon.MFA.ResendCode']}} @if (isInDisablePeriod) {
                                          <span
                                          >{{resendTimeRemaining$ | async | date:'ss'}}{{'s'}}</span>
                                        }</a>
                                      }
                                      @if (step == MFAStep.EnterCodeForAddNewEmail) {
                                        <a href="javascript:void(0);" (click)="resendCodeToNewAddedEmail()" role="button" class="btn"
                                          [ngClass]="isReSending || isInDisablePeriod? 'disabled':''">{{resourceStrings['Aon.MFA.ResendCode']}} @if (isInDisablePeriod) {
                                          <span
                                          >{{resendTimeRemaining$ | async | date:'ss'}}{{'s'}}</span>
                                        }</a>
                                      }
                                      @if (step == MFAStep.EnterCodeForAddNewPhone) {
                                        <a href="javascript:void(0);" (click)="resendCodeToNewAddedMobile()" role="button" class="btn"
                                          [ngClass]="isReSending || isInDisablePeriod? 'disabled':''">{{resourceStrings['Aon.MFA.ResendCode']}} @if (isInDisablePeriod) {
                                          <span
                                          >{{resendTimeRemaining$ | async | date:'ss'}}{{'s'}}</span>
                                        }</a>
                                      }
                                    </div>
                                    <div class="d-flex align-items-center m-t-8">
                                      @if (step == MFAStep.EnterCode && displayBtnSMSCode()) {
                                        <a href="javascript:void(0);" (click)="sendCode('SMS',mfaOptions[1].value, MFAStep.EnterCode)" role="button"
                                          class="ghost SMSCode btn"
                                        [ngClass]="isSending || isInDisablePeriod? 'disabled':''">{{resourceStrings['Aon.MFA.SMSCode']}}</a>
                                      }
                                      @if (step == MFAStep.EnterCode && displayBtnEmailCode()) {
                                        <a href="javascript:void(0);" (click)="sendCode('Email',mfaOptions[0].value, MFAStep.EnterCode)" role="button"
                                          class="ghost emailCode btn"
                                        [ngClass]="isSending || isInDisablePeriod? 'disabled':''">{{resourceStrings['Aon.MFA.EmailCode']}}</a>
                                      }
                                    </div>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>

                          <ng-template #enterCodeFromProfileTemplate let-option="option" let-errorMsg="errorMsg" let-mEmail="mEmail"
                            let-mPhoneNumber="mPhoneNumber" let-isResendCodeToNewChannel="isResendCodeToNewChannel" let-isReSend="isReSend">
                            <div class="col-12 m-auto">
                              <div>
                                <div class="row">
                                  <div class="col-12 m-b-20">
                                    <div class="d-flex align-items-center">
                                      @if (option == MFAOption.Email) {
                                        <h4 class="m-b-0 h5" [innerHTML]="resourceStrings['Aon.MFA.CheckEmail'] | safeHtml"
                                        ></h4>
                                      }
                                      @if (option == MFAOption.SMS) {
                                        <h4 class="m-b-0 h5" [innerHTML]="resourceStrings['Aon.MFA.CheckPhone'] | safeHtml"
                                        ></h4>
                                      }
                                    </div>
                                  </div>
                                </div>
                                @if (errorMsg!='') {
                                  <div AlertBannerCloseButtonFocus class="alert banner error" role="alert"
                                    id="dismissable-error-3">
                                    <div class="title">
                                      <div class="title-content">
                                        <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                        {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                                      </div>
                                      <i role="button" class="fal fa-times focus-visibility" tabindex="0"
                                        (click)="helperService.closeInfo('dismissable-error-3')"
                                        (keydown.enter)="helperService.closeInfo('dismissable-error-3')"
                                      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                                    </div>
                                    <p [innerHTML]="errorMsg | safeHtml"></p>
                                  </div>
                                }
                                <label class="header-title">
                                  <p class="m-r-10 lg" [innerHTML]="resourceStrings['Aon.MFA.SentACodeTo']| safeHtml"></p>
                                  @if (option == MFAOption.Email) {
                                    <p class="lg strong" [innerHTML]="mEmail | safeHtml">
                                    </p>
                                  }
                                  @if (option == MFAOption.SMS) {
                                    <p class="lg strong" [innerHTML]="mPhoneNumber | safeHtml">
                                    </p>
                                  }
                                </label>
                                <div class="form-group">
                                  <label for="otpCode{{option}}" class="text-format strong"
                                  [innerHTML]="resourceStrings['Aon.MFA.EnterCode']| safeHtml"></label>
                                  @if (isOTPFieldBlank) {
                                    <div class="alert contextual error">
                                      <div role="alert" id="phone-number-required">
                                        <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{resourceStrings['Aon.MFA.EmptyOTP']}}
                                      </div>
                                    </div>
                                  }
                                  <div class="d-flex resend-code-wrapper">
                                    <input id="otpCode{{option}}" type="text" maxlength="6" [(ngModel)]="otpCode[option]"
                                      (keypress)="numberOnly($event)" />
                                      <div>
                                        <div class="button-sleeve">
                                          <button class="subtle resendCode" (click)="resendCode(isResendCodeToNewChannel, option)"
                                            [disabled]="isReSend">
                                            {{resourceStrings['Aon.MFA.ResendCode']}}
                                            @if (isReSend) {
                                              <span class="spinner-border spinner-border-sm m-l-4"></span>
                                            }
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-template>