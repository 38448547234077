import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Faq } from '../../shared/models/faq.model';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-faq',
  templateUrl: 'faq.component.html'
})
export class FaqComponent implements OnInit {
  faPlus: any;
  faMinus: any;
  @Input() faqTitle: string;
  @Input() faqs: Faq[];
  @Input() faqPage: boolean;
  @Input() resourcestrings;
  @ViewChild(NgbAccordionDirective) private readonly accordion: NgbAccordionDirective;
  openById = {};
  FAQ(event) {
    this.openById[event] = this.accordion.isExpanded(event);
  }
  constructor(private readonly analyticsService: AnalyticsService, private readonly elementRef: ElementRef) { }
  activeId: string[];
  ngOnInit(): void {
  }

  faqclick(faq) {
    this.analyticsService.trackCustomEvent({
      'event': 'faq-question-clicked',
      'faq-item-title': faq.itemTitle,
      'faq-product-name': faq.productname,
      'faq-question': faq.question,
    });
  }
  ariaOwns = '';
  ngAfterViewInit(): void {
    this.faqs?.forEach((faq) => {
      let ph = document.getElementById('p' + faq.faqID + '-header');
      this.ariaOwns = this.ariaOwns + ' ' + 'p' + faq.faqID + '-header';
      if (ph) {
        ph.setAttribute("role", "");
      }
    })
    this.ariaOwns = this.ariaOwns.trim();
  }

  domChanged() {
    try {
      const headingElements = this.elementRef?.nativeElement?.querySelectorAll('[role="heading"]');
      if (headingElements?.length > 0) {
        headingElements.forEach((element => element.removeAttribute("role")));
      }
      const tabListElements = this.elementRef.nativeElement.querySelectorAll('[role="tablist"]');
      if (tabListElements?.length > 0) {
        tabListElements.forEach((element => element.removeAttribute("role")));
      }
      const tabElements = this.elementRef.nativeElement.querySelectorAll('[role="tab"]');
      if (tabElements?.length > 0) {
        tabElements.forEach((element => element.removeAttribute("role")));
      }
    } catch { }
  }
}
