import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[WatchDomTree]'
})
export class WatchDomTreeDirective {

  constructor(
    private readonly elRef: ElementRef
  ) { }

  ngOnInit() {
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  registerDomChangedEvent(el) {
    const mutationObserverOptions = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(list => {
      list.forEach((mutation: any) => {
        mutation?.addedNodes?.forEach((node: any) => {
          if ((node instanceof HTMLElement)) {
            const evt = new CustomEvent('dom-changed', { detail: list, bubbles: true });
            el.dispatchEvent(evt);
          }
        })
      });
      observer.disconnect();
      setTimeout(() => observer.observe(el, mutationObserverOptions), 1000);
    });
    observer.observe(el, mutationObserverOptions);
  }

}
