import { Component, Injector } from '@angular/core';
import { TBSBaseComponent } from '../../models/tbsBaseComponent';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CTAModel } from '../../models/callToAction.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelfServiceModel } from '../../models/selfService.model';

@Component({
  templateUrl: './self-service-manage-cta-template-modal.component.html'
})
export class SelfServiceManageCtaTemplateModalComponent extends TBSBaseComponent {
  templateMode = SelfServiceModel.TemplateMode.Add;
  TemplateMode = SelfServiceModel.TemplateMode;
  ctaForm: FormGroup;
  displayHideFields: Array<string> = [];
  editData: CTAModel.CallToActionFields;
  onSubmit: Subject<CTAModel.CallToActionFields> = new Subject();
  onSubmitErrorMessage: string = "";
  constructor(injector: Injector,
    private readonly formBuilder: FormBuilder,
    public readonly activeModal: NgbActiveModal
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Validation.Required.ErrorMessage',
      'Aon.Validation.Invalid.ErrorMessage',
      'Aon.SelfService.CTA.Modal.Add.Header.Label',
      'Aon.SelfService.CTA.Modal.Edit.Header.Label',
      'Aon.SelfService.CTA.ActionType.Label',
      'Aon.SelfService.CTA.ActionType.Description',
      'Aon.SelfService.CTA.TargetURL.Label',
      'Aon.SelfService.CTA.TargetURL.Description',
      'Aon.SelfService.CTA.DisplayStyle.Label',
      'Aon.SelfService.CTA.CSSClass.Label',
      'Aon.SelfService.CTA.CSSClass.Description',
      'Aon.SelfService.CTA.DisplayText.Label',
      'Aon.SelfService.CTA.DisplayText.Description',
      'Aon.SelfService.CTA.AccessibleDescription.Label',
      'Aon.SelfService.CTA.AccessibleDescription.Description',
      'Aon.Pages.Common.DisplayOrder'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable().pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
    this.init();
  }

  init(): void {
    this.onSubmitErrorMessage = "";
    this.BuildForm();
  }

  private BuildForm(): void {
    let ctaFormControls = {};
    this.AddFormControl("itemTitle", ctaFormControls);
    this.AddFormControl("actionType", ctaFormControls);
    this.AddFormControl("linkUrl", ctaFormControls);
    this.AddFormControl("displayStyle", ctaFormControls);
    this.AddFormControl("cssClass", ctaFormControls);
    this.AddFormControl("displayText", ctaFormControls);
    this.AddFormControl("accessibleDescription", ctaFormControls);
    this.ctaForm = this.formBuilder.group(ctaFormControls);
  }

  private AddFormControl(formControlName: string, formControls: { [key: string]: FormControl }): void {
    if (!this.canDisplayField(formControlName)) { return }
    const requiredFields = ["itemTitle"];
    let validators = [];
    let value = "";
    if (requiredFields.includes(formControlName)) {
      validators.push(Validators.required);
    }
    if (this.templateMode === SelfServiceModel.TemplateMode.Edit) {
      value = this.editData[formControlName];
    }
    formControls[formControlName] = new FormControl(value, validators);
  }

  canDisplayField(formControlName: string): boolean {
    return !this.displayHideFields.includes(formControlName);
  }

  addRequired(formGroup: FormGroup, formInputName: string): string {
    return formGroup.get(formInputName)?.hasValidator(Validators.required) ? "<span class='aon-required'>&nbsp;(" + this.resourceStrings['Aon.Common.Required'] + ")</span>" : "";
  }

  isValidationFailed(formGroup: FormGroup, formInputName: string) {
    return formGroup.get(formInputName)?.touched && formGroup.get(formInputName)?.invalid;
  }

  saveCTA(): void {
    this.onSubmitErrorMessage = "";
    this.ctaForm.markAllAsTouched();
    if (this.ctaForm.valid) {
      let saveResponse: CTAModel.CallToActionFields = this.ctaForm.value;
      if (this.templateMode === SelfServiceModel.TemplateMode.Edit) {
        saveResponse.system = this.editData.system;
      }
      this.onSubmit.next(saveResponse);
    }
  }
}
