import { Component, OnInit, Inject, TemplateRef, ViewChild, Injector } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { BootstrapRequestContext } from 'tbs-typings';
import { takeUntil, take } from 'rxjs/operators';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { CustomLoggedOffSettings } from '../models/customLoggedOffSettings.model';
import { SessionStorageKey } from '../models/constants';

@Component({
  selector: 'app-idle-timeout',
  templateUrl: './idle-timeout.component.html'
})
export class IdleTimeoutComponent extends TBSBaseComponent implements OnInit {
  @ViewChild('content', { static: false }) private content: any;
  count: number;
  baseUrl: string;
  private modelOpened: boolean = false;
  uiConfig: UIConfigurationType;

  constructor(
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private readonly userIdle: UserIdleService,
    private readonly authenticationService: AuthenticationService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    public helperService: HelperService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Common.TimeoutTitle',
      'Aon.Common.TimeoutInfoPrefix',
      'Aon.Common.TimeoutInfoSuffix',
      'Aon.Common.KeepWorking'
    ]);
  }


  open(content: TemplateRef<any>) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    this.helperService.isTimeout = true;
    ngbModalOptions.container = '#app-modal-anchor';
    this.modalService.open(content, ngbModalOptions);
  }

  openmodel() {
    this.open(this.content);
  }
  ngOnInit(): void {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = this.helperService.getBaseUrl();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
    this.authenticationService.currentUser.subscribe(x => {
      if (x != null && this.authenticationService.isLogin) {
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe(count => {
          if (!this.modelOpened) {
            this.open(this.content);
            this.modelOpened = true;
          }
          this.count = 30 - count;
        });

        // Start watch when time is up.
        this.userIdle.onTimeout()
          .pipe(take(1))
          .subscribe(() => {
            this.stop()
          });
      }
    })

  }

  stop() {
    //this.helperService.isTimeout = false;
    this.userIdle.stopWatching();
    this.userIdle.stopTimer();
    this.modalService.dismissAll();
    this.authenticationService.logout().subscribe(() => {
      this.modelOpened = false;
      sessionStorage.removeItem("AllowedLanguages");
      const isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
      if (!this.helperService.AllowAnonymousAccess()) {
        let customLoggedOffSettings: CustomLoggedOffSettings = null;
        if (sessionStorage.getItem('CustomLoggedOffSettings')) {
          customLoggedOffSettings = JSON.parse(sessionStorage.getItem('CustomLoggedOffSettings'));
        }
        if (customLoggedOffSettings) {
          if (customLoggedOffSettings.enableCustomLoggedOffPage) {
            this.router.navigate([this.baseUrl + '/loggedoff'], { queryParams: { pagekey: customLoggedOffSettings.customLoggedOffPagePath } });
          } else {
            if (isFromMobileApp) {
              this.router.navigate([this.baseUrl + '/login'], { queryParams: { timestamp: Date.now() } });
            } else {
              this.router.navigate([this.baseUrl + '/login']);
            }
          }
        } else {
          let uiConfig = this.uiconfigrationService.getUIConfiguration();
          if (uiConfig?.uiConfiguration?.loginByMitIDOnly) {
            window.location.href = "/SSO/SignOutMitid";
          } else {
            if (isFromMobileApp) {
              this.router.navigate([this.baseUrl + '/login'], { queryParams: { timestamp: Date.now() } });
            } else {
              this.router.navigate([this.baseUrl + '/login']);
            }
          }
        }
      }
      else {
        this.router.navigate([this.baseUrl + '/']);
      }
    })

  }

  restart() {
    this.helperService.isTimeout = false;
    this.userIdle.resetTimer();

    this.modelOpened = false;
    this.modalService.dismissAll();
  }



}
