import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ParticipantsType } from 'src/app/shared/models/ParticipantsType';
import { ActiveEmployeeBenefitsRequest, EmployeeBenefitsType } from 'src/app/shared/models/benefitReview.model';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { ContentItemModel } from '../../shared/models/contentItem.model';
import { Participant } from '../../shared/models/participant.model';
import { participantDocument } from '../../shared/models/participantDocument.model';
import { SaveResult } from '../../shared/models/saveResult.model';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { EmployeeContactPreference } from './models/employeeContactPreference.model';
import { EmployeeExternalBenefits } from './models/employeeExternalBenefit.model';
import { EmployeeInfoModel } from './models/employeeInfo.model';
import { EmployeeServiceCommsPreference } from './models/employeeServiceCommsPreference.model';
import { SessionStorageKey, Constants } from 'src/app/shared/models/constants';
import { adminCorrespondenceDocumentType } from 'src/app/shared/models/adminCorrespondenceDocumentType.model'
import { HelperService } from '../../shared/services/helper.service';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { AccountService } from '../account/account.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AllocationType } from 'src/app/shared/models/allocationType.model';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  tenantFolderName: string;
  apiBaseUrl: string;
  siteName: string;
  uiConfig: UIConfigurationType;
  isBeneficiaryDeleteFromProfileIntiated: boolean = false;
  myBenefitsLoaded = new BehaviorSubject<EmployeeBenefitsType[]>(null);
  myBenefitsChanged = new Subject<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly helperService: HelperService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly accountService: AccountService,
    private readonly authenticationService: AuthenticationService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.tenantFolderName = bootstrapRequestContext.tenantFolderName;
    this.siteName = bootstrapRequestContext.siteName;
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.globalObjectsService.uiConfigs.subscribe((uiConfiguration => this.uiConfig = uiConfiguration));
  }

  getEmployeeInfo() {
    const employeeInfoUrl = this.apiBaseUrl + 'employees';
    return this.http.post<EmployeeInfoModel>(employeeInfoUrl, { "showWorkProfile": true }).pipe(tap(employeeInfo => {
      if (employeeInfo?.timeWarpDate) {
        sessionStorage.setItem(SessionStorageKey.TimeWarpDate, window.btoa(employeeInfo.timeWarpDate));
      }
    }));
  }
  
  getEmployeeContactPreferences() {
    const employeeContactPreferencesUrl = this.apiBaseUrl + 'contactpreferences';
    return this.http.get<EmployeeContactPreference>(employeeContactPreferencesUrl);
  }

  saveEmployeeInformation(employeeInfo: EmployeeInfoModel) {
    const employeeInfoUrl = this.apiBaseUrl + 'employeeinformation';
    return this.http.post<SaveResult>(employeeInfoUrl, employeeInfo);
  }

  saveEmployeeContactPreferences(contactModel: EmployeeContactPreference, uiConfigId: string) {
    const employeeContactPreferencesUrl = this.apiBaseUrl + 'contactpreferences' + "/" + uiConfigId;
    return this.http.post<SaveResult>(employeeContactPreferencesUrl, contactModel);
  }

  GetEmployeeParticipants(employeeBenefitId: number | null = null, line_RecordID: string | null = null, isPartial: boolean | null = null, limitParticipantsByType: boolean | null = null) {
    let httpParams = new HttpParams()
      .set("CanEdit", Constants.True);

    if (employeeBenefitId) {
      httpParams = httpParams.set("EmployeeBenefitId", employeeBenefitId);
    }
    if (line_RecordID) {
      httpParams = httpParams.set("LineId", line_RecordID);
    }
    if (isPartial != null) {
      httpParams = httpParams.set("isPartial", isPartial);
    }
    if (limitParticipantsByType != null) {
      httpParams = httpParams.set("LimitParticipantsByType", limitParticipantsByType);
    }
    if (this.helperService.isExpressCheckoutEnabled()) {
      httpParams = httpParams.set("IncludeVanilla", this.helperService.isExpressCheckoutEnabled());
    }
    const getDependantsUrl = this.apiBaseUrl + 'participants';
    return this.http.get<ParticipantsType>(getDependantsUrl, { params: httpParams });
  }

  resetPassword(password: string, currentPassword: string) {
    const requestUrl = this.apiBaseUrl + this.siteName + '/resetpassword';
    return this.http.post<boolean>(requestUrl, { Password: password, CurrentPassword: currentPassword, UIConfigId: this.uiConfig.id });
  }

  setNewPassword(userName: string, password: string, currentPassword: string) {
    const requestUrl = this.apiBaseUrl + this.siteName + '/setnewpassword';
    return this.http.post<SaveResult>(requestUrl, { UserIdentity: userName, Password: password, CurrentPassword: currentPassword, UIConfigId: this.uiConfig.id });
  }

  createPassword(password: string, currentPassword: string) {
    const requestUrl = this.apiBaseUrl + this.siteName + '/createpassword';
    return this.http.post<boolean>(requestUrl, { Password: password, UIConfigId: this.uiConfig.id });
  }

  getEmployeeBeneficiaries() {
    const httpParams = new HttpParams()
      .set("EmployeeBenefitId", "0")
      .set("BenefitPlan_RecordID", null)
      .set("CanEdit", "True");
    let requestUrl = this.apiBaseUrl + 'beneficiaries';
    return this.http.get<Participant[]>(requestUrl, { params: httpParams });
  }

  getEmployeeBeneficiaryAllocations() {
    const httpParams = new HttpParams()
      .set("EmployeeBenefitId", "0")
      .set("IsProfile", "True");
    let requestUrl = this.apiBaseUrl + 'beneficiaryallocations';
    return this.http.get<AllocationType[]>(requestUrl, { params: httpParams });
  }

  getEmployeeServiceCommsPreferences() {
    const employeeServiceCommsPreferencesUrl = this.apiBaseUrl + 'employeeservicecommspreferences';
    return this.http.get<EmployeeServiceCommsPreference[]>(employeeServiceCommsPreferencesUrl);
  }

  saveEmployeeServiceCommsPreferences(serviceCommsPreferenceModel: EmployeeServiceCommsPreference) {
    const employeeContactPreferencesUrl = this.apiBaseUrl + 'employeeservicecommspreferences';
    return this.http.post<SaveResult>(employeeContactPreferencesUrl, serviceCommsPreferenceModel);
  }

  getEmployeeExternalBenefits() {
    const employeeExternalBenefitsUrl = this.apiBaseUrl + 'employeeexternalbenefits';
    return this.http.get<EmployeeExternalBenefits>(employeeExternalBenefitsUrl);
  }
  getEmployeeSavedQuotes() {
    const httpParams = new HttpParams()
      .set("includeCart", this.helperService.isSingleProductCheckOutEnabled());

    const savedQutoesurl = this.apiBaseUrl + 'savedquotes';
    return this.http.get<EmployeeBenefitsType>(savedQutoesurl, { params: httpParams });
  }

  checkEmployeeSavedQuotesExists(lineRecordId) {
    let includeCart = this.helperService.isSingleProductCheckOutEnabled()
    const requestUrl = `${this.apiBaseUrl}savedquotes/${lineRecordId}/${includeCart}`;  
    return this.http.get<boolean>(requestUrl);
  }

  getCarriers(carrierKeys: string[], subFolder: string = null) {
    const carrierItemsUrl = this.apiBaseUrl + 'carrieritems';
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ContentItemModel[]>(carrierItemsUrl, { Path: nodePath, ContentKeys: carrierKeys });
  }
  getProductsSableSummariesWithDataView(request: any) {
    let requestUrl = this.apiBaseUrl + 'products/sablesummary/dataview';
    return this.http.post<any>(requestUrl, request);
  }

  saveNewUserName(userName, newUserName) {
    let requestUrl = this.apiBaseUrl + this.siteName + '/newusername';
    return this.http.post<SaveResult>(requestUrl, { userName: userName, newUserName: newUserName });
  }
  saveEmployeeCorrespondenceDocument(formData) {
    let requestUrl = this.apiBaseUrl + 'correspondencedocument';
    return this.http.post(requestUrl, formData, { reportProgress: true, observe: 'events' });
  }
  getParticipantDocuments() {
    let requestUrl = this.apiBaseUrl + 'participantdocuments';
    return this.http.post<participantDocument[]>(requestUrl, { EmployeeBenefitIds: [] });
  }


  saveDependant(Dependant: Participant) {
    const employeeInfoUrl = this.apiBaseUrl + 'dependant';
    return this.http.post<SaveResult>(employeeInfoUrl, Dependant);
  }

  getActiveEmployeeBenefits(request: ActiveEmployeeBenefitsRequest): Observable<EmployeeBenefitsType[]> {
    const employeeInfoUrl = this.apiBaseUrl + 'GetActiveEmployeeBenefits';
    return this.http.post<EmployeeBenefitsType[]>(employeeInfoUrl, request);
  }

  getFutureActiveEmployeeBenefits(request: ActiveEmployeeBenefitsRequest): Observable<any> {
    const employeeInfoUrl = this.apiBaseUrl + 'GetFutureEmployeeBenefitsStatements';
    return this.http.post<any>(employeeInfoUrl, request);
  }

  public get getTimeWarpDate(): Observable<Date | Observable<Date>> {
    let date: Date = new Date();
    let sessionStorageTimeWarp = "";
    const isTimeWarpAvailableInSessionStorage = () => {
      try {
        sessionStorageTimeWarp = window.atob(sessionStorage.getItem(SessionStorageKey.TimeWarpDate));
        if (!isNaN(new Date(sessionStorageTimeWarp).getTime())) {
          date = new Date(sessionStorageTimeWarp);
          return true;
        }
      } catch { }
      return false;
    };

    if (!isTimeWarpAvailableInSessionStorage()) {
      return this.getEmployeeInfo().pipe(map(() => {
        isTimeWarpAvailableInSessionStorage();
        return date;
      }));
    }
    return of(date);
  }

  getAdminDocuments() {
    let requestUrl = this.apiBaseUrl + 'adminCorrespondencedocuments';
    return this.http.get<adminCorrespondenceDocumentType[]>(requestUrl);
  }

  public downloadFile(employeeCorrespondenceDocumentId: string) {
    if (this.helperService.isFromAndroidApp()) {
      const httpParams = new HttpParams()
        .set("getFile", "true");
      let requestUrl = this.apiBaseUrl + 'correspondencedocument/' + employeeCorrespondenceDocumentId;
      return this.http.get<any>(requestUrl, {
        params: httpParams
      }).subscribe((response: any) => {
        this.helperService.downLoadFileByBase64(response);
      });
    } else {
      let downloadModel = { documentId: employeeCorrespondenceDocumentId };

      this.helperService.downloadFile('/Download/CorrespondenceDocument', downloadModel);
    }

  }

  public get showDocuments(): boolean {
    return this.uiConfig.uiConfiguration.showDocuments && (
      !this.uiConfig.uiConfiguration.hideAdminDocumentsTab ||
      !this.uiConfig.uiConfiguration.hideEmployeeDocumentsTab ||
      !this.uiConfig.uiConfiguration.hideHistoricalBenefitStatementsTab
    );
  }

  public get showDependantsAndBeneficiaries(): boolean {
    return this.uiConfig.uiConfiguration.showDependantsAndBeneficiaries && (
      !this.uiConfig.uiConfiguration.hideBeneficiariesTab ||
      !this.uiConfig.uiConfiguration.hideDependantsTab
    );
  }

  public get showMyBenefits(): boolean { return this.uiConfig.uiConfiguration.showMyBenefits; }
  public get showLifeEvents(): boolean { return this.uiConfig.uiConfiguration.showLifeEvents; }
  public get showSaveQuotes(): boolean { return this.uiConfig.uiConfiguration.showSaveQuotes; }
  public get showPayments(): boolean { return this.uiConfig.uiConfiguration.showPayments; }
  public get hideProfileAccount(): boolean { return this.uiConfig.uiConfiguration.hideProfileAccount || (this.authenticationService.isSSO(this.authenticationService.currentUserValue) && !this.accountService.cacheSelfRegisteredFlag && !this.helperService.showSensitiveDataScreeningControlSection) }
}
