import { Directive, ElementRef } from '@angular/core';
import { CTACustomFunctionService } from 'src/app/shared/services/ctacustom-function.service';

@Directive({
  selector: '[T3ModalAccess]'
})
export class T3ModalDirective {

  t3Modals: any = [];

  constructor(private readonly elRef: ElementRef,
    private readonly ctaCustomFn: CTACustomFunctionService
  ) { }

  ngAfterViewChecked(): void {
    try {
      let t3ModalElements: any = this.elRef.nativeElement.querySelectorAll('[t3-modal-guid]');
      if (t3ModalElements.length != this.t3Modals.length) {
        this.t3Modals = t3ModalElements;
        this.createAnEventListenerForT3Modal();
      }
    } catch (error) { }
  }

  createAnEventListenerForT3Modal(): void {
    if (this.t3Modals.length) {
      this.t3Modals.forEach((t3ModalElement: any) => {
        let line_GUID: string = t3ModalElement.getAttribute('t3-modal-guid');
        let item_Title: string = t3ModalElement.getAttribute('t3-modal-title');
        if (line_GUID != "" && item_Title != "" && item_Title != null) {
          let t3ModalListenerName: string = 't3-modal-listener';
          if (t3ModalElement.hasAttribute(t3ModalListenerName) === false) {
            t3ModalElement.setAttribute(t3ModalListenerName, true);
            t3ModalElement.addEventListener('click', (e: any) => {
              e.preventDefault();
              setTimeout(() => {
                this.openBenefitInfoPopup(line_GUID, item_Title)
              }, 100);
            })
          }
        }
      })
    }
  }

  openBenefitInfoPopup(lineId: string, lineName: string): void {
    this.ctaCustomFn.openBenefitInfoPopup(lineId, lineName);
  }
}

