import { Component, OnInit, OnDestroy, Injector, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { BenefitService } from '../benefit.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { NgbActiveModal, NgbModalOptions, NgbModal, NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { FollowUpItem } from '../benefit.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { QuoteService } from '../../quote/quote.service';
import { DataViewInfo } from 'src/app/shared/models/sable.model';
import { EmployeeEventDetail } from 'src/app/shared/models/employeeEvent.model';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { AonTaxModellerTablesModalComponent } from 'src/app/shared/aon-tax-modeller-tables-modal/aon-tax-modeller-tables-modal.component';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { Observable } from 'rxjs';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { EmployeeBenefitsType, EmployeeBenefitType } from 'src/app/shared/models/benefitReview.model';

@Component({
  selector: 'app-enrolment-summary',
  templateUrl: './enrolment-summary.component.html'
})
export class EnrolmentSummaryComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @ViewChild('validateErrorContent', { static: false }) private readonly validateErrorContent: any;
  @ViewChild(NgbAccordionDirective) private readonly accordion: NgbAccordionDirective;
  additionalContextData: any;
  baseUrl: string;
  uiConfig: UIConfigurationType;
  errorMessages: string[] = [];
  validationError: string[] = [];
  Math: Math = Math;
  validationWarning: string[] = [];
  continueButtonLoading = false
  activeEventId: string;
  isPerPay: boolean = true;
  modalOption: NgbModalOptions = {};
  collectFollowups: FollowUpItem[] = [];
  benefitSummary: EmployeeBenefitsType[] = [];
  needShowUploadDoc: boolean;
  hasOtherValidationError: boolean;
  displayInCard = false;
  openById = {};
  activeId: string = "";
  currencyOrder: number;
  primaryCurrency_RecordID: string;
  benefitByScheme = [];
  taxCodeInputTextErrorFlag: any = {
    isRequired: false,
    isPatternInvalid: false
  };
  taxCodeInputText: string;
  isPrintMode: boolean = false;
  isWithinTheFlow: boolean;
  effectiveDate: string;
  isDataLoaded: EventEmitter<boolean> = new EventEmitter();
  paymentSourcePanelActiveIds: { [key: string]: string } = {};
  currentAdapterName: string = "";

  constructor(
    public activeModal: NgbActiveModal,
    public benefitService: BenefitService,
    private readonly ngxService: NgxUiLoaderService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly injector: Injector,
    private readonly quoteService: QuoteService,
    private readonly modalService: NgbModal,
    public globalObjService: GlobalObjectsService,
    private readonly elRef: ElementRef
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.BenefitMatrix.PageTitle.Review',
      'Aon.BenefitMatrix.PageSubTitle.Review',
      'Aon.BenefitMatrix.IntroText.Review',
      'Aon.BenefitMatrix.Confirm',
      'Aon.BenefitMatrix.Back',
      'Aon.BenefitReview.TotalPayrollDeductions',
      'Aon.BenefitReview.permonth',
      'Aon.BenefitReview.peryear',
      'Aon.BenefitReview.CostFrequency',
      'Aon.BenefitMatrix.BenefitsTable.Title.Review',
      'Aon.Benefit.ValidationTitle',
      'Aon.Benefit.ValidationError',
      'Aon.Benefit.ValidationWarning',
      'Aon.Common.OK',
      'Aon.BenefitMatrix.Continue',
      'Aon.EnrolmentSummary.EmployerCost',
      'Aon.EnrolmentSummary.BenefitSpend',
      'Aon.EnrolmentSummary.EmployeeDescription',
      'Aon.EnrolmentSummary.EmployerDescription',
      'Aon.EnrolmentSummary.PageTitle.Enrolment',
      'Aon.EnrolmentSummary.GlobalBenefits.BenefitSpend',
      'Aon.EnrolmentSummary.GlobalBenefits.EmployerCost',
      'Aon.EnrolmentSummary.GlobalEmployeeDescription',
      'Aon.EnrolmentSummary.GlobalEmployerDescription',
      'Aon.EventReview.GlobalBenefits.Heading',
      'Aon.EventReview.GlobalBenefits.Description',
      'Aon.EnrolmentSummary.TaxCode',
      'Aon.EnrolmentSummary.TaxCode.ToolTip',
      'Aon.EnrolmentSummary.TaxCode.Action.Calculate',
      'Aon.EnrolmentSummary.TaxCode.Heading',
      'Aon.EnrolmentSummary.TaxCode.Description',
      'Aon.EnrolmentSummary.TaxCode.Error.IsRequired',
      'Aon.EnrolmentSummary.TaxCode.Error.isPatternInvalid',
      'Aon.Payment.Source.AllocatedSpend',
      'Aon.Payment.Source.RemainingBalance',
      'Aon.Arialabel.Info',
      'Aon.EnrolmentSummary.TaxModeler.RelatedLink',
      'Aon.EnrolmentSummary.TaxCode.PlaceHolder',
      'Aon.Tag.Credit',
      'Aon.BenefitMatrix.IntroText.Review.NonFlow',
      'Aon.Payment.Source.ReleaseBalance',
      'Aon.Summary.TaxNote'
    ]);
  }

  ngOnInit(): void {
    this.ngxService.start();
    this.globalObjService.fromEnrolmentSummary = true;
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = this.helperService.getBaseUrl();
    this.loadResourceStringAsObservable()
      .pipe(
        mergeMap(res => {
          this.getResouceStringsAsObject(res);
          let sessionTaxCode = sessionStorage.getItem(SessionStorageKey.TaxCode);
          if (sessionTaxCode && sessionTaxCode != '') {
            return this.loadDefaultTaxCode(sessionTaxCode);
          }
          if (this.globalObjService.employeePaySlipTypeTaxCode?.length > 0) {
            return this.loadDefaultTaxCode(this.globalObjService.employeePaySlipTypeTaxCode);
          }
          return this.benefitService.getActiveEventForReviewById(this.activeEventId, this.isWithinTheFlow, this.effectiveDate, "", this.currentAdapterName)
        }),
        takeUntil(this.unsubscribe))
      .subscribe({
        next: (dataDetails) => this.handleDataDetailsFromActiveEventReview(dataDetails),
        error: () => {
          this.ngxService.stop();
          this.isDataLoaded.emit(false);
        }
      });
  }

  loadDefaultTaxCode(taxCode): Observable<EmployeeEventDetail> {
    this.taxCodeInputText = taxCode;
    let calculateTaxCodeApiService: Observable<EmployeeEventDetail> = this.calculateTaxCode(this.taxCodeInputText, true);
    if (!calculateTaxCodeApiService) {
      return this.benefitService.getActiveEventForReviewById(this.activeEventId, this.isWithinTheFlow, this.effectiveDate, "", this.currentAdapterName);
    }

    return calculateTaxCodeApiService;
  }

  handleDataDetailsFromActiveEventReview(dataDetails: EmployeeEventDetail): void {
    this.benefitSummary = dataDetails.employeeBenefitsByScheme;
    this.initDataModel();
    this.ngxService.stop();
    this.removeTheRoleTabFromPanelHeader()
    if (this.isPrintMode) {
      setTimeout(() => {
        this.elRef.nativeElement?.querySelectorAll(".fa-info-circle")?.forEach(x => x.classList.add('ignore-from-media-print'));
        this.isDataLoaded.emit(true);
      }, 200)
    }
    setTimeout(() => this.expandPaymentSourceAccordionPanelByDefault(), 200);
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private expandPaymentSourceAccordionPanelByDefault(): void {
    try {
      const expansionPanel = this.elRef.nativeElement.querySelectorAll('[id^=p-payment-source-type]');
      if (expansionPanel != undefined && expansionPanel?.length > 0) {
        expansionPanel.forEach((expansion: any) => {
          if (expansion.querySelector('[tabindex]').ariaExpanded != 'true') {
            expansion.querySelector('[tabindex]').click()
          }
        })
      }
    } catch { }
  }


  /**
   * Axe Dev Tool Issue Fix
   * Interactive Controls must not be nested
   * Child index having tabindex so removing the role tab to avoid this issue. Bug ref - #1086852
   */
  removeTheRoleTabFromPanelHeader(): void {
    try {
      setTimeout(() => {
        let ngbAccordions = this.elRef.nativeElement.querySelectorAll("ngb-accordion");
        if (ngbAccordions != undefined && ngbAccordions?.length > 0) {
          ngbAccordions.forEach((panelHeader: any) => {
            panelHeader.removeAttribute("role");
            panelHeader.removeAttribute("aria-multiselectable");
            let expansionPanel = panelHeader.querySelectorAll('.expansion-panel');
            if (expansionPanel != undefined && expansionPanel?.length > 0) {
              expansionPanel.forEach((expansion: any) => {
                expansion.firstChild.removeAttribute("role");
              })
            }
          })
        }
      }, 200)
    } catch (e) { console.log(e) }
  }

  private initDataModel(): void {
    let schemeName: string = "";
    let schemeDescription: string = "";
    let employeeBenefits = [];
    let employerBenefits = [];
    let dataViewInfo: DataViewInfo;
    let eeHasTax = false;
    let erHasTax = false;
    let isTransformNegativeEmployeeCost = false;
    if (this.benefitSummary && this.benefitSummary.length > 0) {
      this.benefitSummary = this.benefitService.getTaxInclusiveCosts(this.benefitSummary);
      this.benefitSummary.forEach(((employeeBenefitByScheme, index: number) => {
        // Reseeting the values for each scheme
        employeeBenefits = [];
        employerBenefits = [];
        eeHasTax = false;
        erHasTax = false;
        schemeName = "";
        schemeDescription = "";
        dataViewInfo = undefined;
        isTransformNegativeEmployeeCost = false;
        let totalEmployeeCost_PayPeriod = 0;
        let totalEmployeeCost_Annual = 0;
        let totalEmployerCost_PayPeriod = 0;
        let totalEmployerCost_Annual = 0;
        const calculateTotalCost = (ebt: EmployeeBenefitType) => {
          totalEmployeeCost_PayPeriod += ebt.adjustmentType != null ? !isNaN(ebt.adjustmentType?.employeeCost) ? ebt.adjustmentType?.employeeCost : 0 : ebt.employeeCost_PayPeriod;
          totalEmployeeCost_Annual += ebt.adjustmentType != null ? !isNaN(ebt.adjustmentType?.employeeCost) ? ebt.adjustmentType?.employeeCost : 0 : ebt.employeeCost_Annual;
          totalEmployerCost_PayPeriod += ebt.adjustmentType != null ? !isNaN(ebt.adjustmentType?.employerCost) ? ebt.adjustmentType?.employerCost : 0 : ebt.employerCost_PayPeriod;
          totalEmployerCost_Annual += ebt.adjustmentType != null ? !isNaN(ebt.adjustmentType?.employerCost) ? ebt.adjustmentType?.employerCost : 0 : ebt.employerCost_Annual;
        }
        // Prepare EE and ER Data
        employeeBenefitByScheme?.employeeBenefitGroups.forEach(benefitgroup => {
          if (benefitgroup.employeeBenefitsByProducts && benefitgroup.employeeBenefitsByProducts.length > 0) {
            benefitgroup.employeeBenefitsByProducts.forEach(product => {
              isTransformNegativeEmployeeCost = product.transformNegativeEmployeeCost ? true : isTransformNegativeEmployeeCost;
              if (product.employeeBenefitTypes && product.employeeBenefitTypes.length > 0) {
                if (product.schemeName || product.schemeDescription) {
                  schemeName = product.schemeName;
                  schemeDescription = product.schemeDescription;
                }
                product?.employeeBenefitTypes?.forEach((ebt => {
                  eeHasTax = ebt.employeePayPeriodTaxTotalAmount > 0 ? true : eeHasTax;
                  erHasTax = ebt.employerPayPeriodTaxTotalAmount > 0 ? true : erHasTax;
                  if (!ebt.isNoCoverage) {
                    employeeBenefits.push({
                      "benefitName": ebt.name,
                      "benefitValuePerPay": ebt.adjustmentType != null ? ebt.adjustmentType?.employeeCost : ebt.employeeCost_PayPeriod,
                      "benefitValueAnnual": ebt.adjustmentType != null ? ebt.adjustmentType?.employeeCost : ebt.employeeCost_Annual,
                      "currencyCode": ebt.currencyCode,
                      "isNoCoverage": ebt.isNoCoverage,
                      "hideEmployeeCost": ebt.hideEmployeeCost,
                      "hideEmployerCost": ebt.hideEmployerCost,
                      "transformNegativeEmployeeCost": product.transformNegativeEmployeeCost
                    })
                    employerBenefits.push({
                      "benefitName": ebt.name,
                      "benefitValuePerPay": ebt.adjustmentType != null ? ebt.adjustmentType?.employerCost : ebt.employerCost_PayPeriod,
                      "benefitValueAnnual": ebt.adjustmentType != null ? ebt.adjustmentType?.employerCost : ebt.employerCost_Annual,
                      "currencyCode": ebt.currencyCode,
                      "isNoCoverage": ebt.isNoCoverage,
                      "hideEmployeeCost": ebt.hideEmployeeCost,
                      "hideEmployerCost": ebt.hideEmployerCost
                    })
                    calculateTotalCost(ebt);
                  }
                }))
              }
            });
          }
        });

        let isDataViewInfoHasItems = false;
        if (employeeBenefitByScheme?.employeePaySlipType && employeeBenefitByScheme?.employeePaySlipType?.dataViewInfo) {
          dataViewInfo = employeeBenefitByScheme?.employeePaySlipType?.dataViewInfo;
          if (dataViewInfo?.dataJSON) {
            const parsedDataJSON = JSON.parse(dataViewInfo?.dataJSON);
            isDataViewInfoHasItems = parsedDataJSON?.items?.length > 0;
          }
        }

        employeeBenefitByScheme?.paymentSourceSummaries?.forEach((x, i: number) => this.paymentSourcePanelActiveIds[index + '_' + i] = "");

        this.benefitByScheme.push({
          "index": index,
          "eESpend": employeeBenefits,
          "erHasTax": erHasTax,
          "eeHasTax": eeHasTax,
          "eRSpend": employerBenefits,
          "schemeName": schemeName,
          "dataViewInfo": dataViewInfo,
          "isDataViewInfoHasItems": isDataViewInfoHasItems,
          "schemeDescription": schemeDescription,
          "currencyCode": employeeBenefitByScheme.currencyCode,
          "paymentSourceSummaries": employeeBenefitByScheme.paymentSourceSummaries,
          "isTransformNegativeEmployeeCost": isTransformNegativeEmployeeCost,
          "totalEmployeeCost_PayPeriod": totalEmployeeCost_PayPeriod,
          "totalEmployeeCost_Annual": totalEmployeeCost_Annual,
          "totalEmployerCost_PayPeriod": totalEmployerCost_PayPeriod,
          "totalEmployerCost_Annual": totalEmployerCost_Annual
        });
      }))
    }
  }

  openAonTaxModellerModel(event: any, modellerJson: string): void {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      ariaLabelledBy: 'app-modal-anchor'
    };
    ngbModalOptions.container = '#app-modal-anchor';
    event.preventDefault();
    setTimeout(() => {
      let modalRef = this.modalService.open(AonTaxModellerTablesModalComponent, ngbModalOptions);
      modalRef.componentInstance.modellerJson = modellerJson;
    }, 100);
  }

  /**
   * Method to handle the tax code calculation
   */
  public calculateTaxCode(taxCodeUserInput: any, returnSubscribe: boolean = false): any {
    let taxCodeRegExp = new RegExp(/(^[0-9]{1}[0-9]{0,5}[TLPVY]([MW]1){0,1}$)|(^K[0-9]{1}[0-9]{0,5}([MW]1){0,1}$)|(^((BR)|(0P)|(0T)|(K0)|(OT)|(D0)|(D1)|(NT)|(FT))([MW]1){0,1}$)|(^[Ss][0-9]*[LlPpTtMmNnSs]$)|(^[0-9]*[LlPpTtMmNnSs]$)|(^C[0-9]+[a-zA-Z]$)/);
    if (taxCodeUserInput != '' && taxCodeUserInput != undefined) {
      this.taxCodeInputTextErrorFlag.isPatternInvalid = false;
      taxCodeUserInput = taxCodeUserInput.replaceAll(" ", "");
      if (taxCodeRegExp.test(taxCodeUserInput)) {
        if (returnSubscribe == false) {
          sessionStorage.setItem(SessionStorageKey.TaxCode, taxCodeUserInput);
        }
        this.ngxService.start();
        let taxCodeApiService: any = this.benefitService.getActiveEventForReviewById(this.activeEventId, this.isWithinTheFlow, this.effectiveDate, taxCodeUserInput, this.currentAdapterName);
        if (returnSubscribe) {
          return taxCodeApiService;
        }
        taxCodeApiService.subscribe((dataDetails: any) => {
          this.benefitByScheme = [];
          this.handleDataDetailsFromActiveEventReview(dataDetails);
        })
      } else {
        this.taxCodeInputTextErrorFlag.isPatternInvalid = true;
      }
    } else {
      this.taxCodeInputTextErrorFlag.isRequired = true;
    }
    return false;
  }

  costFrequencyChange(): void {
    this.quoteService.isPerPay.next(this.isPerPay);
  }

  onClose() {
    this.globalObjService.fromEnrolmentSummary = false;
    this.activeModal.dismiss();
  }

  togglePanel(event) {
    Object.keys(this.openById).forEach(panelId => this.openById[panelId] = panelId?.startsWith("p-payment-source-type") ? this.openById[panelId] : false);
    this.openById[event] = this.accordion.isExpanded(event);
    this.activeId = this.activeId == event ? "" : event;
  }

  togglePanelPaymentSource(event, index: string) {
    this.openById[event] = this.accordion.isExpanded(event);
    this.paymentSourcePanelActiveIds[index] = this.paymentSourcePanelActiveIds[index] == event ? "" : event;
  }

  parseData(data: any, relatedDataLinkPointer: any = undefined) {
    let parsedData = JSON.parse(data);
    if (parsedData?.items?.length > 0) {
      parsedData.items = parsedData.items.filter(x => (x.displayType == 'accordion' || x.displayType == 'panel') && x.title != '' && x.value != '')
      parsedData.items.forEach((item: any) => {
        if (item.displayType == 'labelValuePair' && item.items.length > 0) {
          item.items = item.items.filter(x => x.label != '' && x.value != '')
        }
        item.relatedDataLinkPointer = relatedDataLinkPointer;

        // it gets json data of 'relatedDataLinkPointer' from globalObjService
        // but for the scenario to frash page, there are no data in globalObjService
        // also it seems sable-view does not map the json data for sub item of 'relatedDataLinkPointer'
        // so we use the value of sub item by configuring it to 'relatedDataLinkPointer'
        // the following code is to get its json data, and set to globalObjService
        if (item.items.length > 0) {
          item.items.forEach((x: any) => {
            if (x.displayType == 'labelValuePair' && x.relatedDataLinkPointer != '' && x.value != '') {
              if (this.globalObjService.employeePaySlipTypeDataJSON == undefined) {
                this.globalObjService.employeePaySlipTypeDataJSON = {};
              }
              if (this.globalObjService.employeePaySlipTypeDataJSON[x.relatedDataLinkPointer] == undefined) {
                this.globalObjService.employeePaySlipTypeDataJSON[x.relatedDataLinkPointer] = x.value;
              }
            }
          })
        }
      })
    }
    return parsedData;
  }

  benefitEmployerSpendFilter(data) {
    let isTemp = data?.hideEmployeeCost && !data?.hideEmployerCost
    if (data?.isNoCoverage && isTemp) {
      return true;
    }
    if (!data?.isNoCoverage && data?.benefitValuePerPay === 0) {
      return false
    }
    if (!data?.isNoCoverage && data?.benefitValuePerPay !== 0 && data?.benefitValuePerPay !== null) {
      return true
    }
    return false;
  }
  benefitSpendFilter(data) {
    let isTemp = data?.hideEmployeeCost && !data?.hideEmployerCost
    if (data?.isNoCoverage && isTemp) {
      return true;
    }
    if (!data?.isNoCoverage && data?.benefitValuePerPay === 0) {
      if (isTemp) {
        return true;
      }
    }
    if (!data?.isNoCoverage && data?.benefitValuePerPay !== 0 && data?.benefitValuePerPay !== null) {
      return true
    }
    return false;
  }
  benefitEmployeeSummaryToggle(i) {
    if (this.benefitSummary[i]?.paymentSourceSummaries?.length > 0) {
      return false;
    }
    if (this.isPerPay && this.benefitSummary?.length > 0 && this.benefitSummary[i]?.employeeCost_PayPeriod && this.benefitSummary[i]?.employeeCost_PayPeriod != 0) {
      return true
    }
    if (!this.isPerPay && this.benefitSummary?.length > 0 && this.benefitSummary[i]?.employeeCost_Annual && this.benefitSummary[i]?.employeeCost_Annual != 0) {
      return true
    }
  }

  benefitEmployerSummaryToggle(i) {
    if (this.isPerPay && this.benefitSummary?.length > 0 && this.benefitSummary[i].employerCost_PayPeriod && this.benefitSummary[i].employerCost_PayPeriod != 0) {
      return true
    }
    if (!this.isPerPay && this.benefitSummary?.length > 0 && this.benefitSummary[i].employerCost_Annual && this.benefitSummary[i].employerCost_Annual != 0) {
      return true
    }
  }
}
