<div [innerHTML]="options.cardContent | safeHtml"></div>
<div class="{{options.cssClass}}">
  @for (card of options.items; track card; let i = $index; let first = $first) {
    <div>
      @if ((!first) && (card.label == 'true' || card.label === '')) {
        <hr aria-hidden="true">
      }
      @if (card.label == 'true' || card.label === '') {
        <div [innerHTML]="card.cardContent | safeHtml" class="{{card.cssClass}}"></div>
      }
    </div>
  }
</div>
