@if (product.benefitPlanName) {
  @switch (benefitPlanNameHeading?.tagLevel) {
    @case (benefitPlanNameHeadingTagLevels.h5) {
      <h5 class="color-2 {{benefitPlanNameHeading?.class}}">{{product.benefitPlanName}}</h5>
    }
    @case (benefitPlanNameHeadingTagLevels.h4) {
      <h4 class="color-2 {{benefitPlanNameHeading?.class}}">{{product.benefitPlanName}}</h4>
    }
    @default {
      <h3 class="color-2 {{benefitPlanNameHeading?.class}}" [ngClass]="{'h5': !benefitPlanNameHeading?.class}">
      {{product.benefitPlanName}}</h3>
    }
  }
}
<!-- Tiered -->
@if (product.lookupLineType_RecordID == 1) {
  @if (product.addOnGroups) {
    <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
  } @else {
    @if (!fromBenefitStatement && product.showPlanDetails) {
      <div class="m-b-20 no-export">
        <a (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
          attr.aria-label="{{resourceStrings['Aon.BenefitDetails.PlanDetails']}} {{product.name | striphtml}}"
        [innerHTML]="resourceStrings['Aon.BenefitDetails.PlanDetails'] | safeHtml"></a>
      </div>
    }
  }
}
<!-- Currency -->
@if (product.lookupLineType_RecordID == 6 || product.lookupLineType_RecordID == 8 || product.lookupLineType_RecordID == 4) {
  <!-- Plan and Coverage -->
  <div class="m-b-20">
    @if (product.benefitPlanName != product.coverageName) {
      <span [ngClass]="{ 'strong' : product.addOnGroups && product.valueSummary}"
      [innerHTML]="product.coverageName | safeHtml"></span>
    }
    @if (product.coverageName && (product.benefitPlanName != product.coverageName) && product.employeeVolume && !product?.hideCoverageVolume && !product.valueSummary) {
      <span
      >,&nbsp;</span>
    }
    @if (product.employeeVolume && !product?.hideCoverageVolume && !product.valueSummary) {
      <span
      >{{ShowDataByAmountType}}</span>
    }
    @if (product.valueSummary) {
      <p class="{{helperService.getPdfUnicodeCharacterOverrideClasses(product.valueSummary)}}"
      [innerHTML]="product.valueSummary | safeHtml"></p>
    }
  </div>
  @if (product.addOnGroups) {
    <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
  } @else {
    @if (!fromBenefitStatement && product.showPlanDetails) {
      <div class="m-b-20 no-export">
        <a (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
          attr.aria-label="{{resourceStrings['Aon.BenefitDetails.PlanDetails']}} {{product.name | striphtml}}"
        [innerHTML]="resourceStrings['Aon.BenefitDetails.PlanDetails'] | safeHtml"></a>
      </div>
    }
  }
}
<!-- Volume -->
@if (product.lookupLineType_RecordID == 2) {
  <!-- Plan and Coverage -->
  <div>
    <!-- Condition 1 -->
    <div class="m-b-20">
      @if (((product.benefitPlanName || product.coverageName) && (product.benefitPlanName == product.coverageName) && !(product.hideCoverageVolume)) && ((product.employeeVolume && !product?.hideCoverageVolume) || product.valueSummary)) {
        <div class="d-flex"
          >
          @if (product.employeeVolume && !product?.hideCoverageVolume) {
            <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(ShowDataByAmountType)}}"
            >{{ShowDataByAmountType}}</p>
          }
        </div>
      }
      <!-- condition 2 -->
      @if ((product.benefitPlanName != product.coverageName) && !(product.hideCoverageVolume) && ((product.employeeVolume && !product?.hideCoverageVolume) || product.valueSummary || product.coverageName)) {
        <div class="d-flex"
          >
          @if (product.benefitPlanName != product.coverageName) {
            <p class="m-b-0"
            [innerHTML]="product.coverageName | safeHtml"></p>
          }
          @if (product.coverageName && product.employeeVolume && !product?.hideCoverageVolume) {
            <p class="m-b-0">
              ,&nbsp;
            </p>
          }
          @if (product.employeeVolume && !product?.hideCoverageVolume) {
            <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(ShowDataByAmountType)}}"
            >{{ShowDataByAmountType}}</p>
          }
        </div>
      }
      <!-- condition 3 -->
      @if ((product.benefitPlanName != product.coverageName) && (product.hideCoverageVolume)) {
        <div class="d-flex"
          >
          @if (product.benefitPlanName != product.coverageName) {
            <p class="m-b-0"
            [innerHTML]="product.coverageName | safeHtml"></p>
          }
        </div>
      }
      @if (product.valueSummary) {
        <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(product.valueSummary)}}"
        [innerHTML]="product.valueSummary | safeHtml"></p>
      }
    </div>
    @if (product.addOnGroups) {
      <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
    } @else {
      @if (!fromBenefitStatement && product.showPlanDetails) {
        <div class="m-b-20 no-export">
          <a (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
            attr.aria-label="{{resourceStrings['Aon.BenefitDetails.PlanDetails']}} {{product.name | striphtml}}"
          [innerHTML]="resourceStrings['Aon.BenefitDetails.PlanDetails'] | safeHtml"></a>
        </div>
      }
    }
  </div>
}

<!-- Addons Section -->
<ng-template #AddOnSection>
  @if (addOnWithIsCoverage.length > 0) {
    @for (addOn of addOnWithIsCoverage; track addOn) {
      @if (addOn.name && addOn.valueSummary) {
        <div class="m-b-20">
          <p class="strong m-b-0">{{addOn.name}}</p>
          <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(addOn.valueSummary)}}"
          [innerHTML]="addOn.valueSummary | safeHtml"></p>
        </div>
      }
    }
  }
  <ng-container *ngTemplateOutlet="PlanDetailsLinkSection"></ng-container>
  @if (addOnNotWithIsCoverage.length > 0) {
    <label class="strong m-b-0" [innerHTML]="resourceStrings['Aon.AddOns.label'] | safeHtml"></label>
    <div class="d-flex flex-wrap">
      @for (addOn of addOnNotWithIsCoverage; track addOn; let islast = $last) {
        <p class="m-b-0">
          {{addOn.name}}@if (!islast && (!addOn.valueSummary || addOn.valueSummary == '')) {
          ,&nbsp;
        }
        @if (addOn.valueSummary) {
          <span class="{{helperService.getPdfUnicodeCharacterOverrideClasses(addOn.valueSummary)}}">&nbsp;(<span
          [innerHTML]="addOn.valueSummary | safeHtml"></span>)</span>
          @if (!islast) {
            ,&nbsp;
          }
        }
      </p>
    }
  </div>
}
</ng-template>

<!-- Plan Details Link section -->
<ng-template #PlanDetailsLinkSection>
  @if (!fromBenefitStatement && product.showPlanDetails) {
    <div class="m-b-20 no-export">
      <a (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
        attr.aria-label="{{resourceStrings['Aon.BenefitDetails.PlanDetails']}} {{product.name | striphtml}}"
      [innerHTML]="resourceStrings['Aon.BenefitDetails.PlanDetails'] | safeHtml"></a>
    </div>
  }
</ng-template>
