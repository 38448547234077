import { Component, OnInit, Input, Output, EventEmitter, Injector } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { ProfileService } from '../../modules/profile/profile.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { SelectEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-participant-documents',
  templateUrl: './participant-documents.component.html'
})
export class ParticipantDocumentsComponent extends TBSBaseComponent implements OnInit {
  @Input() participantData: any;
  @Input() isCompleteStatus: boolean;
  @Input() participantId: number | string;
  @Input() index: number;
  @Input() fromDoc: boolean = false;
  @Input() employeeBenefitID: number = null;
  @Input() lineRecordID: string = null;
  successMessage: string[];
  @Output() public updateParticipant = new EventEmitter();
  public progress: number;
  public message: string;
  errorMessage: string[];
  participantIndex: number;
  selectedEmployeeCorrespondence_RecordID: string;
  ngZone: any;

  constructor(private profileService: ProfileService,
    private readonly ngxService: NgxUiLoaderService, private readonly injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.UploadDocument.NoDocuments',
      'Aon.UploadDocument.UploadDocumentsText',
      'Aon.Document.MyDocuments.InvalidFileFormat',
      'Aon.Document.MyDocuments.DocumentIsRequired',
      'Aon.Document.MyDocuments.InvalidFileSize',
      'Aon.Profile.Documents.Download.Label',
      'Aon.Profile.Documents.Edit.Label',
      'Aon.Documents.Complete',
      'Aon.Document.MyDocuments.MalwarFile',
      'Aon.UploadDocument.SuccessMessage',
      'Aon.Common.Required',
      'Aon.Common.Optional'
    ]);
  }

  ngOnInit(): void {
    this.ngxService.start('PARTICIPANT-RS');
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
        this.ngxService.stop('PARTICIPANT-RS');
      });
    this.errorMessage = new Array<string>(this.participantData.length + 1);
    this.successMessage = new Array<string>(this.participantData.length + 1);
  }

  public uploadFile = (files) => {
    for (let i = 0; i < this.participantData.length; i++) {
      this.errorMessage[i] = "";
    }

    if (files.length === 0 && this.participantData[this.participantIndex]?.documents?.length == 0) {
      this.errorMessage[this.participantIndex] = this.resourceStrings["Aon.Document.MyDocuments.DocumentIsRequired"];
      return;
    }
    this.ngxService.start();
    let fileToUpload = <File>files[0];

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    if (this.participantId != null) {
      formData.append('EmployeeRelationship_RecordID', this.participantId.toString());
    }
    if (this.selectedEmployeeCorrespondence_RecordID != null) {
      formData.append('EmployeeCorrespondence_RecordID', this.selectedEmployeeCorrespondence_RecordID.toString());
    }
    formData.append('CorrespondenceType_RecordID', this.participantData[this.participantIndex].recordID.toString());

    if (this.employeeBenefitID != null) {
      formData.append('EmployeeBenefitID', this.employeeBenefitID.toString());
      formData.append('Line_RecordID', this.lineRecordID.toString());
    }
    if (this.validateDocumentUpload(fileToUpload)) {
      this.profileService.saveEmployeeCorrespondenceDocument(formData)
        .subscribe(event => {
          if (event.type === HttpEventType.UploadProgress)
            this.progress = Math.round(100 * event.loaded / event.total);
          else if (event.type === HttpEventType.Response) {
            if (event.body['employeeCorrespondenceDocument'] != null) {
              this.participantData[this.participantIndex].documents[0] = event.body['employeeCorrespondenceDocument'];
              this.successMessage[this.participantIndex] = this.resourceStrings["Aon.UploadDocument.SuccessMessage"];
            }
            if (event.body['saveResult'] != null && event.body['saveResult'].didSave == false) {
              this.errorMessage = [...event.body['saveResult'].errorMessages];
              if (this.errorMessage[this.participantIndex] != "") {
                this.errorMessage[this.participantIndex] = this.errorMessage[this.participantIndex].replace("Aon.Document.MyDocuments.InvalidFileFormat", this.resourceStrings['Aon.Document.MyDocuments.InvalidFileFormat']);
                this.errorMessage[this.participantIndex] = this.errorMessage[this.participantIndex].replace("Aon.Document.MyDocuments.InvalidFileSize", this.resourceStrings['Aon.Document.MyDocuments.InvalidFileSize']);
                this.errorMessage[this.participantIndex] = this.errorMessage[this.participantIndex].replace("Aon.Document.MyDocuments.MalwarFile", this.resourceStrings['Aon.Document.MyDocuments.MalwarFile']);
              }
            }
            let data = {
              participant: this.participantData,
              index: this.index
            }
            this.updateParticipant.emit(data);
            this.ngxService.stop();
          }
        });
    } else {
      this.ngxService.stop();
    }
  }
  selectEventHandler(e: SelectEvent) {
    const files = [];
    e.files.forEach(function (file) {
      files.push(file.rawFile);
    });
    this.uploadFile(files);
  }
  triggerFileClick(upload) {
    document.getElementById(upload.fileSelectButton.nativeElement.id).click();
  }
  public downloadFile(employeeCorrespondenceDocumentId: string) {
    this.profileService.downloadFile(employeeCorrespondenceDocumentId);
  }

  // Save the Participant Id for the uploaded file
  setParticipantIndex(participantIndex: number): void {
    this.participantIndex = participantIndex;
  }

  validateDocumentUpload(file: File) {
    let status = this.validateFile(file);
    switch (status) {
      case -1:
        this.errorMessage[this.participantIndex] = this.resourceStrings['Aon.Document.MyDocuments.InvalidFileFormat'] + ' (' + file.name + ')';
        return false;
      case -2:
        this.errorMessage[this.participantIndex] = this.resourceStrings['Aon.Document.MyDocuments.InvalidFileSize'] + ' (' + file.name + ')';
        return false;
      default:
        break;
    }
    this.errorMessage[this.participantIndex] = "";
    return true;
  }

  getFileExt(file) {
    return (-1 !== file.name.indexOf('.')) ? file.name.replace(/.*[.]/, '') : '';
  }

  validateFile(file: File) {
    if (this.participantData[this.participantIndex] != null) {
      let fileExt = this.participantData[this.participantIndex].fileExtensions;
      let maxSize = this.participantData[this.participantIndex].maxSize; //MB based
      if (maxSize != null && maxSize > 0) {
        maxSize = maxSize * (1024 * 1024); //Byte based
      }
      else {
        maxSize = 0;
      }

      if (fileExt != null && fileExt.length > 0) {
        let ext = this.getFileExt(file);
        let regex = new RegExp("^(" + fileExt + ")$", "i");
        if (!(ext && regex.test(ext))) {
          return -1;
        }
      }

      if (maxSize != null && maxSize > 0) {
        if (file.size > maxSize) {
          return -2;
        }
      }
    }
    return 0;
  }

}
