@if (isNormalLogin()) {
  @if (!showSucessStep) {
    <div>
      <div class="modal-header p-b-8">
        @if (!curExpressLoginStep) {
          <h3 class="modal-title">{{resourceStrings['Aon.Login.LogInToContinue']}}</h3>
        }
        @if (!hideCloseButton) {
          <button type="button" class="close" title=""
            [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']" (click)="onClose()"
            >
            <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
          </button>
        }
      </div>
      <div class="modal-body">
        @if (!curExpressLoginStep) {
        <div class="row">
          <div class="col-md-12">
            <p class="lg m-b-32" id="not-registered">
              {{resourceStrings['Aon.Login.NotRegisteredUser']}}
              <a id="create-account" href="javascript:void(0);" (click)="createAccount()" class="clr-inherit"
                 aria-labelledby="not-registered">{{resourceStrings['Aon.Login.CreateAccount']}}</a>
            </p>
          </div>
        </div>
        @if (uiConfig?.uiConfiguration?.showLoginWelcomeText) {
        <div class="row">
          <div class="col-12 d-flex">
            <h5>
              {{resourceStrings['Aon.Login.Welcome']}}
            </h5>
          </div>
        </div>
        }
        <div>
          <form [formGroup]="loginForm">
            <div class="form-group">
              <label for="username"
                     [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Login.UserName']}}</label>
              @if (f.username.invalid && (f.username.dirty || f.username.touched)) {
              <div class="alert contextual error">
                @if (f.username.errors.required) {
                <div role="alert">
                  <i class="fas fa-exclamation-triangle"
                     aria-hidden="true"></i>{{resourceStrings['Aon.Login.UserNameIsRequired']}}
                </div>
                }
              </div>
              }
              <input type="text" formControlName="username" id="username" class="m-b-8"
                     [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
              @if (uiConfig?.uiConfiguration?.showForgotUsernameLinkonLoginPage) {
              <p>
                <a [routerLink]="[baseUrl+'/account/forgotusername']"
                   (click)="linkClick()">{{resourceStrings['Aon.Login.ForgotUserName']}}</a>
              </p>
              }
            </div>
            <div class="form-group">
              <label for="password"
                     [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.Password']}}</label>
              @if (f.password.invalid && (f.password.dirty || f.password.touched)) {
              <div class="alert contextual error">
                @if (f.password.errors.required) {
                <div role="alert">
                  <i class="fas fa-exclamation-triangle"
                     aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                </div>
                }
              </div>
              }
              <aon-input-password formControlName="password" [id]="'password'"
                                  [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
                                  [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
              @if (!isFromForgotPassword) {
              <p class="m-t-8">
                <a [routerLink]="[baseUrl+'/account/forgotpassword']"
                   (click)="linkClick()">{{resourceStrings['Aon.Login.Forgotpassword']}}</a>
              </p>
              }
            </div>
          </form>
        </div>
        <div class="row m-b-20">
          @if (isExpressCheckoutEnabled()) {
          <div class="col-md-6">
            <button class="login-continue-as-guest spinner button-style subtle" (click)='goToExpressLogin()'>
              {{resourceStrings['Aon.Login.ExpressCheckout']}}
            </button>
          </div>
          }
          <div class="col-md-6">
            <button class="login-submit spinner" [disabled]="loading" (click)='onSubmit()'>
              {{resourceStrings['Aon.Login.LoginText']}}
              @if (loading) {
              <span class="spinner-border spinner-border-sm"></span>
              }
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="sm" id="need-help">
              {{resourceStrings['Aon.Account.NeedHelp']}} <a id="contact-help"
                                                             [routerLink]="[baseUrl+'/home/help']" (click)="linkClick()"
                                                             aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a>
            </p>
          </div>
        </div>
        }
      </div>
      </div>
    }
    @if (showSucessStep) {
      <div>
        <div class="modal-header">
          <h3 class="modal-title" tabindex="0"> {{resourceStrings['Aon.Login.SuccessHeader']}}</h3>
          <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Common.Close']" (click)="onClose()">
            <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div [innerHTML]="resourceStrings[loginSucessInfoMsgKey] | safeHtml"></div>
          <button class="login-done spinner" [disabled]="loading" (click)='onSuccess()'>
            {{resourceStrings['Aon.Login.Done']}}
            @if (loading) {
              <span class="spinner-border spinner-border-sm m-l-4"></span>
            }
          </button>
        </div>
      </div>
    }
  }
  @if (!isNormalLogin()) {
    <app-express-login [curExpressLoginStep]="curExpressLoginStep" [hideCloseButton]="hideCloseButton"
      (goToLoginScreen)="goToLogin()" (closeExpressLoginCallback)="closeExpressLogin()"
    (afterSumbitCallback)="afterSumbit()"></app-express-login>
  }
