<div class="coverage-eoi" [ngClass]="{'basic-card': options?.displayInCard}">
  <h6>{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Coverage'] }}</h6>
  <table class="coverage-table table" [attr.aria-label]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Coverage']">
    <thead>
      <tr>
        <th>{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Costs'] }} </th>
        <th>{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim'] }}</th>
        <th>{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested'] }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <p class="m-b-0">{{ resourceStrings['Aon.BenefitDetails.YourCoverageTitle'] }}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
          <p class="m-b-0">{{ coverage?.employeeCoverage?.interim }}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
          <p class="m-b-0">{{ coverage?.employeeCoverage?.requested }}</p>
        </td>
      </tr>
      @if (coverage?.volume?.extra?.visibility) {
      <tr>
        <td>
          <p class="m-b-0">{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Volume'] }}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
          <p class="m-b-0">{{helperService.ShowDataByAmountType(coverage?.volume?.interim,
            coverage?.volume?.extra?.showDataByAmountTypeInterim?.planData)}}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
          <p class="m-b-0">
            {{helperService.ShowDataByAmountType(coverage?.volume?.requested ,
            coverage?.volume?.extra?.showDataByAmountTypeRequested?.planData,
            coverage?.volume?.extra?.showDataByAmountTypeRequested?.lineData)}}</p>
        </td>
      </tr>
      }
      @if (coverage?.employeeContribution?.extra?.visibility) {
      <tr>
        <td>
          <p class="m-b-0" [innerHTML]="coverage?.employeeContribution?.extra?.label | safeHtml">
          </p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
          <p class="m-b-0">{{ coverage?.employeeContribution?.interim | currency:
            options?.currencyCode }}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
          <p class="m-b-0">{{ coverage?.employeeContribution?.requested | currency:
            options?.currencyCode }}</p>
        </td>
      </tr>
      }
      @if (coverage?.employerContribution?.extra?.visibility) {
      <tr>
        <td>
          <p class="m-b-0" [innerHTML]="coverage?.employerContribution?.extra?.label | safeHtml">
          </p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
          <p class="m-b-0">{{ coverage?.employerContribution?.interim | currency:
            options?.currencyCode }}</p>
        </td>
        <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
          <p class="m-b-0">{{ coverage?.employerContribution?.requested | currency:
            options?.currencyCode }}</p>
        </td>
      </tr>
      }
      @if (coverage?.employerContribution?.extra?.visibility) {
      <tr>
        <td>
          <p class="strong m-b-0" [innerHTML]="coverage?.totalContribution?.extra?.label | safeHtml">
          </p>
        </td>
        <td>
          <p class="strong m-b-0">{{ coverage?.totalContribution?.interim | currency:
            options?.currencyCode }}
          </p>
        </td>
        <td>
          <p class="strong m-b-0">{{ coverage?.totalContribution?.requested | currency:
            options?.currencyCode
            }}</p>
        </td>
      </tr>
      }
    </tbody>
  </table>
  @if(participants?.length > 0) {
  <div>
    <h6>{{ resourceStrings['Benefit.ExpansionPanelTable.EOI.Participants'] | uppercase }}</h6>
    <table class="table" [attr.aria-label]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Participants']">
      <thead>
        <tr>
          <th class="d-none d-md-block" id="benefitExpansionPanelTableEOIInterimInTableHeader">{{
            resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim'] }}</th>
          <th id="benefitExpansionPanelTableEOIRequestedInTableHeader">{{
            resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested'] }}</th>
        </tr>
      </thead>
      <tbody>
        @for(participant of participants; track $index; let islast = $last) {
        <tr>
          <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
            @if (participant.interim) {
            <span class="label m-b-0 d-none d-md-block">
              {{participant.interim?.firstName + ' ' + participant.interim?.lastName + '
              (' +
              participant.interim?.relationshipType_Name + ')'}}
              @if (!islast) {
              &nbsp;
              }
            </span>
            }
          </td>
          <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
            @if (participant.requested) {
            <span class="label m-b-0 d-none d-md-block">
              {{participant.requested?.firstName + ' ' +
              participant.requested?.lastName + ' (' +
              participant.requested?.relationshipType_Name + ')'}}<ng-container>&nbsp;
              </ng-container>
            </span>
            }
          </td>
        </tr>
        }
        <div class="d-block d-md-none participants-mobile-view">
          <div class="interim-wrapper">
            <tr>
              <th id="benefitExpansionPanelTableEOIInterim">{{
                resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim'] }}</th>
            </tr>
            @for(participant of participants; track $index; let islast = $last) {
            <tr>
              <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Interim']">
                @if (participant.interim) {
                <span class="label m-b-0">
                  {{participant.interim?.firstName + ' ' + participant.interim?.lastName +
                  ' (' +
                  participant.interim?.relationshipType_Name + ')'}}
                  @if (!islast) {
                  &nbsp;
                  }
                </span>
                }
              </td>
            </tr>
            }
          </div>
          <tr>
            <th id="benefitExpansionPanelTableEOIRequested">{{
              resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested'] }}</th>
          </tr>
          @for(participant of participants; track $index) {
          <tr>
            <td [attr.data-th]="resourceStrings['Benefit.ExpansionPanelTable.EOI.Requested']">
              @if (participant.requested) {
              <span class="label m-b-0">
                {{participant.requested?.firstName + ' ' +
                participant.requested?.lastName + ' (' +
                participant.requested?.relationshipType_Name + ')'}}<ng-container>
                  &nbsp;
                </ng-container>
              </span>
              }
            </td>
          </tr>
          }
        </div>
      </tbody>
    </table>
  </div>
  }
</div>