import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BootstrapRequestContext } from 'tbs-typings';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Injectable({
  providedIn: 'root'
})
export class UiconfigrationService {
  readonly #apiBaseUrl: string;
  readonly #siteName: string;

  constructor(
    private readonly http: HttpClient,
    private readonly globalObjectsService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.#apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.#siteName = bootstrapRequestContext.siteName;
  }

  getUIConfiguration(): UIConfigurationType {
    let result: UIConfigurationType;

    if (sessionStorage.getItem('uiConfigPerRole') != null) {
      result = JSON.parse(sessionStorage.getItem('uiConfigPerRole'));
      return result;
    }
    if (sessionStorage.getItem('uiConfigGuest') != null) {
      result = JSON.parse(sessionStorage.getItem('uiConfigGuest'));
      return result;
    }
  }

  private readonly afterUIConfigurationReceived = (data) => {
    this.#setUiConfig(data);
    if (sessionStorage.getItem('currentUser') != null && this.globalObjectsService.headerFooterConfigRefresh) {
      this.globalObjectsService.checkHeaderFooterConfig.next(true);
      this.globalObjectsService.headerFooterConfigRefresh = false;
      this.globalObjectsService.logoPath.next(data?.uiConfiguration?.logoPath);
    }
    return data;
  }

  getUIConfigurationFromService(uiConfigID: string = null): Observable<UIConfigurationType> {
    let requestUrl = this.#apiBaseUrl + this.#siteName + '/uiconfiguration';
    if (uiConfigID) {
      requestUrl += '/' + uiConfigID;
    }
    return this.http.get<UIConfigurationType>(requestUrl).pipe(
      map(this.afterUIConfigurationReceived)
    );
  }

  getDefaultUIConfigrationFromService() {
    const requestUrl = this.#apiBaseUrl + 'uiconfiguration/default';
    return this.http.get<UIConfigurationType>(requestUrl);
  }

  #setUiConfig(data: UIConfigurationType) {
    this.globalObjectsService.uiConfigs.next(data);

    if (sessionStorage.getItem('currentUser') != null) {
      const uiConfig = JSON.stringify(data);
      sessionStorage.setItem('uiConfigPerRole', uiConfig);
    } else {
      const uiConfig = JSON.stringify(data);
      sessionStorage.setItem('uiConfigGuest', uiConfig);
    }
  }
}
