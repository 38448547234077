import { Component, Injector, OnInit } from '@angular/core';
import { TBSBaseComponent } from '../../models/tbsBaseComponent';
import { CommonService } from '../../../core/services';
import { take } from 'rxjs';
import { ContentItemModel } from '../../models/contentItem.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-content-popup',
  templateUrl: './content-popup.component.html',
  styles: [
  ]
})
export class ContentPopupComponent extends TBSBaseComponent implements OnInit {

  contentItemPath: string;
  content: ContentItemModel;
  constructor(
    private readonly injector: Injector,
    private readonly commonService: CommonService,
    private readonly modalService: NgbActiveModal
  ) {
    super(injector)
    this.addResourceStringKeys([
      'Aon.Article.ViewAll'
    ]);
  }
  ngOnInit(): void {
    this.commonService.getContentItemByPath(this.contentItemPath).pipe(take(1)).subscribe(content => {
      this.content =content;
    });
  }
  close() {
    this.modalService.close(false);
  }
}
