@if (pageContentAvailable ) {
  <div class="page" id="client-content-page">
    <div class="page-content-sleeve container-fluid">
      @if (clientContent && clientContent.content) {
        <div class="row">
          <h1 class="client-content-header col-12">{{clientContent.content.contentItemTitle}}</h1>
          <div class="client-content-body col-12" [innerHTML]="clientContent.content.contentItemBody | safeHtml">
          </div>
          @if (clientContent.content.ctAsList.length>0) {
            <div class="button-container m-t-32 col-12">
              @for (list of clientContent.content.ctAsList; track list) {
                <app-aon-cta [fragment]="list.linkUrl.split('#')[1]" [callToAction]="list"
                  [redirectUrl]="list.linkUrl.includes('#') ? list.linkUrl.split('#')[0] : list.linkUrl"
                (ctaCustomFunc)="ctaCustomFn.ctaCustomFuncHandler($event)"></app-aon-cta>
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
} @else {
  <app-page-not-found></app-page-not-found>
}
