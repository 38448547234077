import { AfterViewInit, Component, ElementRef, OnInit, Injector, OnDestroy } from '@angular/core';
import { Product } from '../../../shared/models/product.model';
import { Faq } from '../../../shared/models/faq.model';
import { ProductService } from '../../product/product.service';
import { CommonPageModel } from '../../../shared/models/commonPage.model';
import { GlobalObjectsService } from '../../../shared/services/global-objects.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ContentItemAndCommonPage } from 'src/app/shared/models/ContentItemAndCommonPage.model';
import { Location } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { take, takeUntil } from 'rxjs/operators';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';

@Component({
  selector: 'app-benefit-info-modal',
  templateUrl: './benefit-info-modal.component.html'
})
export class BenefitInfoModalComponent extends TBSBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  products: Product[];
  faqTitle: string;
  faqs: Faq[];
  productdetails: ContentItemAndCommonPage;
  editorial: CommonPageModel;
  benefitId: string;
  benefitName: string;
  productType: string;
  campaignKey: string;
  currentProduct: Product;
  prod: Product[];
  active: any = 0;
  removeRoleDialog: boolean = false;

  constructor(
    private readonly injector: Injector,
    public  readonly activeModal: NgbActiveModal,
    private readonly ngxService: NgxUiLoaderService,
    private readonly productService: ProductService,
    private readonly globalojectService: GlobalObjectsService,
    private readonly elementRef: ElementRef,
    private readonly location: Location,
    private readonly modalService: NgbActiveModal,
    private readonly authenticationService: AuthenticationService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Common.LearnMore',
      'Aon.Common.About',
      'System.Common.Cancel',
      'Aon.BenefitDetails.GoBack'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
    this.campaignKey = this.helperService.getCampaignKey();
    this.init(this.benefitId);
  }

  ngAfterViewInit(): void {
    if (this.removeRoleDialog) {
      try {
        // Removing the role dialog because it's opened as a full page not in the modal window
        this.elementRef.nativeElement.parentElement.parentElement.parentElement.removeAttribute('role');
        this.elementRef.nativeElement.parentElement.parentElement.parentElement.removeAttribute('aria-modal');
      } catch { }
    }
    this.ensureCustomControls();
  }

  handlekeyDownEsc(event) {
    this.close(event)
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  init(id): void {
    this.benefitId = id;

    this.ngxService.start();
    const languageCode = this.helperService.getCurrentLanguageCode().cultureCode;
    const parentPath = '/Products';
    this.productService.getproductItems(languageCode, id, parentPath, this.campaignKey)
      .pipe(take(1))
      .subscribe((productModel) => {
        this.productType = productModel.productName;

        this.faqTitle = productModel.faqTitle;
        this.faqs = productModel.faqs;
        this.productdetails = productModel.productDetails;

        this.globalojectService.Products.subscribe((r) => {
          this.products = r.filter((r) => r.line_GUID != this.benefitId);
          this.prod = r.filter((r) => r.line_GUID == this.benefitId);
          this.currentProduct = this.prod[0];
          this.faqs?.forEach(singleFAQ => {
            singleFAQ.productname = this.currentProduct?.gaTag;
          });
          this.ngxService.stop();
        });
      });
  }

  goBack(): void {
    this.location.back();
  }

  ensureCustomControls(): void {
    this.helperService.ensureCustomAccordion(this.elementRef);
    this.helperService.ensureCustomeCollapse(this.elementRef);
  }

  getSectionId(section: string | number): string {
    return "section-" + section;
  }

  close(event: any) {
    this.modalService.close();
    /**
     * To prevent the next key press event auto trigger otherwise the modal will reopen again and it become a loop
     */
    event.stopPropagation()
  }
  onClose() {
    this.activeModal.dismiss();
  }

  setActive(activeId: number | string) {
    this.active = Number(activeId);
  }

  get showJumpToSection(): boolean {
    if (
      (this.productdetails?.items?.contentItemsList?.length > 1 && !this.faqs?.length)
      || (this.productdetails?.items?.contentItemsList?.length >= 1 && this.faqs?.length)
    ) {
      return true;
    }
    return false;
  }
}
