import { ElementRef, Pipe, PipeTransform } from '@angular/core';
import { HelperService } from 'src/app/shared/services/helper.service';

@Pipe({ name: 'currency' })
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private readonly helperService: HelperService, private readonly elementRef: ElementRef) { }
  transform(value: any, currencyCode?: string, amountType: string = '2', showAsNegative = false, overrideCurrencyFromUIConfigSetting: boolean = true) {
    //Amount type list as below
    //AMOUNT_TYPE_PERCENT_SALARY = 1;
    //AMOUNT_TYPE_CURRENCY = 2;
    //AMOUNT_TYPE_VOUCHER = 3;
    //AMOUNT_TYPE_DAYS = 4;
    //AMOUNT_TYPE_HOURS = 5;
    //AMOUNT_TYPE_VOUCHERS = 7;
    //AMOUNT_TYPE_CUSTOMTYPE = 8;
    try {
      if (value === Infinity) { value = 0 };
      if (amountType === null || amountType === undefined) {
        amountType = '2'
      }
      if (value === '-') {
        return '-';
      }
      const overrideConcealTheSensitiveData = this.helperService.concealTheSensitiveData ? this.helperService.isElementInsideKendoPDFExport(this.elementRef?.nativeElement) : false;
      let data = this.helperService.currencyAmountValue(value, currencyCode, amountType, overrideCurrencyFromUIConfigSetting);
      if (showAsNegative) { data = '-' + data }
      if (overrideConcealTheSensitiveData) {
        let unConcealedData = this.helperService.currencyAmountValue(value, currencyCode, amountType, overrideCurrencyFromUIConfigSetting, overrideConcealTheSensitiveData);
        if (showAsNegative) { unConcealedData = '-' + unConcealedData }
        return this.helperService.getThePreProcessedKendoPDFExportHTMLString(data, unConcealedData);
      }
      try {
        this.helperService.getPdfUnicodeCharacterOverrideClasses(data)?.split(" ")?.forEach(x => this.elementRef.nativeElement.parentElement.classList.add(x));
      } catch { }
      return data;
    }
    catch {
      return '';
    }
  }
}

