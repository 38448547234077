<app-editable-mbr-site-toolbar [instance]="this" [controls]="toolbarControlsForMasthead"
    [type]="ToolbarType.Global"></app-editable-mbr-site-toolbar>
<div class="site-masthead" aria-label="navContent" role="navigation" id="nav">
    <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="{ 'navbar-collapsed': isNavbarCollapsed }"
        aria-label="site-masthead-nav" id="nav-site-masthead">
        <div class="d-md-block">
            <app-masthead-logo [resourcestrings]="resourceStrings" (onCloseClick)="closemodalcross($event)">
            </app-masthead-logo>
        </div>

        <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
            <ul class="head-menu navbar-nav ms-auto">
                @if (authenticationService.isLogin && isInternalEditorSite) {
                <li ngbDropdown class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
                    [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
                    #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
                    <a class="nav-link text-info focus-visibility"
                        [attr.aria-label]="resourceStrings['Aon.GlobalBenefitLibrary.LibraryItems']"
                        (click)="closeOtherDropdowns(myUser)"
                        [routerLink]="[baseUrl + 'globalbenefitslibrary/library-items']" routerLinkActive="active"
                        [attr.aria-expanded]="myUser.isOpen()" role="button" ngbDropdownToggle>
                        <div class="icon-badge-container d-flex align-items-center">
                            <span [ngbTooltip]="'Library Items'" data-heap-redact-text>
                                {{resourceStrings['Aon.GlobalBenefitLibrary.LibraryItems']}}
                            </span>
                        </div>
                    </a>

                </li>
                }
                <li ngbDropdown class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
                    [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
                    #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
                    <a class="nav-link text-info focus-visibility"
                        [attr.aria-label]="preferredName + ', ' + resourceStrings['Aon.Navigation.Profile']"
                        (click)="closeOtherDropdowns(myUser)" [routerLink]="[baseUrl + '/selfservice/publish-tasks']"
                        routerLinkActive="active" [attr.aria-expanded]="myUser.isOpen()" role="button"
                        ngbDropdownToggle>
                        <div class="icon-badge-container d-flex align-items-center">
                            <span [ngbTooltip]="'Publish Tasks'" data-heap-redact-text>
                                {{resourceStrings['Aon.SelfService.PublishTask']}}
                            </span>
                        </div>
                    </a>

                </li>
                <li ngbDropdown class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
                    [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
                    #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
                    <a class="nav-link text-info focus-visibility"
                        [attr.aria-label]="preferredName + ', ' + resourceStrings['Aon.Navigation.Profile']"
                        href="javascript:void(0)" (click)="closeOtherDropdowns(myUser)"
                        [routerLink]="[baseUrl + '/article/articleindex']" [attr.aria-expanded]="myUser.isOpen()"
                        role="button" ngbDropdownToggle>
                        <div class="icon-badge-container d-flex align-items-center">
                            <span [ngbTooltip]="'Article Index'" data-heap-redact-text>
                                {{resourceStrings['Aon.SelfService.ArticleIndex']}}
                            </span>
                        </div>
                    </a>

                </li>
                @if (authenticationService.isLogin && headMenuObject.profile) {
                <li ngbDropdown class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
                    [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
                    #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
                    <a class="nav-link text-info focus-visibility"
                        [attr.aria-label]="preferredName + ', ' + resourceStrings['Aon.Navigation.Profile']"
                        href="javascript:void(0)" (click)="closeOtherDropdowns(myUser)"
                        [attr.aria-expanded]="myUser.isOpen()" role="button" ngbDropdownToggle>
                        <div class="icon-badge-container d-flex align-items-center">
                            <i class="aon-icon aon-user d-flex" aria-hidden="true"></i><span
                                [ngbTooltip]="preferredName" data-heap-redact-text>
                                {{truncate(preferredName)}}@if ((isFailedPayment || hasGracePeriod)) {
                                <span class="badge bg-danger user-badge d-inline-block p-t-0 p-b-0 p-l-0 p-r-0"></span>
                                }
                            </span>

                        </div>
                    </a>

                </li>
                }

                @if (headMenuObject.language && !hideLanguageSelection) {
                <li class="nav-item-dropdown nav-li li-order-{{headMenuObject.language}} anaval-language-selection">
                    <app-language-selection (closeOtherDropdown)="closeOtherDropdowns($event)"
                        (hideLanguageSelection)="onAllowedLanguagesLoaded($event)"
                        [dropDownAutoClose]="dropDownAutoClose"></app-language-selection>
                </li>
                }
            </ul>
        </div>
    </nav>
</div>