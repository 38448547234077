<div class="row">
  <div class="col-sm-12">
    <div class="d-flex align-items-center m-b-20">
      <h3 class="m-b-0" [innerHTML]="product?.name | striphtml"></h3>
      @if (ssoBenefittag) {
        <span class="tag green-tag-wrapper">{{resourceStrings['Aon.SSO.BenefitTag']}}</span>
      }
    </div>
    <div class="m-b-20">
      <p [innerHTML]="product.description | striphtml" class="d-inline"></p>
      @if (product.showBenefitDetails) {
        <a class="m-l-2"
          (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
          attr.aria-label="{{resourceStrings['Aon.BenefitMatrix.ViewBenefit']}} {{product.name | striphtml}}"
        [innerHTML]="resourceStrings['Aon.BenefitMatrix.ViewBenefit'] | safeHtml"></a>
      }
    </div>

    @if (product.canInteract) {
      <div>
        <button class="subtle"
          attr.aria-label="{{resourceStrings['Aon.BenefitMatrix.Enrol']}} {{product.name | striphtml}}"
        [innerHTML]="resourceStrings['Aon.BenefitMatrix.Enrol'] | safeHtml" (click)="goToSableFlow(product)"></button>
      </div>
    }
  </div>
</div>