<div class="benefit-term-condition">
  <div class="modal-header">
    <div class="flex-grow-1">
      @if (agreementType?.title) {
        <h1 class="modal-title m-b-20" tabindex="0" [innerHTML]="agreementType?.title"></h1>
      }
      @if (agreementType?.agreementText) {
        <p class="m-b-20" [innerHTML]="agreementType?.agreementText"></p>
      }
    </div>
  </div>

  @if (agreementType) {
    <div class="modal-body">
      <div class="basic-card edit-state text-start" id="body">
        <div class="body-container">
          <div class="name term-details">
            @if (agreementType.content) {
              <p class="xl" [innerHTML]="agreementType.content | safeHtml"></p>
            }
          </div>
          <div class="divider m-t-16 m-b-20"></div>
          <div (click)="agreementType.assent = !agreementType.assent">
            <input type="checkbox" [(ngModel)]="agreementType.assent" />
            <label class="secondary">
              <span class="label content">{{ resourceStrings['Aon.Payment.AgreementAssent'] }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  }
  @if (saveError) {
    <div class="error-msg">{{ resourceStrings['Aon.Common.Error'] }}</div>
  }
  <div class="button-sleeve m-t-32">
    <button type="button" class="confirm-term-condition prominent" (click)="confirm()" [disabled]="!validate()">
      {{ resourceStrings['Aon.Common.Confirm'] }}
    </button>
    <button type="button" class="cancel-term-condition ghost" (click)="onClose()">{{ resourceStrings['Aon.Common.Back']
    }}</button>
  </div>
</div>