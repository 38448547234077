import { BrightcoveVideoEmbedComponent } from './brightcove-video-embed/brightcove-video-embed.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapitalizePipe, TrimPipe, SafeHtmlPipe, DateFormatPipe, CurrencyFormatPipe, RemoveSpacesPipe, StripHtmlPipe, DashPipe } from './pipes';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { LoginComponent } from './login/login.component';
import { LoginService } from './services/login.service';
import { HelperService } from './services/helper.service';
import { SableProgressStepsService } from './services/sable-progress-steps.service';
import { ProductListsComponent } from './product-lists/product-lists.component';
import { CategoryListsComponent } from './category-lists/category-lists.component';
import { FaqComponent } from './faq/faq.component';
import { RouterModule } from '@angular/router';
import { NewPasswordComponent } from './new-password/new-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { RegistrationModalComponent } from './registration-modal/registration-modal.component';
import { RegistrationCoreComponent } from './registration-core/registration-core.component';
import { AonCtaComponent } from './aon-cta/aon-cta.component';
import { SharedSableFlowComponent } from './sable/shared-sable-flow/shared-sable-flow.component';
import { SableFlowModule, SableFlowDataService, SableFlowEventBusService, SableFlowContentShareService } from '@aon/sable-flow';
import { AppFlowDataService } from './sable/app-flow-data.service';
import { AppFormDataService } from './sable/app-form-data.service';
import { AppViewDataService } from './sable/app-view-data.service';
import { SableFormDataService, SableFormsModule } from '@aon/sable-forms';
import { SableFormsFlowAdapterModule } from '@aon/sable-forms-flow-adapter';
import { SableDataViewFlowAdapterModule } from '@aon/sable-dataview-flow-adapter';
import { SableDataViewComponentFactory, SableDataViewDataService, SableDataViewModule } from '@aon/sable-dataview';
import { AppDataViewComponentFactory } from './sable/app-view.factory';
import { SableDataViewInputLabelComponent } from './sable/sable-view/sable-dataview-input-label/sable-dataview-input-label.component';
import { MarketingPreferenceComponent } from './marketing-preference/marketing-preference.component';
import { AppFlowContainerDirective } from './sable/app-flow-container.directive';
import { SableDataViewAccordionComponent } from './sable/sable-view/sable-dataview-accordion/sable-dataview-accordion.component';
import { ClientCodeNotFoundComponent } from './client-code-not-found/client-code-not-found.component';
import { SableEntityIntersectFlowDataService } from '@aon/sable-entity-intersect-flow-adapter';
import { KenticoSableEntityIntersectFlowDataService } from './sable/app-sable-entity-intersect-flow.service';
import { AonMessageBoxComponent } from './aon-message-box/aon-message-box.component';
import { AonSpinComponent } from './aon-spin/aon-spin.component';
import { SableDataviewTableComponent } from './sable/sable-view/sable-dataview-table/sable-dataview-table.component';
import { ContentComponent } from '../modules/quote/content/content.component';
import { SableDataviewInputLabelValueOnlyComponent } from './sable/sable-view/sable-dataview-input-label-value-only/sable-dataview-input-label-value-only.component';
import { VbGlobalErrorPageComponent } from './vb-global-error-page/vb-global-error-page.component';
import { CcValidatorDirective } from './directive/cc-validator.directive';

import { NotonFilePipe } from './pipes/nof.pipe';
import { ExcludeMeldedPipe } from './pipes/excludeMelded.pipe';
import { ParticipantDocumentsComponent } from './participant-documents/participant-documents.component';
import { AddEditDependentComponent } from './add-edit-dependent/add-edit-dependent.component';
import { FileListComponent } from './file-list/file-list.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { FlatquestionResultComponent } from '../modules/quote/flatquestion-result/flatquestion-result.component';
import { PurchaseRightSectionComponent } from './purchase-right-section/purchase-right-section.component';
import { AddEditBeneficiaryComponent } from './beneficiary/add-edit-beneficiary/add-edit-beneficiary.component';
import { ProductAllocationDetailsComponent } from './product-allocation-details/product-allocation-details.component';
import { BasicContentCardComponent } from './content-card/basic-content-card/basic-content-card.component';
import { PayFrequencyComponent } from './pay-frequency/pay-frequency.component';
import { TextTransformPipe } from './pipes/textTransform.pipe';
import { ParameterizedTextComponent } from './parameterized-text/parameterized-text.component';
import { BenefitService } from '../modules/benefit/benefit.service';
import { SableDataviewCardComponent } from './sable/sable-view/sable-dataview-card/sable-dataview-card.component';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { AonAngularCommonModule } from '@aon/aon-angular-common';
import { AonBannersComponent } from './aon-banners/aon-banners.component';
import { TotalCostComponent } from './total-cost/total-cost.component';
import { CoverageDetailsComponent } from './coverage-details/coverage-details.component';
import { ToggleOptionComponent } from './toggle-option/toggle-option.component';
import { FirstPurchaseViewSummaryComponent } from './first-purchase-view-summary/first-purchase-view-summary.component';
import { BenefitDetailsComponent } from './benefit-details/benefit-details.component';
import { DependantsInfoComponent } from './dependants-info/dependants-info.component';
import { SableDataViewOrderedListComponent } from './sable/sable-view/sable-dataview-orderedlist/sable-dataview-orderedlist.component';
import { SableDataViewUnorderedListComponent } from './sable/sable-view/sable-dataview-unorderedList/sable-dataview-unorderedList.component';
import { BenefitCtaComponent } from './benefit-cta/benefit-cta.component';
import { BenefitDetailsAvailableComponent } from './benefit-details-available/benefit-details-available.component';
import { ProductBannerMessagesComponent } from './product-banner-messages/product-banner-messages.component';
import { PlanDependentInfoComponent } from './plan-dependent-info/plan-dependent-info.component';
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { CoverageDisplayInfoComponent } from './coverage-display-info/coverage-display-info.component';
import { ExpressLoginComponent } from './express-login/express-login.component';
import { StripCssHtmlPipe } from './pipes/stripCssHtml.pipe';
import { SableDataviewChartComponent } from './sable/sable-view/sable-dataview-chart/sable-dataview-chart.component';
import { T3ModalDirective } from './directive/t3-modal.directive';
import { TrsWidgetComponent } from './trs-widget/trs-widget.component';
import { ValidateErrorContentComponent } from './validate-error-content/validate-error-content.component';
import { CustomAccordionPipe } from './pipes/customAccordion.pipe';
import { CustomAccordionDirective } from './directive/custom-accordion.directive';
import { ACHAccountNumberValidatorDirective } from './directive/ach-account-number-validator.directive';
import { ACHBankCodeValidatorDirective } from './directive/ach-bank-code-validator.directive';
import { ZipUSValidatorDirective } from './directive/zip-us-validator.directive';
import { AonFilterPipe } from './pipes/aonFilter.pipe';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BeneficiaryTypeRightComponent } from './beneficiary/beneficiary-type-rights/beneficiary-type-rights.component';
import { ExpressRegistrationComponent } from './express-registration/express-registration.component';
import { SpyScrollViewDirective } from './directive/spy-scroll-view.directive';
import { MfaAuthenticationComponent } from './mfa-authentication/mfa-authentication.component';
import { AonTaxModellerTablesModalComponent } from './aon-tax-modeller-tables-modal/aon-tax-modeller-tables-modal.component';
import { ParticipantProvinceDistrictComponent } from './participant-province-district/participant-province-district.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { BenefitDetailsInformationComponent } from './benefit-details-information/benefit-details-information.component';
import { BenefitDetailsInformationModalComponent } from './benefit-details-information-modal/benefit-details-information-modal.component';
import { MfaAuthenticationModalComponent } from './mfa-authentication-modal/mfa-authentication-modal.component';
import { DeselectBenefitConfirmComponent } from './deselect-benefit-confirm/deselect-benefit-confirm.component';
import { AonSkipLinksComponent } from './aon-skip-links/aon-skip-links.component';
import { AdditionalEventsSignpostWidgetComponent } from './additional-events-signpost-widget/additional-events-signpost-widget.component';
import { EventWidgetTemplateComponent } from './event-widget-template/event-widget-template.component';
import { EventBroadcastBarComponent } from './event-broadcast-bar/event-broadcast-bar.component';
import { WrapperFormDecoratorComponent } from './wrapper-form-decorator/wrapper-form-decorator.component';
import { PaymentBillingAddressComponent } from './payment-billing-address/payment-billing-address.component';
import { WatchDomTreeDirective } from './directive/watch-dom-tree.directive';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { EnrolmentSummaryControlIconComponent } from './enrolment-summary-control-icon/enrolment-summary-control-icon.component';
import { DeferCoverageComponent } from './defer-coverage/defer-coverage.component';
import { ScriptInjectorDirective } from './directive/script-injector.directive';
import { AlertBannerCloseButtonFocusDirective } from './directive/alert-banner-close-button-focus.directive'
import { UploadsModule } from '@progress/kendo-angular-upload';
import { QrcodePopupComponent } from './aon-cta/qrcode-popup/qrcode-popup.component';
import { ContentPopupComponent } from './aon-cta/content-popup/content-popup.component';
import { BarcodesModule } from "@progress/kendo-angular-barcodes";
import { CreateEventListenerDirective } from './directive/create-event-listener.directive';
import { ChunkArrayPipe } from './pipes/chunk-array.pipe';
import { BenefitDetailsMiniComponent } from './benefit-details/benefit-details-mini/benefit-details-mini.component';
import { SharedArticleContentComponent } from './cms-content/shared-article-content/shared-article-content.component';
import { SharedVideoContentComponent } from './cms-content/shared-video-content/shared-video-content.component';
import { SharedFeaturesContentComponent } from './cms-content/shared-features-content/shared-features-content.component';
import { SharedGatewayContentComponent } from './cms-content/shared-gateway-content/shared-gateway-content.component';
import { SharedSuggestedBenefitsContentComponent } from './cms-content/shared-suggested-benefits-content/shared-suggested-benefits-content.component';
import { SharedArticleWidgetContentComponent } from './cms-content/shared-article-widget-content/shared-article-widget-content.component';
import { SableDataViewComponent } from './sable-data-view/sable-data-view.component';
import { SharedHeadlineContentComponent } from './cms-content/shared-headline-content/shared-headline-content.component';
import { SharedCoverageContentComponent } from './cms-content/shared-coverage-content/shared-coverage-content.component';
import { EoiInformationComponent } from './eoi-information/eoi-information.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FunctionExecutorPipe } from './pipes/function-executor.pipe';
import { AddEditFundAllocationComponent } from './fund-allocation/add-edit-fund-allocation/add-edit-fund-allocation.component';
import { FundAllocationDisplayComponent } from './fund-allocation/fund-allocation-display/fund-allocation-display.component';
import { KendoPdfExportDirective } from './directive/kendo-pdf-export.directive';
import { EditableMbrSiteToolbarComponent } from './editable-mbr-site-toolbar/editable-mbr-site-toolbar.component';
import { CommonProgressComponent } from './common-progress/common-progress.component';
import { SelfServiceMediaSelectionComponent } from './self-service-templates/self-service-media-selection/self-service-media-selection.component';
import { DateTimeFormatPipe } from './pipes/dateTimeFormat.pipe';
import { SelfServiceMediaUploadComponent } from './self-service-templates/self-service-media-upload/self-service-media-upload.component';
import { AonSelfServiceCtaWrapperComponent } from './aon-cta/aon-self-service-cta-wrapper/aon-self-service-cta-wrapper.component';
import { SelfServiceManageCtaTemplateModalComponent } from './self-service-templates/self-service-manage-cta-template-modal/self-service-manage-cta-template-modal.component';
import { SelfServiceDeleteTemplateModalComponent } from './self-service-templates/self-service-delete-template-modal/self-service-delete-template-modal.component';
import { SelfServiceManageContentitemTemplateModalComponent } from './self-service-templates/self-service-manage-contentitem-template-modal/self-service-manage-contentitem-template-modal.component';
import { SelfServiceManagePopulationTemplateModalComponent } from './self-service-templates/self-service-manage-population-template-modal/self-service-manage-population-template-modal.component';


@NgModule({
  declarations: [
    BenefitDetailsComponent,
    DependantsInfoComponent,
    CapitalizePipe,
    TrimPipe,
    SafeHtmlPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    CurrencyFormatPipe,
    RemoveSpacesPipe,
    StripHtmlPipe,
    NotonFilePipe,
    ExcludeMeldedPipe,
    TextTransformPipe,
    DashPipe,
    DynamicInputComponent,
    LoginModalComponent,
    LoginComponent,
    ProductListsComponent,
    CategoryListsComponent,
    FaqComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
    RegistrationCoreComponent,
    RegistrationModalComponent,
    AonCtaComponent,
    SharedSableFlowComponent,
    SableDataViewInputLabelComponent,
    MarketingPreferenceComponent,
    SableDataViewAccordionComponent,
    AppFlowContainerDirective,
    ClientCodeNotFoundComponent,
    AonMessageBoxComponent,
    AonSpinComponent,
    SableDataviewTableComponent,
    ContentComponent,
    SableDataviewInputLabelValueOnlyComponent,
    SableDataviewCardComponent,
    VbGlobalErrorPageComponent,
    CcValidatorDirective,
    ParticipantDocumentsComponent,
    AddEditDependentComponent,
    FileListComponent,
    PlanDetailsComponent,
    FlatquestionResultComponent,
    PurchaseRightSectionComponent,
    ProductAllocationDetailsComponent,
    BasicContentCardComponent,
    PayFrequencyComponent,
    ParameterizedTextComponent,
    AonBannersComponent,
    TotalCostComponent,
    CoverageDetailsComponent,
    ToggleOptionComponent,
    FirstPurchaseViewSummaryComponent,
    SableDataViewOrderedListComponent,
    SableDataViewUnorderedListComponent,
    BenefitCtaComponent,
    BenefitDetailsAvailableComponent,
    BenefitDetailsInformationComponent,
    BenefitDetailsInformationModalComponent,
    ProductBannerMessagesComponent,
    PlanDependentInfoComponent,
    BarChartComponent,
    CoverageDisplayInfoComponent,
    ExpressLoginComponent,
    StripCssHtmlPipe,
    SableDataviewChartComponent,
    T3ModalDirective,
    TrsWidgetComponent,
    ValidateErrorContentComponent,
    CreatePasswordComponent,
    CustomAccordionPipe,
    CustomAccordionDirective,
    ACHAccountNumberValidatorDirective,
    ACHBankCodeValidatorDirective,
    ZipUSValidatorDirective,
    AonFilterPipe,
    BeneficiaryComponent,
    BeneficiaryTypeRightComponent,
    AddEditBeneficiaryComponent,
    ExpressRegistrationComponent,
    SpyScrollViewDirective,
    MfaAuthenticationComponent,
    AonTaxModellerTablesModalComponent,
    ParticipantProvinceDistrictComponent,
    VerifyOtpComponent,
    MfaAuthenticationModalComponent,
    DeselectBenefitConfirmComponent,
    AonSkipLinksComponent,
    BrightcoveVideoEmbedComponent,
    AdditionalEventsSignpostWidgetComponent,
    EventWidgetTemplateComponent,
    EventBroadcastBarComponent,
    WrapperFormDecoratorComponent,
    PaymentBillingAddressComponent,
    WatchDomTreeDirective,
    EmailVerificationComponent,
    EnrolmentSummaryControlIconComponent,
    DeferCoverageComponent,
    ScriptInjectorDirective,
    AlertBannerCloseButtonFocusDirective,
    QrcodePopupComponent,
    ContentPopupComponent,
    CreateEventListenerDirective,
    ChunkArrayPipe,
    BenefitDetailsMiniComponent,
    SharedArticleContentComponent,
    SharedVideoContentComponent,
    SharedFeaturesContentComponent,
    SharedGatewayContentComponent,
    SharedSuggestedBenefitsContentComponent,
    SharedArticleWidgetContentComponent,
    SableDataViewComponent,
    SharedHeadlineContentComponent,
    SharedCoverageContentComponent,
    EoiInformationComponent,
    PageNotFoundComponent,
    FunctionExecutorPipe,
    FundAllocationDisplayComponent,
    AddEditFundAllocationComponent,
    KendoPdfExportDirective,
    EditableMbrSiteToolbarComponent,
    SelfServiceMediaSelectionComponent,
    CommonProgressComponent,
    SelfServiceMediaUploadComponent,
    AonSelfServiceCtaWrapperComponent,
    SelfServiceManageCtaTemplateModalComponent,
    SelfServiceDeleteTemplateModalComponent,
    SelfServiceManageContentitemTemplateModalComponent,
    SelfServiceManagePopulationTemplateModalComponent
  ],
  exports: [
    BenefitDetailsComponent,
    DependantsInfoComponent,
    CapitalizePipe,
    TrimPipe,
    SafeHtmlPipe,
    DateFormatPipe,
    DateTimeFormatPipe,
    CurrencyFormatPipe,
    RemoveSpacesPipe,
    StripHtmlPipe,
    NotonFilePipe,
    ExcludeMeldedPipe,
    TextTransformPipe,
    DashPipe,
    ScriptInjectorDirective,
    DynamicInputComponent,
    LoginModalComponent,
    LoginComponent,
    ProductListsComponent,
    CategoryListsComponent,
    FaqComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
    CreatePasswordComponent,
    RegistrationCoreComponent,
    RegistrationModalComponent,
    AonCtaComponent,
    SharedSableFlowComponent,
    MarketingPreferenceComponent,
    AonMessageBoxComponent,
    AonSpinComponent,
    ContentComponent,
    CcValidatorDirective,
    ParticipantDocumentsComponent,
    PaymentBillingAddressComponent,
    AddEditDependentComponent,
    FileListComponent,
    PlanDetailsComponent,
    PurchaseRightSectionComponent,
    AddEditBeneficiaryComponent,
    ProductAllocationDetailsComponent,
    BasicContentCardComponent,
    ParameterizedTextComponent,
    SableDataViewModule,
    SableDataViewAccordionComponent,
    AonBannersComponent,
    TotalCostComponent,
    CoverageDetailsComponent,
    ToggleOptionComponent,
    FirstPurchaseViewSummaryComponent,
    SableDataViewOrderedListComponent,
    SableDataViewUnorderedListComponent,
    BenefitCtaComponent,
    BenefitDetailsAvailableComponent,
    BenefitDetailsInformationComponent,
    BenefitDetailsInformationModalComponent,
    ProductBannerMessagesComponent,
    PlanDependentInfoComponent,
    BarChartComponent,
    CoverageDisplayInfoComponent,
    StripCssHtmlPipe,
    T3ModalDirective,
    TrsWidgetComponent,
    CustomAccordionPipe,
    CustomAccordionDirective,
    ACHAccountNumberValidatorDirective,
    ACHBankCodeValidatorDirective,
    ZipUSValidatorDirective,
    AonFilterPipe,
    BeneficiaryComponent,
    BeneficiaryTypeRightComponent,
    ExpressRegistrationComponent,
    SpyScrollViewDirective,
    MfaAuthenticationComponent,
    DeselectBenefitConfirmComponent,
    AonSkipLinksComponent,
    BrightcoveVideoEmbedComponent,
    AdditionalEventsSignpostWidgetComponent,
    EventWidgetTemplateComponent,
    EventBroadcastBarComponent,
    WrapperFormDecoratorComponent,
    WatchDomTreeDirective,
    EnrolmentSummaryControlIconComponent,
    DeferCoverageComponent,
    AlertBannerCloseButtonFocusDirective,
    CreateEventListenerDirective,
    ChunkArrayPipe,
    BenefitDetailsMiniComponent,
    SharedArticleContentComponent,
    SharedVideoContentComponent,
    SharedFeaturesContentComponent,
    SharedGatewayContentComponent,
    SharedSuggestedBenefitsContentComponent,
    SharedArticleWidgetContentComponent,
    SableDataViewComponent,
    SharedHeadlineContentComponent,
    SharedCoverageContentComponent,
    EoiInformationComponent,
    PageNotFoundComponent,
    FunctionExecutorPipe,
    FundAllocationDisplayComponent,
    AddEditFundAllocationComponent,
    KendoPdfExportDirective,
    EditableMbrSiteToolbarComponent,
    CommonProgressComponent,
    AonSelfServiceCtaWrapperComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SableFlowModule,
    SableFormsModule,
    SableFormsFlowAdapterModule,
    SableDataViewFlowAdapterModule,
    NgbModule,
    SableDataViewModule,
    DateInputsModule,
    AonAngularCommonModule,
    ChartsModule,
    UploadsModule,
    BarcodesModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LoginService,
        HelperService,
        BenefitService,
        SableFlowEventBusService,
        SableFlowContentShareService,
        SableProgressStepsService,
        TextTransformPipe,
        AppFormDataService,
        { provide: SableEntityIntersectFlowDataService, useClass: KenticoSableEntityIntersectFlowDataService },
        { provide: SableFlowDataService, useClass: AppFlowDataService },
        { provide: SableFormDataService, useClass: AppFormDataService },
        { provide: SableDataViewDataService, useClass: AppViewDataService },
        { provide: SableDataViewComponentFactory, useClass: AppDataViewComponentFactory },
        NgbActiveModal
      ]
    };
  }
}
