import { Component, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { HelperService } from '../services/helper.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-external-link-popup',
  templateUrl: './external-link-popup.component.html'
})
export class ExternalLinkPopupComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateRef) externalwarning: TemplateRef<unknown> | undefined;
  @Input() externalLinkURL: Subject<boolean>;
  uiConfig: UIConfigurationType;
  count: number;
  targetURL : string;
  modalReference: NgbModalRef;

  constructor(
    private readonly modalService: NgbModal,
    public helperService: HelperService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Common.RedirectInfoTitle',
      'Aon.Common.RedirectInfoText'
    ]);
  }

  ngOnInit(): void {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = this.helperService.getBaseUrl();
    
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
  }

  public openmodal(targetURL:string) {
    this.targetURL = targetURL;
    this.open(this.externalwarning);
    setTimeout(() => {
      this.modalReference.close();
      window.open(targetURL, "_blank");
    }, 3000);
    
  }

  open(content: TemplateRef<any>) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    ngbModalOptions.container = '#app-modal-anchor';
    this.modalReference = this.modalService.open(content, ngbModalOptions);
    return this.modalReference;
  }
  
  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }


}
