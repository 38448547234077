<div id="benefit-info-modal" [attr.role]="removeRoleDialog ? null : 'alertdialog'" [attr.aria-label]="benefitName"
  (keydown.esc)="handlekeyDownEsc($event)">
  <div class="modal-only">
    <div class="modal-nav-bar">
      <div class="page-content-sleeve">
        <span tabindex="0" class="focus-visibility" (click)="onClose()" (keydown.enter)="onClose()">
          <i class="m-r-8 fa-light fa-chevron-left"></i>
          <a role="button" class="close" [innerHTML]="resourceStrings['Aon.BenefitDetails.GoBack']"
            [attr.aria-label]="resourceStrings['Aon.BenefitDetails.GoBack']">
          </a>
        </span>
      </div>
    </div>
  </div>
  @if (showJumpToSection) {
    <div class="col-12 d-block d-sm-none sticky-dropdown container m-t-20">
      <div class="m-b-32">
        <select class="dropdown" #selectListTopic (change)="setActive(selectListTopic.value)">
          <optgroup label="{{resourceStrings['Aon.Help.JumpToSection']}}">
            <ng-container>
              @for (content of productdetails.items.contentItemsList; track content; let sectionIndex = $index) {
                <option [value]="sectionIndex"
                  [attr.aria-label]="content.contentItemTitle + ' ' + resourceStrings['Aon.Common.Selected']"
                  [selected]="sectionIndex === active">
                  {{content.contentItemTitle}}
                </option>
              }
              @if (faqs?.length) {
                <option [value]="productdetails.items.contentItemsList.length"
                  [attr.aria-label]="faqTitle + ' ' + resourceStrings['Aon.Common.Selected']"
                  [selected]="productdetails.items.contentItemsList.length === active">
                  {{faqTitle}}
                </option>
              }
            </ng-container>
          </optgroup>
        </select>
      </div>
    </div>
  }
  <!-- Accessibility Loading and Loaded screen announcement -->
  <aon-spin [noDarkSpin]="true"></aon-spin>
  @if (productdetails) {
    <div class="page T3" id="product-details" [ngClass]="currentProduct?.theme">
      <div class="pd-content-sleeve-outer">
        <div class="page-content-sleeve benefit-information">
          @if (productdetails.items) {
            <div class="row benefit-information">
              <div class="col-md-3 d-none d-sm-block" id="nav-target">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-pills flex-column sidebar">
                  @for (content of productdetails.items.contentItemsList; track content; let sectionIndex = $index; let last = $last) {
                    <li [class.m-t-4]="sectionIndex === 0" id="content-title{{sectionIndex}}" class="nav-item"
                      [ngbNavItem]="sectionIndex" [domId]="sectionIndex?.toString()">
                      <a ngbNavLink class="nav-link product-details-nav-link info-modal-section-{{sectionIndex}}" role="tab">
                        <span class="theme-color">
                          {{ content.contentItemTitle }}
                        </span>
                      </a>
                      <ng-template ngbNavContent>
                        <section class="m-b-30" data-spy="1" id="{{ getSectionId(content.contentItemID) }}" SpyScrollView>
                          <div id="{{sectionIndex+'-panel'}}">
                            @if (sectionIndex == 0) {
                              <div>
                                <h2 class="theme-color h3" id="title-{{ content.contentItemID }}">{{ content.contentItemTitle }}
                                </h2>
                              </div>
                            }
                            @if (sectionIndex != 0 && !last) {
                              <div>
                                <h2 class="theme-color h3" id="title-{{ content.contentItemID}}">{{ content.contentItemTitle }}
                                </h2>
                              </div>
                            }
                            @if (last && sectionIndex != 0) {
                              <div>
                                <div class="row justify-content-between">
                                  <div class="col-md-12 col-10">
                                    <h2 class="theme-color h3" id="title-{{ content.contentItemID}}">
                                    {{content.contentItemTitle}}</h2>
                                  </div>
                                </div>
                              </div>
                            }
                            @if (content != null && content.contentItemBody != null) {
                              <div class="m-l-4"
                              [innerHTML]="content.contentItemBody | safeHtml"></div>
                            }
                            <div class="divider m-t-30"></div>
                          </div>
                        </section>
                      </ng-template>
                    </li>
                  }
                  @if (faqs?.length) {
                    <li id="faq-title" class="nav-item"
                      [ngbNavItem]="productdetails.items.contentItemsList.length"
                      [domId]="productdetails.items.contentItemsList.length?.toString()">
                      <a ngbNavLink
                        class="nav-link product-details-nav-link info-modal-section-{{productdetails.items.contentItemsList.length}}"
                        role="tab">
                        <span class="theme-color">
                          {{faqTitle}}
                        </span>
                      </a>
                      <ng-template ngbNavContent>
                        @if (faqs?.length) {
                          <section data-spy="1" class="m-b-30" id="{{ getSectionId('FAQ') }}">
                            <div id="{{productdetails.items.contentItemsList.length+'-panel'}}">
                              <div class="row justify-content-between">
                                <div class="col-md-12">
                                  <div class="theme-color" id="title-FAQ">
                                    <div class="row">
                                      <div class="col-md-8 col-12 m-auto">
                                        <app-faq [faqTitle]="faqTitle" [faqs]="faqs" [resourcestrings]="resourceStrings">
                                        </app-faq>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        }
                      </ng-template>
                    </li>
                  }
                </ul>
              </div>
              <div class="col-md-9 col-12" id="product-detail-content-sleeve">
                <div class="modal-header">
                  <h1 class="modal-title" aria-describedby="benefitName" aria-labelledby="benefitName"
                  [innerHTML]="benefitName | striphtml"></h1>
                </div>
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>