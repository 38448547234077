import { Component, Injector } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TBSBaseComponent } from '../../models/tbsBaseComponent';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './self-service-delete-template-modal.component.html'
})
export class SelfServiceDeleteTemplateModalComponent extends TBSBaseComponent {
  public headerLabel = "";
  public description = "";
  public deleteConfirmationReceived: Subject<boolean> = new Subject();
  onSubmitErrorMessage: string = "";
  constructor(injector: Injector,
    public readonly activeModal: NgbActiveModal
  ) {
    super(injector);
    this.addResourceStringKeys([]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
  }

  proceedToDelete(): void {
    this.onSubmitErrorMessage = "";
    this.deleteConfirmationReceived.next(true);
  }
}
