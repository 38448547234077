<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{headerLabel}}</h4>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="activeModal.dismiss('ABORT')">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
</div>
<div class="modal-body">
    <div class="basic-card edit-state">
        <div class="row">
            <div class="col-12">
                <p>{{description}}</p>
            </div>
        </div>

    </div>
</div>
@if (onSubmitErrorMessage != '') {
<div class="alert contextual error m-t-20">
    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
    <span [innerHTML]="onSubmitErrorMessage | safeHtml"></span>
</div>
}
<div class="button-sleeve">
    <button type="button" class="prominent m-t-32" (click)="proceedToDelete()"
        [innerHTML]="resourceStrings['Aon.Common.OK'] | safeHtml"></button>
    <button type="button" class="ghost m-t-32" (click)="activeModal.dismiss('ABORT')"
        [innerHTML]="resourceStrings['Aon.Common.Cancel'] | safeHtml"></button>
</div>