import { Component, ElementRef, Injector, Input, OnInit } from '@angular/core';
import { SableDataViewStateService } from '@aon/sable-dataview';
import { takeUntil } from 'rxjs';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { HelperService } from "src/app/shared/services/helper.service";
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Component({
  selector: 'lib-dataview-input-label',
  templateUrl: './sable-dataview-input-label.component.html'
})
export class SableDataViewInputLabelComponent extends TBSBaseComponent implements OnInit {
  @Input() data: any;
  options: any;
  pdfUnicodeCharacterOverrideClasses: string = "";
  public htmlElement: HTMLElement;

  constructor(private readonly injector: Injector,
    private readonly viewState: SableDataViewStateService,
    public helperService: HelperService,
    public globalObjService: GlobalObjectsService,
    readonly elementRef: ElementRef) {
    super(injector);
    this.htmlElement = elementRef.nativeElement;
    this.addResourceStringKeys(['Aon.Arialabel.Info']);
  }

  ngOnInit() {
    this.options = this.data;
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        res => { this.getResouceStringsAsObject(res); }
      );
    this.pdfUnicodeCharacterOverrideClasses = this.helperService.getPdfUnicodeCharacterOverrideClasses(this.helperService.labelValueText(this.data, this.htmlElement, this.options));
  }
  ngOnDestroy() {

  }

  openTooltip(popover: NgbPopover): void {
    popover.isOpen() ? popover.close() : popover.open();
  }

}
