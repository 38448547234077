import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { takeUntil } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';
import { CommonService } from '../../../core/services';


@Component({
  selector: 'app-claim-save-alert',
  templateUrl: './claim-save-alert.component.html'
})
export class ClaimSaveAlertComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  uiConfig: UIConfigurationType;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  removePurchaseErrorMessage: boolean = false;
  constructor(
    public modalService: NgbActiveModal,
    public activeModal: NgbActiveModal,
    private readonly scroller: ViewportScroller,
    private readonly commonService: CommonService,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Claim.Alert.Description'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }
  close() {
    this.modalService.close(false);
    this.scrollToTop();
  }
  save() {
    this.commonService.ClaimLanguageChange.next(true);
  }


  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private scrollToTop() {
    this.scroller.scrollToPosition([0, 0]); //Scroll to top to show error message
  }

}
