import { Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HelperService } from 'src/app/shared/services/helper.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { Router } from '@angular/router';
import { GlobalObjectsService } from '../services/global-objects.service';
import { CommonService } from 'src/app/core/services';
import { Subscriber, take, takeUntil } from 'rxjs';
import { ContentItemModel } from '../models/contentItem.model';
import { EmployeeLifeStyle } from '../models/employeeEvent.model';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { QouteDeletePopupComponent } from 'src/app/modules/quote/qoute-delete-popup/qoute-delete-popup.component';
import { CheckOutDocumentsComponent } from 'src/app/modules/benefit/check-out-documents/check-out-documents.component';
import { DeselectBenefitConfirmComponent } from '../deselect-benefit-confirm/deselect-benefit-confirm.component';
import { QuoteService } from 'src/app/modules/quote/quote.service';
import { BenefitService } from 'src/app/modules/benefit/benefit.service';
import { CTACustomFunctionService } from 'src/app/shared/services/ctacustom-function.service';
import { DependantNameType, TaxAdjustmentType, TaxType } from '../models/benefitReview.model';
import { EmployeeRelationshipTypeNames, SessionStorageKey } from '../models/constants';

@Component({
  selector: 'app-benefit-details',
  templateUrl: './benefit-details.component.html'
})
export class BenefitDetailsComponent extends TBSBaseComponent implements OnInit, OnChanges {
  @Input() showBuildPolicyButton: boolean;
  @Input() showDeleteButton: boolean;
  @Input() fromEndPurchase: boolean;
  @Input() isBenefitMatrixCard: boolean = false;
  @Input() isSSOCoverageActivityCard: boolean = false;
  @Input() index: number;
  @Input() product: any;
  /**
   * Controls the Funding section to show seperately and rename the existing total cost label
   */
  @Input() displayERCostAsFunding = false;
  @Input() isPerPay: boolean = true;
  @Input() lifestyleReview = false;
  @Input() isMultiInstance = false;
  @Input() fromBenefitDetails = false;
  @Input() showCustomerService: boolean = true;
  @Input() isProfileDisplay: boolean = false;
  @Input() hideAlsoAvailableSection: any;
  @Input() benefitsType: string = "";
  @Output() goSableFlow = new EventEmitter();
  @Output() callFlow = new EventEmitter();
  @Input() transformNegativeEmployeeCost = false;
  lifeStyleFlow: EmployeeLifeStyle;
  primarySecondaryBeneficiarieDetails: {};
  isDefaultCoverDeselection: boolean = false;
  showStandardBenefitCard: boolean = false;
  baseUrl: string;
  uiConfig: UIConfigurationType;
  AMOUNT_TYPE_PERCENT_SALARY = 1;
  AMOUNT_TYPE_CURRENCY = 2;
  AMOUNT_TYPE_VOUCHER = 3;
  AMOUNT_TYPE_DAYS = 4;
  AMOUNT_TYPE_HOURS = 5;
  AMOUNT_TYPE_VOUCHERS = 7;
  AMOUNT_TYPE_CUSTOMTYPE = 8;
  Math: Math = Math;
  productThumbnail: string = "";
  productCategory: string = "";
  nodeAliasPath: string = "";
  customerService: ContentItemModel;
  currentProduct: any = null;
  formatOptions: any = { style: 'currency', currency: '', currencyDisplay: 'symbol' };
  cultureCode: string;
  tabRoleChecked = false;
  messages: string[];
  @Input() ssoBenefittag: boolean;
  participantsForEOIInformation: Array<{
    interim: DependantNameType,
    requested: DependantNameType
  }>
  taxes: {
    employee: TaxType[],
    employer: TaxType[]
  } = {
      employee: [],
      employer: []
    };
  private _totalPaymentSourceCost: {
    perPay: number,
    perAnnual: number
  };

  constructor(public helperService: HelperService,
    private readonly benefitService: BenefitService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly commonService: CommonService,
    private readonly elRef: ElementRef,
    private readonly injector: Injector,
    private readonly ctaCustomFn: CTACustomFunctionService,
    private readonly quoteService: QuoteService,) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.BenefitDetails.Title',
      'Aon.BenefitDetails.Description',
      'Aon.BenefitDetails.CardTitle',
      'Aon.BenefitDetails.PlanTitle',
      'Aon.BenefitDetails.PlanDetails',
      'Aon.BenefitDetails.Term',
      'Aon.BenefitDetails.Policy Number',
      'Aon.BenefitDetails.EmployerCost',
      'Aon.BenefitDetails.TotalCost',
      'Aon.BenefitDetails.CoverageDetails',
      'Aon.BenefitDetails.PendingCoverage',
      'Aon.BenefitDetails.CustomerSupport',
      'Aon.Beneficiary.PrimaryBeneficiary',
      'Aon.Beneficiary.ContingentBeneficiary',
      'Aon.Benefit.EmployeeCost',
      'Aon.Benefit.EmployerCost',
      'Benefit.ExpansionPanelTable.EOI.YourCost',
      'Benefit.ExpansionPanelTable.EOI.EmployerCost',
      'Benefit.ExpansionPanelTable.EOI.TotalCost',
      'Benefit.ExpansionPanelTable.EOI.ActiveBenefitsPendingInformation',
      'Aon.Benefit.TotalCost',
      'Aon.Benefits.InterimCoverage',
      'Aon.Benefits.RequestedCoverage',
      'Benefit.ExpansionPanelTable.EOI.CustomerService',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.Phone',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.Hours',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.Claims',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.Saturday',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.Sunday',
      'Benefit.ExpansionPanelTable.EOI.CustomerService.MondayToFriday',
      'Aon.Profile.Benefit.UpdateBenefit',
      'Aon.BenefitDetails.CardTitle',
      'Aon.BenefitMatrix.ViewBenefit',
      'Aon.BenefitMatrix.Update',
      'Aon.Tag.Pending',
      'Aon.Tag.NewBenefit',
      'Aon.Tag.Removed',
      'Aon.Tag.Updated',
      'Aon.Tooltip.PendingEOI',
      'Aon.BenefitMatrix.BuildAnother',
      'Aon.BenefitMatrix.Deselect',
      'Aon.ActiveBenefits.EditPolicyLabel',
      'Aon.BenefitCard.NoPoliciesToDisplayHeading',
      'Aon.BenefitCard.NoPoliciesToDisplay',
      'Aon.Arialabel.Info',
      'Aon.SSO.BenefitTag',
      'Aon.Benefit.Message.Delete',
      'Aon.Benefit.Message.New',
      'Aon.Benefit.Message.CostChange',
      'Aon.Benefit.Message.PlanChange',
      'Aon.Benefit.Message.DependantChange',
      'Aon.Benefit.Message.Header',
      'Aon.Benefit.FundingRelease',
      'Aon.Benefit.FundingForfeit',
      'Aon.BenefitDetails.OutstandingCost',
      'Aon.BenefitDetails.YourCost',
      'Aon.Tag.Credit',
      'Aon.PaymentSource.YourCost',
      'Aon.Benefit.EmployeeTaxes',
      'Aon.Benefit.EmployerTaxes',
      'Aon.ERTaxSummary.Popover.Title',
      'Aon.FundedTaxSummary.Popover.Title',
      'Aon.EETaxSummary.Popover.Title',
      'Aon.BenefitDetails.TaxDetails',
      'Aon.BenefitDetails.BenefitCost',
      'Aon.BenefitDetails.Funding',
      'Aon.BenefitDetails.FundingTaxes',
      'Aon.PaymentSource.YourCost.Adjusted',
      'Aon.Benefit.YourCost.Adjusted',
      'Aon.Benefit.EmployerCost.Adjusted',
      'Aon.Benefit.EmployeeTaxes.Adjusted',
      'Aon.Benefit.EmployerTaxes.Adjusted',
      'Aon.Beneficiary.NominatedTrustee',
      'Aon.Beneficiary.Irrevocable'
    ]);

  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = this.helperService.getBaseUrl();
    this.cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    if (this.benefitService.activeEvents?.value) {
      this.lifeStyleFlow = this.benefitService.activeEvents.value.lifeStyleInfo;
    }
    else {
      this.lifeStyleFlow = JSON.parse(sessionStorage.getItem(SessionStorageKey.ActiveEvent))?.lifeStyleInfo;
    }
    this.loadIcons();
    this.getProductInfo();
    if (this.benefitsType !== 'newBenefit') {
      this.loadCustomerService();
    }
    this.product.eoiInformation = this.product?.eOIInformation ? this.product?.eOIInformation : this.product.eoiInformation;
    /**
     * Re-use the EBT Labels for the master cards
     */
    if (this.product?.employeeBenefitTypes?.length) {
      this.product.employeeCostLabel = this.product.employeeBenefitTypes[0].employeeCostLabel;
      this.product.employerCostLabel = this.product.employeeBenefitTypes[0].employerCostLabel;
      this.product.totalCostLabel = this.product.employeeBenefitTypes[0].totalCostLabel;
    }
    this.isDefaultCoverDeselection = (this.product.isNoCoverage && (this.product?.userChange?.isDeleted || this.product?.defaultedChange?.isDeleted));
    this.showStandardBenefitCard = (!this.isBenefitMatrixCard || ((this.isBenefitMatrixCard && !this.product.isNoCoverage) || (this.isBenefitMatrixCard && this.product.isNoCoverage && this.product.eoiInformation)) || this.isDefaultCoverDeselection);
    this.employeeCostInfo();
    if (this.currentProduct?.whosCoveredMode != 3) {
      this.participantsForEOIInformation = this.getParticipantsForEOIInformation(this.product);
    }

    this._totalPaymentSourceCost = {
      perPay: 0,
      perAnnual: 0
    };
    this.product?.paymentSources?.forEach(x => {
      this._totalPaymentSourceCost.perPay += x.value_PayPeriod;
      this._totalPaymentSourceCost.perAnnual += x.value_Annual;
    })
  }


  goToSableFlow(product: any, emitCallFlow = false): any {
    const anytimeSableFlowId = this.quoteService.getAnyTimeSableFlowId(product);
    let lifeStyleSableFlowId = null;

    if (this.isProfileDisplay && anytimeSableFlowId) {
      this.quoteService.triggerAnyTimeFlow(product.line_RecordID, anytimeSableFlowId);
      return;
    }

    if (this.lifeStyleFlow && !anytimeSableFlowId && this.isProfileDisplay) {
      this.triggerLifeStyleFlow(this.lifeStyleFlow.sableFlow_RecordID, {});
      return;
    }

    if (product && !product.anytimeEntityInformationType && !anytimeSableFlowId) {
      lifeStyleSableFlowId = product.sableFlow_RecordID;
    }

    if (product && emitCallFlow) {
      const line_RecordID = product.line_RecordID;
      const sableFlow_RecordID = product.sableFlow_RecordID;
      const employeeEvent_RecordID = product?.employeeEvent_RecordID;
      this.emitCallFlow({ line_RecordID, sableFlow_RecordID, employeeEvent_RecordID });
      return;
    }

    this.emitGoSableFlow({
      anytimeSableFlowId: anytimeSableFlowId,
      lifeStyleSableFlowId: lifeStyleSableFlowId,
      line_RecordID: product.line_RecordID,
      employeeBenefitID: product.employeeBenefitID,
      sableFlow_RecordID: product.sableFlow_RecordID,
      sableInstance_RecordID: product.entityInformationType ? product.entityInformationType.sableInstance_RecordID : product.sableInstance_RecordID
    });
  }

  public emitGoSableFlow(data): void {
    this.goSableFlow.emit(data);
  }

  private emitCallFlow(data): void {
    this.callFlow.emit(data);
  }

  onClickDelete(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    ngbModalOptions.container = '#app-modal-anchor';
    const modalRef = this.modalService.open(QouteDeletePopupComponent, ngbModalOptions);
    modalRef.componentInstance.EmployeeBenefitID = data.employeeBenefitID
    let employeeSelf = data.coveredDependants != null && data.coveredDependants.filter(depdnt => {
      return depdnt.lookupRelationshipType_RecordID == EmployeeRelationshipTypeNames.Employee;
    }).length > 0
    modalRef.componentInstance.triggerDeselectionWarning = employeeSelf && data.triggerDeselectionWarning
    return modalRef.result;
  }

  loadIcons() {
    this.globalObjectsService.Products.subscribe(products => {
      let p;
      if (this.isMultiInstance) p = products.find(x => x.line_GUID == this.product?.employeeBenefitTypes?.line_RecordID);
      else p = products.find(x => x.line_GUID == this.product.line_RecordID);
      if (p?.icon2) {
        this.productThumbnail = p?.icon2;
        this.productCategory = p?.category;
      }
    });
  }

  getProductInfo() {
    this.globalObjectsService.Products.subscribe(r => {
      let products = r.filter(r => r?.line_GUID == this.product?.line_RecordID);
      if (products != null && products.length > 0) {
        this.currentProduct = products[0];
      }
    });
  }

  removeTheRoleTabFromPanelHeader(): void {
    let ngbAccordions = this.elRef.nativeElement.querySelectorAll("#benefit-details-for-print" + this.product?.line_RecordID);
    if (this.elRef.nativeElement.querySelectorAll("#benefit-details-for-print" + this.product?.line_RecordID)) {
      if (ngbAccordions) {
        ngbAccordions.forEach((panelHeader: any) => {
          panelHeader.removeAttribute("role");
          panelHeader.removeAttribute("aria-multiselectable");
          let expansionPanel = panelHeader.querySelectorAll('.expansion-panel');
          if (expansionPanel != undefined && expansionPanel?.length > 0) {
            expansionPanel.forEach((expansion: any) => {
              expansion.firstChild.removeAttribute("role");
            });
          }
        });
      }
      this.tabRoleChecked = true;
    }
  }

  showMessagePanel(product) {
    this.messages = [];
    if (
      product?.eoiInformation ||
      product?.defaultedChange?.isNew ||
      product?.defaultedChange?.costChanged ||
      product?.defaultedChange?.dependantChanged ||
      product?.defaultedChange?.planChanged ||
      product?.userChange?.isDeleted ||
      product?.defaultedChange?.isDeleted
    ) {
      this.getMessages(product);
      return true;
    }
  }

  getMessages(product) {
    if (product?.eoiInformation) {
      this.messages.push(this.resourceStrings['Benefit.ExpansionPanelTable.EOI.ActiveBenefitsPendingInformation']);
    }
    if (product?.userChange?.isDeleted || product?.defaultedChange?.isDeleted) {
      this.messages.push(this.resourceStrings['Aon.Benefit.Message.Delete']);
    }
    if (product?.defaultedChange?.isNew) {
      this.messages.push(this.resourceStrings['Aon.Benefit.Message.New']);
    }
    if (product?.defaultedChange?.planChanged) {
      this.messages.push(this.resourceStrings['Aon.Benefit.Message.PlanChange']);
    }
    if (product?.defaultedChange?.costChanged) {
      this.messages.push(this.resourceStrings['Aon.Benefit.Message.CostChange']);
    }
    if (product?.defaultedChange?.dependantChanged) {
      this.messages.push(this.resourceStrings['Aon.Benefit.Message.DependantChange']);
    }
  }

  loadCustomerService() {
    this.globalObjectsService.Products.subscribe(r => {
      let products = [];
      products = r.filter(x => x.line_GUID == this.product.line_RecordID);
      if (products != null && products.length > 0) {
        this.commonService.getContentItem('CustomerService', products[0].nodeAliasPath)
          .pipe(take(1))
          .subscribe(customerService => {
            this.customerService = customerService;
          });
      }
    });
  }


  triggerLifeStyleFlow(flowId: string, product: any): void {
    if (this.currentProduct) {
      this.analyticsService.trackCustomEvent({
        "event": "trigger-lifestyle-benefits",
        "product-english-name": this.currentProduct.gaTag
      });
    }
    if (product.anytimeEntityInformationType && product.anytimeEntityInformationType.sableFlow_RecordID && product.line_RecordID) {
      flowId = product.anytimeEntityInformationType.sableFlow_RecordID;
      let para = {
        queryParams: {
          browseBenefitsLineId: product.line_RecordID,
          isAnytime: true,
          isLifeStle: false
        }
      };
      sessionStorage.setItem('activeEventFlowId', flowId);
      let redirectUrl = this.baseUrl + "/quote/eventflow-" + flowId + "/details";
      this.router.navigate([redirectUrl], para);
    }
    else if (flowId) {
      sessionStorage.setItem('activeEventFlowId', flowId);
      this.router.navigate([this.baseUrl + '/quote/eventflow-' + flowId + '/details/']);
    }
  }

  checkLockInPeriod(lockOutEndDate) {
    if (!lockOutEndDate) {
      return true;
    }
    const l = new Date(lockOutEndDate);
    return l < new Date();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  getPrimarySecondaryBeneficiaries(line, bt: number) {
    if (!line?.allocationType?.allocationBeneficiaries) {
      return [];
    }

    return line.allocationType.allocationBeneficiaries.filter((data) => {
      return data.beneficiaryType === bt;
    })
  }


  getDependents(coveredDeps = [], eoiDeps = []) {
    coveredDeps = coveredDeps || [];
    eoiDeps = eoiDeps || [];
    return Array(Math.max(coveredDeps.length, eoiDeps.length)).fill(1);
  }

  private getParticipantsForEOIInformation(product: any): Array<{
    interim: DependantNameType,
    requested: DependantNameType
  }> {
    const isEmployee = (x) => x.lookupRelationshipType_RecordID === 0;
    let coveredDeps = product?.coveredDependants || [];
    let eoiDeps = product?.eoiInformation?.coveredDependants || [];
    if (!product?.showEmployeeAsCovered) {
      coveredDeps = coveredDeps.filter(c => !isEmployee(c))
      eoiDeps = eoiDeps.filter(c => !isEmployee(c))
    }

    const tableData = [];
    const tableLength = Math.max(coveredDeps.length, eoiDeps.length);

    for (let i = 0; i < tableLength; ++i) {
      tableData.push({
        interim: coveredDeps[i],
        requested: eoiDeps[i],
      })
    }
    return tableData.length === 0 ? [] : tableData;
  }

  openBenefitInfoPopup(lineId: string, lineName: string): void {
    this.benefitService.openBenefitInfoPopup(lineId, lineName);
  }

  benefitInfoPopup(event) {
    this.openBenefitInfoPopup(event.line_RecordID, event.name);
  }

  // check 1st
  openDocumentPopup(subscriber: Subscriber<any> = null) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      ariaLabelledBy: 'app-modal-anchor'
    };
    ngbModalOptions.container = '#app-modal-anchor';
    const modalRef = this.modalService.open(CheckOutDocumentsComponent, ngbModalOptions);
    modalRef.componentInstance._triggeredFrom = 'app-benefit-details'
    modalRef.result.then((result) => {

    }, (reason) => {
      /*Leave empty or handle reject*/
      /*This is used to avoid the JS error when calling dismiss to close a modal*/
    });
  }
  openTooltip($event) {
    $event.target.click();
  }

  deselect(data: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'app-modal-anchor'
    };
    ngbModalOptions.container = '#app-modal-anchor';
    const modalRef = this.modalService.open(DeselectBenefitConfirmComponent, ngbModalOptions);
    modalRef.componentInstance.EmployeeBenefitID = data.employeeBenefitID;
    modalRef.componentInstance.BenefitName = data.name;
  }

  employeeCostInfo() {
    if (this.transformNegativeEmployeeCost) {
      this.product.employeeCost_PayPeriod = this.product?.employeeCost_PayPeriod.toString().replace("-", "");
      this.product.employeeCost_Annual = this.product?.employeeCost_Annual.toString().replace("-", "");
      if (this.product?.eoiInformation) {
        this.product.eoiInformation.employeeCost = this.product?.eoiInformation?.employeeCost.toString().replace("-", "");
        this.product.eoiInformation.employeeAnnualCost = this.product?.eoiInformation?.employeeAnnualCost.toString().replace("-", "");
      }
    }

    if (this.product?.adjustmentType != null) {
      this.product?.adjustmentType?.taxAdjustments?.forEach((tax: TaxAdjustmentType) => {
        if (tax?.employeeTaxAmount && tax?.employeeTaxAmount > 0) {
          this.taxes.employee.push({
            employeePayPeriodTaxAmount: tax?.employeeTaxAmount,
            employeeAnnualTaxAmount: tax?.employeeTaxAmount,
            employerPayPeriodTaxAmount: 0,
            employerAnnualTaxAmount: 0,
            recordID: tax?.lookupFlexTaxType_RecordID,
            isNonRecurring: false,
            alternateFlexTaxTypeName: tax?.alternateFlexTaxTypeName
          })
        }
        if (tax?.employerTaxAmount && tax?.employerTaxAmount > 0) {
          this.taxes.employer.push({
            employeePayPeriodTaxAmount: 0,
            employeeAnnualTaxAmount: 0,
            employerPayPeriodTaxAmount: tax?.employerTaxAmount,
            employerAnnualTaxAmount: tax?.employerTaxAmount,
            recordID: tax?.lookupFlexTaxType_RecordID,
            isNonRecurring: false,
            alternateFlexTaxTypeName: tax?.alternateFlexTaxTypeName
          })
        }
      })
    } else {
      this.product?.taxes?.forEach((tax: TaxType) => {
        if (tax.employeePayPeriodTaxAmount && tax.employeePayPeriodTaxAmount > 0) {
          this.taxes.employee.push(tax);
        }
        if (tax.employerPayPeriodTaxAmount && tax.employerPayPeriodTaxAmount > 0) {
          this.taxes.employer.push(tax);
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  public get showAdjustmentCosts(): boolean { return this.product?.adjustmentType != null }
  public get showOutstandingCost(): boolean { return this.outstandingCost != null && this.outstandingCost > 0 }
  private get showEmployeeCost(): boolean { return (this.product?.employeeCost_PayPeriod != null || this.product?.employeeCost_Annual != null) && !this.product?.hideEmployeeCost }
  public get showEmployeeCostOnMainCardCostDisplay(): boolean { return this.showEmployeeCost && !(this.showPaymentSources || this.showOutstandingCost) }
  public get showEmployeeCostOnTaxDetails(): boolean { return this.showEmployeeCost }
  public get showEmployeeCostOnCoverageDetails(): boolean { return this.showEmployeeCost && !this.showOutstandingCost }
  public get showFundingForfeiture(): boolean { return (this.product?.fundingForfeiture_PayPeriod != null || this.product?.fundingForfeiture_Annual != null) && this.product?.fundingForfeiture_PayPeriod > 0 && this.showERCostAsFunding }
  public get showFundingRelease(): boolean { return ((this.product?.fundingRelease_PayPeriod != null || this.product?.fundingRelease_Annual != null) && this.product?.fundingRelease_PayPeriod > 0) && this.showERCostAsFunding }
  public get showPaymentSources(): boolean { return this.product?.paymentSources?.length > 0 }
  public get isMultiplePaymentSources(): boolean { return this.product?.paymentSources?.length > 1 }
  private get showEmployeeTax(): boolean { return this.getEmployeeTax > 0 }
  public get showEmployeeTaxOnMainCardCostDisplay(): boolean { return this.showEmployeeTax && this.showEmployeeCostOnMainCardCostDisplay }
  public get showEmployeeTaxOnTaxDetails(): boolean { return this.showEmployeeTax && this.showEmployeeCostOnTaxDetails }
  public get showEmployeeTaxOnCoverageDetails(): boolean { return this.showEmployeeTax && this.showEmployeeCostOnCoverageDetails }
  private get showEmployerTax(): boolean { return this.getEmployerTax > 0 }
  public get showEmployerTaxOnMainCardCostDisplay(): boolean { return this.showEmployerTax && this.showEmployerCost }
  public get showEmployerTaxOnTaxDetails(): boolean { return this.showEmployerTax && this.showEmployerCost }
  public get showEmployerTaxOnCoverageDetails(): boolean { return this.showEmployerTax }
  public get showERCostAsFunding(): boolean { return this.displayERCostAsFunding && !this.showAdjustmentCosts && this.product?.fundedCoverage_PayPeriod > 0 }
  public get showTaxDetailsPanel(): boolean { return this.showPaymentSources && (this.taxes.employee?.length > 0 || this.taxes.employer?.length > 0); }
  public get showFundingTax(): boolean { return this.showEmployerTax && this.showERCostAsFunding }
  public get showEmployerCost() {
    if (this.uiConfig.uiConfiguration.hideEmployerCostForAllBenefit)
      return false;
    let showCost = false;
    if (!this.product.hideEmployerCost && (this.getEmployerCost > 0)) {
      showCost = true;
    }
    return showCost && !this.showERCostAsFunding;
  }

  public get getEmployerCost(): number {
    if (this.isProfileDisplay && this.product?.adjustmentType?.actualPeriodCost_Employer > 0) {
      return this.product.adjustmentType.actualPeriodCost_Employer;
    }
    if (this.showAdjustmentCosts) { return isNaN(this.product?.adjustmentType?.employerCost) ? 0 : this.product?.adjustmentType?.employerCost }
    if (this.isPerPay) { return this.product.employerCost_PayPeriod }
    return this.product.employerCost_Annual;
  }

  public get getEmployerTax(): number {
    if (this.showAdjustmentCosts) { return isNaN(this.product?.adjustmentType?.employerTaxTotal) ? 0 : this.product?.adjustmentType?.employerTaxTotal }
    if (this.isPerPay) { return this.product.employerPayPeriodTaxTotalAmount }
    return this.product.employerAnnualTaxTotalAmount;
  }

  public get getEmployeeCost(): number {
    if (this.isProfileDisplay && this.product?.adjustmentType?.actualPeriodCost_Employee > 0) {
      return this.product.adjustmentType.actualPeriodCost_Employee;
    }
    if (this.showAdjustmentCosts) { return isNaN(this.product?.adjustmentType?.employeeCost) ? 0 : this.product?.adjustmentType?.employeeCost }
    if (this.isPerPay) { return this.product.employeeCost_PayPeriod }
    return this.product.employeeCost_Annual;
  }

  public get getEmployeeTax(): number {
    if (this.showAdjustmentCosts) { return isNaN(this.product?.adjustmentType?.employeeTaxTotal) ? 0 : this.product?.adjustmentType?.employeeTaxTotal }
    if (this.isPerPay) { return this.product.employeePayPeriodTaxTotalAmount }
    return this.product.employeeAnnualTaxTotalAmount;
  }

  public get getTotalCost(): number {
    if (this.showAdjustmentCosts) {
      let totalCost = 0;
      const calcTotalCost = (flag, value) => { if (flag) { totalCost += value } };
      calcTotalCost(this.showEmployerCost, this.getEmployerCost);
      calcTotalCost(this.showEmployeeCostOnMainCardCostDisplay, this.getEmployeeCost);
      calcTotalCost(this.showOutstandingCost, this.outstandingCost);
      calcTotalCost(this.showEmployerTaxOnMainCardCostDisplay, this.getEmployerTax);
      calcTotalCost(this.showEmployeeTaxOnMainCardCostDisplay, this.getEmployeeTax);
      calcTotalCost(this.showPaymentSources, this.isPerPay ? this._totalPaymentSourceCost.perPay : this._totalPaymentSourceCost.perAnnual);
      return totalCost;
    }
    if (this.isPerPay) { return this.product.totalCost_PayPeriod }
    return this.product.totalCost_Annual;
  }

  public get getTotalCostForTaxPanel(): number {
    let totalCost = 0;
    if (this.showEmployeeCostOnTaxDetails) { totalCost += Number(this.getEmployeeCost) }
    if (this.showEmployeeTaxOnTaxDetails) { totalCost += Number(this.getEmployeeTax) }
    if (this.showEmployerCost) { totalCost += Number(this.getEmployerCost) }
    if (this.showEmployerTaxOnTaxDetails) { totalCost += Number(this.getEmployerTax) }
    return totalCost;
  }

  public get getTotalCostForCoveragePanel(): number {
    let totalCost = this.getEmployerCost;
    if (this.showOutstandingCost) { totalCost += Number(this.outstandingCost) }
    if (this.showEmployeeCostOnCoverageDetails) { totalCost += Number(this.getEmployeeCost) }
    if (this.showEmployeeTaxOnCoverageDetails) { totalCost += Number(this.getEmployeeTax) }
    if (this.showEmployerTaxOnCoverageDetails) { totalCost += Number(this.getEmployerTax) }
    return totalCost;
  }

  public get showEmployeeCostWithEoi() {
    if (!this.product.hideEmployeeCost) {
      return true;
    }
    else if (this.product.hideEmployeeCost && this.isPerPay) {
      return Number(this.product.employeeCost_PayPeriod) > 0 || Number(this.product?.eoiInformation?.employeeCost) > 0;
    }
    else if (this.product.hideEmployeeCost && !this.isPerPay) {
      return Number(this.product.employeeCost_Annual) > 0 || Number(this.product?.eoiInformation?.employeeAnnualCost) > 0;
    }
    else {
      return true;
    }
  }

  public get outstandingCost() { return this.isPerPay ? isNaN(this.product?.outstanding_PayPeriod) ? 0 : this.product?.outstanding_PayPeriod : isNaN(this.product?.outstanding_Annual) ? 0 : this.product?.outstanding_Annual }

  /**
   * @returns the class of the cost
   */
  public get getPaymentSourceCostClass(): string {
    if (!this.showEmployeeCostOnMainCardCostDisplay && !this.showOutstandingCost) {
      if (this.product?.paymentSources?.length == 1) {
        return "headline-cost theme-color";
      }
      return "headline-cost theme-color cost-multi-payment-source-value";
    }
    return "";
  }

  /**
   * Controls total cost section display
   */
  public get showTotalCostSection(): boolean {
    if (!this.showEmployerCost) {
      return false;
    }
    const taxDisplayFlag = this.showEmployerTaxOnMainCardCostDisplay || this.showEmployeeTaxOnMainCardCostDisplay;
    return ((this.showEmployeeCostOnMainCardCostDisplay && (this.showEmployerCost || this.showERCostAsFunding || taxDisplayFlag))
      || (this.showEmployerCost && (this.showEmployeeCostOnMainCardCostDisplay || this.showERCostAsFunding || taxDisplayFlag))
      || (this.showPaymentSources && (this.showEmployerCost || this.showERCostAsFunding || this.isMultiplePaymentSources || this.outstandingCost || taxDisplayFlag))
    );
  }

  /**
   * EOI Information Coverage Response
   */
  public get coverageForEOIInformation() {
    return {
      employeeCoverage: {
        interim: this.product?.coverageName,
        requested: this.product?.eoiInformation?.coverageName
      },
      volume: {
        interim: this.product?.employeeVolume,
        requested: this.product?.eoiInformation?.employeeVolume,
        extra: {
          visibility: !this.product?.hideCoverageVolume,
          showDataByAmountTypeInterim: {
            planData: {
              currencyCode: this.product?.currencyCode,
              currencySymbol: this.product?.currencySymbol,
              amountType_RecordID: this.product?.amountType_RecordID,
              amountTypeCaption: this.product?.amountTypeCaption,
              customAmountTypeValue: this.product?.customAmountTypeValueg
            }
          },
          showDataByAmountTypeRequested: {
            planData: {
              currencyCode: this.product?.eoiInformation?.currencyCode,
              currencySymbol: this.product?.eoiInformation?.currencySymbol,
              amountType_RecordID: this.product?.eoiInformation?.amountType_RecordID,
              amountTypeCaption: this.product?.eoiInformation?.amountTypeCaption,
              customAmountTypeValue: this.product?.eoiInformation?.customAmountTypeValueg
            },
            lineData: {
              currencyCode: this.product?.currencyCode
            }
          }
        }
      },
      employeeContribution: {
        interim: this.isPerPay ? this.product?.employeeCost_PayPeriod : this.product?.employeeCost_Annual,
        requested: this.isPerPay ? this.product?.eoiInformation?.employeeCost : this.product?.eoiInformation?.employeeAnnualCost,
        extra: {
          visibility: this.showEmployeeCostWithEoi,
          label: this.product?.employeeCostLabel != null && this.product?.employeeCostLabel != '' ? this.product?.employeeCostLabel : this.resourceStrings['Benefit.ExpansionPanelTable.EOI.YourCost']
        }
      },
      employerContribution: {
        interim: this.isPerPay ? this.product?.employerCost_PayPeriod : this.product?.employerCost_Annual,
        requested: this.isPerPay ? this.product?.eoiInformation?.employerCost : this.product?.eoiInformation?.employerAnnualCost,
        extra: {
          visibility: this.showEmployerCost,
          label: this.product?.employerCostLabel != null && this.product?.employerCostLabel != '' ? this.product?.employerCostLabel : this.resourceStrings['Benefit.ExpansionPanelTable.EOI.EmployerCost']
        }
      },
      totalContribution: {
        interim: this.isPerPay ? this.product?.totalCost_PayPeriod : this.product?.totalCost_Annual,
        requested: this.isPerPay ? this.product?.eoiInformation?.totalCost : this.product?.eoiInformation?.totalCost_Annual,
        extra: {
          label: this.product?.totalCostLabel != null && this.product?.totalCostLabel != '' ? this.product?.totalCostLabel : this.resourceStrings['Benefit.ExpansionPanelTable.EOI.TotalCost']
        }
      }
    }
  }

  public get checkAdjustedCostLabelDisplay(): boolean {
    if (this.showAdjustmentCosts && !this.isProfileDisplay) { return true; }
    return false;
  }
}
