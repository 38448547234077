@if (data.value != null && data.value != '') {
  <div class="sable-label-pair  d-flex {{data.cssClass}}">
    <div class="icon-col"></div>
    @if (data.label) {
      <p class="sable-label d-flex m-r-5" [class.with-desc]="data.title != null && data.title != ''"
        [ngClass]="{'tooltip': data.title != null && data.title != ''}">
        {{data.label}}
        @if (data.title != null && data.title != '') {
          <span class="tooltiptext">{{data.title}}</span>
        }
      </p>
    }
    @if (labelValueText(data) !== '') {
      <p class="sable-value d-flex m-r-5">
        <span class="sable-label-value-item">{{labelValueText(data)}}</span>
      </p>
    }
  </div>
}