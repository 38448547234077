import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy, Injector } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UiconfigrationService } from '../../../core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { BootstrapRequestContext } from 'tbs-typings';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { ContentItemModel } from 'src/app/shared/models/contentItem.model';
import { ProfileService } from '../../../modules/profile/profile.service';

@Component({
  selector: 'app-masthead-delegate-employees',
  templateUrl: './masthead-delegate-employees.component.html'
})
export class MastheadDelegateEmployeesComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  content: ContentItemModel;
  @Input() resourcestrings;
  @Input() actAsDelegate
  @Input() hasDelegateForEmployees;
  @Output() onCloseClick = new EventEmitter();
  @Output() onGracePeriodUpdated = new EventEmitter<boolean>();
  baseUrl: string;

  uiConfig: UIConfigurationType;
  statusofclick: boolean;
  siteType: string;
  url: string;
  constructor(
    private readonly injector: Injector,
    private readonly authenticationService: AuthenticationService,
    private readonly uiconfigrationService: UiconfigrationService,
    public profileService: ProfileService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }

  ngOnInit(): void {

    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = "/" + this.helperService.getBaseUrl();

  }

  ngOnDestroy(): void {
    this.baseUrl = "";
  }

  closeModal(): void {
    this.statusofclick = true;
    this.onCloseClick.emit(this.statusofclick);
  }

}
