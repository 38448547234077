import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SableFlowEventBusService } from '@aon/sable-flow';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-qoute-delete-popup',
  templateUrl: './qoute-delete-popup.component.html'
})
export class QouteDeletePopupComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  result: any = [];
  triggerDeselectionWarning: boolean;
  uiConfig: UIConfigurationType;
  @Input() public EmployeeBenefitID;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  constructor(
    public modalService: NgbActiveModal,
    public activeModal: NgbActiveModal,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly quoteService: QuoteService,
    private readonly ngxService: NgxUiLoaderService,
    private readonly busservice: SableFlowEventBusService,
    private readonly injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });

    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
  }
  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
  close(event) {
    event.preventDefault();
    setTimeout(() => {
      this.modalService.close(false);
    }, 100);
  }
  save() {
    this.deleteEndPurchase();
    this.modalService.close(true);
  }
  deleteEndPurchase() {
    this.ngxService.start();
    this.quoteService.deleteEndPurchaseQuote(this.EmployeeBenefitID).subscribe((response) => {
      this.result = response;
      if (this.result.didSave) {
        this.globalObjectsService.additionalContextData['SupressProgressInfo'] = false; // Flag to identify and pass to GI if any participant has been deleted from coverage
        this.busservice.next({
          flag: true,
          saveData: null,
          goToInstruction: null,
          additionalContextData: this.globalObjectsService.additionalContextData
        });
      }
      this.ngxService.stop();
    });
  }
}
