import { Component, Injector, Input, Output, EventEmitter, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { MFAOption, OktaInfoModel, MFAStep, MFAData, MFAPreferenceEmail } from 'src/app/shared/models/oktaInfoModel';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { MFAResendDisablePeriod, RegularExpression, SessionStorageKey } from 'src/app/shared/models/constants';
import { MetaDataService } from '../services/metadata.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, combineLatestWith, tap, timer } from 'rxjs';
import { map, take, takeUntil, takeWhile } from 'rxjs/operators';
import { GlobalObjectsService } from '../services/global-objects.service';
import { UIConfigurationType } from '../models/uiconfigration.interface';
@Component({
  selector: 'app-mfa-authentication',
  templateUrl: './mfa-authentication.component.html'
})
export class MfaAuthenticationComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Input() inLoginProcess = false;
  @Input() newMFAChannelInformations: MFAData;
  @Input() existingEmail: string = "";
  @Output() afterPassedVerify: EventEmitter<{
    status?: boolean,
    step?: MFAStep,
    savedChannels?: Array<MFAOption>
  }> = new EventEmitter();
  @Output() finishedAddEmailAndPhone: EventEmitter<{
    step?: MFAStep,
    useSameEmailForMarketingCommunicationChecked?: boolean
  }> = new EventEmitter();
  @Output() backToTermsAndConditions = new EventEmitter<any>();
  @Output() backToLogIn = new EventEmitter();
  @Output() cancelMfa = new EventEmitter();
  @Input() step: MFAStep;
  @Input() employeeRecordID: string;
  @Input() preferredName: string;
  @Input() workEmailForRegistration: string;
  @Input() homeEmailForRegistration: string;
  @Input() alternateEmailForRegistration: string;
  baseUrl: string;
  isCodeSent = false;
  maskedEmail: string;
  maskedPhoneNumber: string;
  selectedOption: MFAOption;
  mfaOptions = [];
  errorMessage = '';

  MFAOption = MFAOption;
  MFAStep = MFAStep;
  MFAPreferenceEmail = MFAPreferenceEmail;
  MFAResendDisablePeriod = MFAResendDisablePeriod;
  isLoading = false;
  isSending = false;
  isReSending = false;
  isVerifying = false;
  ismfaRegistering = false;
  isMFAOptionsExisting = true;
  otpCode: {
    [key: string | number]: string
  } = {};
  enterUniqueCode: string = '';
  countryPhoneCodes: any;
  mfaUniqueIdeIndex: string;
  isMFARegistered: boolean = false;
  isUniIdenVerifying = false;
  mfaRegistration: FormGroup;

  mfaAddNewEmail: FormGroup;
  mfaAddNewPhone: FormGroup;

  userEmail: string;
  enableOverrideMFAData: boolean = false;
  mFAUniqueIdentifier: string;
  userPhone: string;
  userPhoneCode: string;
  inputIdentifier: string;
  enableMFAEmail: boolean = false;
  enableMFAPhone: boolean = false;
  workEmail: string;
  personalEmail: string;
  alternateEmail: string;
  mfaEmail: string;
  preferenceEmail: MFAPreferenceEmail;

  selectedEmail: string;
  selectedMobile: string;
  selectedMobileCountryCode: string;
  selectedMobileCountryCodeName: string;
  selectedPhone: string;

  isPreferenceEmailSelected: boolean = false;
  isUniqueIdentifierFieldBlank: boolean = false;
  isOTPFieldBlank: boolean = false;
  uiConfig: UIConfigurationType;
  useSameEmailForMarketingCommunicationChecked: boolean = false;
  isInDisablePeriod: boolean;
  isExistingEmail: boolean = true;
  resendTimeRemaining$!: Observable<number>;
  sentNewChannelCodesTo: {
    [key: string]: {
      status: boolean,
      maskedOption: string,
      errorMessage: string,
      reSendCode: boolean
    }
  } = {};
  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    private readonly mfaService: MFAService,
    private readonly authenticationService: AuthenticationService,
    protected uiconfigrationService: UiconfigrationService,
    private readonly injector: Injector,
    private readonly metaDataService: MetaDataService,
    private readonly ngxService: NgxUiLoaderService,
    private readonly modalService: NgbModal,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly elementRef: ElementRef
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.MFA.Instruction',
      'Aon.MFA.Hi',
      'Aon.MFA.Cancel',
      'Aon.MFA.Close',
      'Aon.MFA.Continue',
      'Aon.MFA.ResendCode',
      'Aon.MFA.Verify',
      'Aon.MFA.UserInformation',
      'Aon.MFA.NoValues',
      'Aon.MFA.SelectOption',
      'Aon.MFA.ChooseOption',
      'Aon.MFA.CheckEmail',
      'Aon.MFA.CheckPhone',
      'Aon.MFA.SentACodeTo',
      'Aon.MFA.EnterCode',
      'Aon.MFA.Header',
      'Aon.MFA.SystemErrorMessage',
      'Aon.MFA.EmailCode',
      'Aon.MFA.SMSCode',
      'Aon.MFA.BrowserMessage',
      'Aon.MFA.GetACodeEmailedTo',
      'Aon.MFA.GetACodeTextedTo',
      'Aon.MFA.SSNMessage',
      'Aon.MFA.EnterNationIdMsg',
      'AON.MFA.InvalidUniIdentifierMessage',
      'Aon.MFA.EnterEmpNumberIdMsg',
      'Aon.MFA.EnterSystemIdMsg',
      'Aon.MFA.EnterOtherIdMsg',
      'Aon.Profile.Phone',
      'Aon.MFA.Title',
      'Aon.MFA.Verification',
      'Aon.Common.EmailLabel',
      'Aon.Profile.Phone',
      'Aon.MFA.EmptyEmail',
      'Aon.MFA.EmptyPhone',
      'Aon.MFA.InprogressAlert',
      'Aon.Common.BannerTitle.Info',
      'Aon.MFA.ChooseOption',
      'Aon.MFA.InvalidEmail',
      'Aon.MFA.NewChannels.SystemErrorMessage',
      'Aon.MFA.NewChannels.SendEmail.ErrorMessage',
      'Aon.MFA.NewChannels.SendSMS.ErrorMessage',
      'Aon.MFA.NewChannels.VerifyEmail.ErrorMessage',
      'Aon.MFA.NewChannels.VerifySMS.ErrorMessage',
      'Aon.MFA.Verification.SystemErrorMessage',
      'Aon.MFA.AddNewEmail',
      'Aon.MFA.AddNewPhone',
      'Aon.MFA.Header.EnterUniqueIdentifier',
      'Aon.MFA.Header.SelectAnEmail',
      'Aon.MFA.Header.AddNewEmail',
      'Aon.MFA.Header.CheckYourEmail',
      'Aon.MFA.Header.SelectPhone',
      'Aon.MFA.Header.AddNewPhone',
      'Aon.MFA.Header.CheckYourPhone',
      'Aon.Common.Back',
      'Aon.Common.Skip',
      'Aon.MFA.SendCode',
      'Aon.Common.Submit',
      'Aon.MFA.ResendCode.Info',
      'Aon.MFA.ChoosePreferenceEmailOrPhone',
      'Aon.MFA.Header.SelectAutenticationMethod',
      'Aon.MFA.Header.ChooseAuthenticationOption',
      'Aon.MFA.EmptyUniqueIdentifier',
      'Aon.MFA.EmptyOTP',
      'Aon.MFA.CodeValidPeriodInfo',
      'Aon.MFA.UseNewEmailForMarketing'
    ]);
  }

  ngOnInit(): void {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (sessionStorage.getItem(SessionStorageKey.MfaTempUser) != null) {
      this.authenticationService.tempUser = JSON.parse(sessionStorage.getItem(SessionStorageKey.MfaTempUser));
      this.inLoginProcess = true;
    }
    if (!this.authenticationService.tempUser && this.inLoginProcess) {
      this.router.navigate([this.baseUrl + '/login']);
    }
    this.ngxService.start();

    if (!this.inLoginProcess) {
      this.loadResourceStringAsObservable()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.getResouceStringsAsObject(res);
          this.ngxService.stop();
        });
      this.enableMFAEmail = this.uiConfig.uiConfiguration.enableMFAEmail;
      this.enableMFAPhone = this.uiConfig.uiConfiguration.enableMFAMobile;
      this.enableOverrideMFAData = this.uiConfig.uiConfiguration.enableOverrideMFAData;
      this.workEmail = this.workEmailForRegistration;
      this.personalEmail = this.homeEmailForRegistration;
      this.alternateEmail = this.alternateEmailForRegistration;
      if (!this.enableOverrideMFAData) {
        this.getMfaOptions();
      }
      if (this.step == MFAStep.AddNewPhone) {
        this.loadMetaData();
      }
    } else {
      const jwtHelper = new JwtHelper();
      let user = this.authenticationService.tempUser;

      const parsedToken = jwtHelper.decodeToken(user.id_token);
      let mfaUser = JSON.parse(parsedToken.MFAUserSettings);

      this.enableMFAEmail = mfaUser.EnableMFAEmail;
      this.enableMFAPhone = mfaUser.EnableMFAMobile;
      this.isMFARegistered = !!mfaUser.IsMFARegistered;
      this.enableOverrideMFAData = !!mfaUser.EnableOverrideMFAData;
      this.mFAUniqueIdentifier = mfaUser.MFAUniqueIdentifier;
      this.workEmail = mfaUser.WorkEmail;
      this.personalEmail = mfaUser.PersonalEmail;
      this.alternateEmail = mfaUser.AlternateEmail;
      this.preferredName = mfaUser.PreferredName;
      this.setDefaultPreferenceEmail();
      this.loadResourceStringAsObservable().pipe(combineLatestWith(this.getmfaData())).subscribe(([res, mfaData]) => {
        this.getResouceStringsAsObject(res);
        this.mfaEmail = mfaData.mfaEmail;
        if (this.isMFARegistered && this.isOneMFA()) {
          this.mfaOptions = [];
          this.mfaService.getMFAOptions(this.inLoginProcess, this.employeeRecordID).subscribe((data) => {
            for (const key in data) {
              const item = { name: key, value: data[key] };
              this.mfaOptions.push(item);
            }

            if (this.mfaOptions.length === 0) {
              this.errorMessage = this.resourceStrings['AON.MFA.NoValues'];
              this.step = MFAStep.NoAuthInfo;
              this.ngxService.stop();
            } else {
              if (this.enableMFAEmail) {
                const emailOption = this.mfaOptions.find(option => option.name == 'Email');
                if (emailOption) {
                  this.selectOption(emailOption.name, emailOption.value);
                  this.sendCode('Email', emailOption.value, MFAStep.EnterCode);
                } else {
                  this.errorMessage = this.resourceStrings['AON.MFA.NoValues'];
                  this.step = MFAStep.NoAuthInfo;
                  this.ngxService.stop();
                }
              } else {
                const smsOption = this.mfaOptions.find(option => option.name == 'SMS');
                if (smsOption) {
                  this.selectOption(smsOption.name, smsOption.value);
                  this.sendCode('SMS', smsOption.value, MFAStep.EnterCode);
                } else {
                  this.errorMessage = this.resourceStrings['AON.MFA.NoValues'];
                  this.step = MFAStep.NoAuthInfo;
                  this.ngxService.stop();
                }
              }
            }
          });
        } else if (this.enableOverrideMFAData === true && this.isMFARegistered === false) {
          this.ngxService.stop();
          this.step = MFAStep.EnterSsnNumber;
          this.mfaUniqueIdeIndex = this.mFAUniqueIdentifier != null ? this.mFAUniqueIdentifier : null;
        } else {
          this.getMfaOptions();
        }
      });
    }

    this.mfaAddNewEmail = this.formBuilder.group({
      username: ['', this.enableMFAEmail ? [Validators.email, Validators.required, Validators.pattern(RegularExpression.EmailWithMask)] : []],
    });

    this.mfaAddNewPhone = this.formBuilder.group({
      phone: ['', this.enableMFAPhone ? Validators.required : []],
      countryPhoneCode: [null, this.enableMFAPhone ? Validators.required : []]
    });
  }

  isMfaRegisteredUser(): boolean {
    if (this.isMFARegistered === true) {
      return true;
    } else {
      return false;
    }
  }

  getMfaOptions(): void {
    let stepData = MFAStep.SelectOption;
    this.isLoading = true;
    this.mfaOptions = [];
    this.mfaService.getMFAOptions(this.inLoginProcess, this.employeeRecordID).subscribe((data) => {
      for (const key in data) {
        const item = { name: key, value: data[key] };
        this.mfaOptions.push(item);
      }

      if (this.mfaOptions.length === 0) {
        this.errorMessage = this.resourceStrings['AON.MFA.NoValues'];
        stepData = MFAStep.NoAuthInfo;
      } else if (this.mfaOptions.length > 0) {
        this.selectOption(this.mfaOptions[0].name, this.mfaOptions[0].value);
      }
      this.step = stepData;
      this.ngxService.stop();
      this.isLoading = false;
    });
  }

  loadMetaData() {
    if (this.countryPhoneCodes) {
      this.countryPhoneCodes.forEach(subitem => {
        if (subitem.recordID == this.userPhoneCode) {
          this.mfaAddNewPhone.controls.countryPhoneCode.setValue(subitem);
        }
      });
    } else {
      this.metaDataService.countryPhoneCodes().subscribe(countryPhoneCodes => {
        this.countryPhoneCodes = countryPhoneCodes;
        this.countryPhoneCodes.forEach(subitem => {
          if (subitem.recordID == this.userPhoneCode) {
            this.mfaAddNewPhone.controls.countryPhoneCode.setValue(subitem);
          }
        });
      });
    }
  }

  selectOption(key, value): void {
    this.errorMessage = '';
    if (key == 'Email') {
      this.selectedOption = MFAOption.Email;
      this.maskedEmail = value;
      this.isSending = false;
    } else if (key == 'SMS') {
      this.selectedOption = MFAOption.SMS;
      this.maskedPhoneNumber = value;
      this.isSending = false;
    }
    this.otpCode[this.selectedOption] = "";
  }


  validateAllFormFields(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }

    });
  }

  savePreferenceEmail(): void {
    const model = new MFAData();
    model.mfaUniqueIdeIndex = this.mfaUniqueIdeIndex;
    model.inputIdentifier = this.inputIdentifier;
    model.employee_RecordID = this.employeeRecordID;
    model.inLoginProcess = this.inLoginProcess;
    if (this.step == MFAStep.SelectEmail && this.preferenceEmail == MFAPreferenceEmail.NewEmail) {
      this.userEmail = "";
      this.mfaAddNewEmail.reset();
      this.step = MFAStep.AddNewEmail;
      return;
    } else {
      model.step = this.step;
      model.preferenceEmail = this.preferenceEmail;
      this.userEmail = model.mfaEmail;
    }
    this.ismfaRegistering = true;
    if (this.preferenceEmail == MFAPreferenceEmail.MFAEmail) {
      if (this.enableMFAPhone) {
        this.step = MFAStep.AddNewPhone;
        this.loadMetaData();
      } else {
        this.getMfaOptions();
      }
      this.ismfaRegistering = false;
    } else {
      this.mfaService.saveMFAData(model).subscribe(
        (data: any) => {
          if (data && data.didSave) {
            this.isExistingEmail = true;
            if (!this.inLoginProcess && data.didCodeSend) {
              this.isExistingEmail = false;
              this.isInDisablePeriod = true;
              let disablePeriod = 0;
              if (this.selectedOption == MFAOption.SMS) {
                disablePeriod = MFAResendDisablePeriod.SMS;
              } else {
                disablePeriod = MFAResendDisablePeriod.Email;
              }
              this.resendTimeRemaining$ = timer(0, 1000).pipe(
                map(n => ((disablePeriod - n) * 1000)),
                tap(n => {
                  if (n == 0) {
                    this.isInDisablePeriod = false;
                  }
                }),
                takeWhile(n => n >= 0),
              );
              this.selectedOption = MFAOption.Email;
              this.maskedEmail = this.getSelectedPreferenceEmail();
              this.otpCode[this.selectedOption] = '';
              this.userEmail = this.maskedEmail;
              this.selectedEmail = this.userEmail;
              this.step = MFAStep.EnterCodeForAddNewEmail;
            } else if (this.enableMFAPhone) {
              this.step = MFAStep.AddNewPhone;
              this.loadMetaData();
            } else {
              this.getMfaOptions();
            }
            this.errorMessage = '';
            this.ismfaRegistering = false;
          }
          else {
            this.ismfaRegistering = false;
            this.errorMessage = data.errorMessages != "" ? data.errorMessages : this.resourceStrings['Aon.MFA.SystemErrorMessage'];
          }
        },
        (error) => {
          this.errorMessage = this.resourceStrings['Aon.MFA.SystemErrorMessage'];
          this.ismfaRegistering = false;
        });
    }
  }

  sendCodeToNewAddedEmail() {
    // stop here if form is invalid
    if (this.mfaAddNewEmail.invalid) {
      this.validateAllFormFields(this.mfaAddNewEmail);
      return;
    }
    this.selectedOption = MFAOption.Email;
    this.userEmail = this.mfaAddNewEmail.get('username').value;
    this.selectedEmail = this.userEmail;
    this.sendCode('Email', this.userEmail, MFAStep.EnterCodeForAddNewEmail);
  }

  sendCodeToNewAddedMobile() {
    // stop here if form is invalid
    if (this.mfaAddNewPhone.invalid) {
      this.validateAllFormFields(this.mfaAddNewPhone);
      return;
    }
    this.selectedOption = MFAOption.SMS;
    this.selectedMobile = this.mfaAddNewPhone.get('phone').value;
    this.selectedMobileCountryCode = this.mfaAddNewPhone.get('countryPhoneCode').value.recordID;
    let countryCodeName = this.mfaAddNewPhone.get('countryPhoneCode').value.name;
    if (countryCodeName) {
      countryCodeName = countryCodeName.substring(0, countryCodeName.indexOf("(") - 1);
    }
    this.selectedMobileCountryCodeName = countryCodeName;
    this.selectedPhone = countryCodeName + this.selectedMobile;
    this.sendCode('SMS', this.selectedPhone, MFAStep.EnterCodeForAddNewPhone);
  }


  sendCode(optionType?, value?, nextStep?): void {
    let inputTargetId: string = optionType == 'SMS' ? "#otpCode" + MFAOption.SMS : "#otpCode" + MFAOption.Email;
    const _this = this;
    setTimeout(() => {
      try {
        _this.elementRef.nativeElement.querySelector(inputTargetId).focus();
      } catch { }
    }, 100);
    this.clearOutErrorMessages();
    if (this.selectedOption != MFAOption.Email && MFAOption.SMS != this.selectedOption) {
      this.errorMessage = this.resourceStrings['Aon.MFA.SelectOption'];
    } else {
      this.isSending = true;

      const model = new OktaInfoModel();
      if (optionType == 'SMS') {
        this.selectedOption = MFAOption.SMS;
        this.maskedPhoneNumber = value;
        this.otpCode[this.selectedOption] = '';
      } else if (optionType == 'Email') {
        this.selectedOption = MFAOption.Email;
        this.maskedEmail = value;
        this.otpCode[this.selectedOption] = '';
      }

      //Set disable period for "Resend Code","Text Code", "Email Code"
      //Bug 1857318: UAT BUG - MFA Channel Switch Rate Limiting Issue
      this.isInDisablePeriod = true;
      let disablePeriod = 0;
      if (this.selectedOption == MFAOption.SMS) {
        disablePeriod = MFAResendDisablePeriod.SMS;
      } else {
        disablePeriod = MFAResendDisablePeriod.Email;
      }
      this.resendTimeRemaining$ = timer(0, 1000).pipe(
        map(n => ((disablePeriod - n) * 1000)),
        tap(n => {
          if (n == 0) {
            this.isInDisablePeriod = false;
          }
        }),
        takeWhile(n => n >= 0),
      );

      model.step = this.step;
      model.inLoginProcess = this.inLoginProcess;
      if (model.step == MFAStep.AddNewEmail) {
        model.userEmail = value;
      }
      if (model.step == MFAStep.AddNewPhone) {
        model.phoneNumber = value;
      }
      if (!model.inLoginProcess) {
        model.employeeRecordID = this.employeeRecordID;
      }
      model.option = this.selectedOption;
      model.isResend = false;

      this.mfaService.sendMFACode(model).subscribe(
        (data) => {
          this.errorMessage = '';
          this.isSending = false;
          if (this.isMFARegistered && this.isOneMFA()) {
            this.ngxService.stop();
          }
          if (data.result) {
            this.isCodeSent = true;

            if (this.step == MFAStep.AddNewPhone && nextStep == MFAStep.EnterCode) {
              //If it's on the add new phone step and the next step is enter code, it means the "Skip" is clicked
              //And the previous step isn't the new added email, so needs to send email to the MFA email
              //Here we need get the masked MFA email
              this.mfaOptions = [];
              this.mfaService.getMFAOptions(this.inLoginProcess, this.employeeRecordID).subscribe((data) => {
                for (const key in data) {
                  const item = { name: key, value: data[key] };
                  this.mfaOptions.push(item);
                }
                if (this.mfaOptions.length > 0) {
                  this.selectOption(this.mfaOptions[0].name, this.mfaOptions[0].value);
                  this.maskedEmail = this.mfaOptions[0].value;
                }
              });
            }

            if (nextStep) {
              this.step = nextStep;
            } else {
              this.step = MFAStep.EnterCode;
            }
          } else {
            this.errorMessage = data.errorMessage != "" ? data.errorMessage : this.resourceStrings['Aon.MFA.SystemErrorMessage'];
          }
        },
        (error) => {
          this.errorMessage = this.resourceStrings['Aon.MFA.SystemErrorMessage'];
          this.isSending = false;
        }
      );
    }
  }

  resendCodeToNewAddedEmail() {
    this.selectedOption = MFAOption.Email;
    this.resendCode();
  }

  resendCodeToNewAddedMobile() {
    this.selectedOption = MFAOption.SMS;
    this.resendCode();
  }

  resendCode(isResendCodeToNewChannel: boolean = false, option: MFAOption = this.selectedOption): void {
    this.otpCode[option] = '';
    this.isReSending = true;
    this.clearOutErrorMessages();

    const model = new OktaInfoModel();
    model.option = option;
    model.isResend = true;
    this.isReSending = true;
    model.step = this.step;
    model.inLoginProcess = this.inLoginProcess;
    if (this.step == MFAStep.EnterCodeForAddNewEmail) {
      model.step = this.step;
      model.userEmail = this.selectedEmail;
    }

    if (this.step == MFAStep.EnterCodeForAddNewPhone) {
      model.step = this.step;
      model.phoneNumber = this.selectedPhone;
    }
    if (!model.inLoginProcess) {
      model.employeeRecordID = this.employeeRecordID;
    }
    //Set disable period for "Resend Code"
    this.isInDisablePeriod = true;
    let disablePeriod = 0;
    if (this.selectedOption == MFAOption.SMS) {
      disablePeriod = MFAResendDisablePeriod.SMS;
    } else {
      disablePeriod = MFAResendDisablePeriod.Email;
    }
    this.resendTimeRemaining$ = timer(0, 1000).pipe(
      map(n => ((disablePeriod - n) * 1000)),
      tap(n => {
        if (n == 0) {
          this.isInDisablePeriod = false;
        }
      }),
      takeWhile(n => n >= 0),
    );

    this.resendTimeRemaining$.subscribe(n => {
      if (n == 0) {
        this.isInDisablePeriod = false;
      }
    })

    this.mfaService.sendMFACode(model).subscribe({
      next: (data) => {
        this.isReSending = false;
        if (data.result) {
          this.isCodeSent = true;
        }
        else {
          this.errorMessage = data.errorMessage && data.errorMessage != "" ? data.errorMessage : this.resourceStrings['Aon.MFA.SystemErrorMessage'];
        }
      },
      error: (error) => {
        this.errorMessage = this.resourceStrings['Aon.MFA.SystemErrorMessage'];
        this.isReSending = false;
      }
    });
  }

  verifyCode(): void {
    if (this.checkInputfieldisBlank(this.otpCode[this.selectedOption])) {
      this.isOTPFieldBlank = true;
    } else {
      this.isVerifying = true;
      this.errorMessage = '';
      this.isOTPFieldBlank = false;
      const model = new OktaInfoModel();

      model.option = this.selectedOption;
      model.otpCode = this.otpCode[this.selectedOption]; //Read otp from textbox
      model.inLoginProcess = this.inLoginProcess;
      model.step = this.step;
      if (this.step == MFAStep.EnterCodeForAddNewEmail) {
        model.userEmail = this.selectedEmail;
        model.useSameEmailForMarketingConsent = this.useSameEmailForMarketingCommunicationChecked;
      }
      if (this.step == MFAStep.EnterCodeForAddNewPhone) {
        model.countryCode = this.selectedMobileCountryCode;
        model.countryCodeName = this.selectedMobileCountryCodeName;
        model.phoneNumber = this.selectedMobile;
      }
      if (!model.inLoginProcess) {
        model.employeeRecordID = this.employeeRecordID;
      }
      this.mfaService.verifyMFACode(model)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            if (data && this.inLoginProcess && data.access_token) {
              sessionStorage.setItem(SessionStorageKey.MfaTempUser, JSON.stringify(data));
              this.authenticationService.startRefreshTokenTimer();
              this.isVerifying = false;
              this.isCodeSent = true;
              if (this.step == MFAStep.EnterCodeForAddNewEmail && this.enableMFAPhone) {
                this.step = MFAStep.AddNewPhone;
                this.loadMetaData();
              } else {
                this.afterPassedVerify.emit({
                  step: this.step
                });
              }
            } else if (data && !this.inLoginProcess && data?.result) {
              this.isVerifying = false;
              this.isCodeSent = true;
              if (this.step == MFAStep.EnterCodeForAddNewEmail) {
                if (this.enableMFAPhone) {
                  this.step = MFAStep.AddNewPhone;
                  this.loadMetaData();
                } else {
                  this.finishedAddEmailAndPhone.emit({
                    step: this.step,
                    useSameEmailForMarketingCommunicationChecked: this.useSameEmailForMarketingCommunicationChecked
                  });
                }
              } else if (this.step == MFAStep.EnterCodeForAddNewPhone || this.step == MFAStep.EnterCode) {
                this.finishedAddEmailAndPhone.emit({
                  step: this.step,
                  useSameEmailForMarketingCommunicationChecked: this.useSameEmailForMarketingCommunicationChecked
                });
              }
            } else if (data && data.errorMessage) {
              this.isVerifying = false;
              this.otpCode[this.selectedOption] = '';
              this.errorMessage = data.errorMessage;
            } else {
              this.isVerifying = false;
              this.otpCode[this.selectedOption] = '';
              this.errorMessage = this.resourceStrings['Aon.MFA.Verification.SystemErrorMessage'];
            }

          },
          error: (error) => {
            this.errorMessage = this.resourceStrings['Aon.MFA.Verification.SystemErrorMessage'];
            this.isVerifying = false;
          }
        });
    }
  }

  skipAddNewPhone() {

    if (this.enableMFAEmail && (this.preferenceEmail == MFAPreferenceEmail.NewEmail || (!this.inLoginProcess && !this.isExistingEmail))) {
      if (this.inLoginProcess) {
        this.afterPassedVerify.emit({
          step: this.step
        });
      } else {
        this.finishedAddEmailAndPhone.emit({
          step: this.step,
          useSameEmailForMarketingCommunicationChecked: this.useSameEmailForMarketingCommunicationChecked
        });
      }
    } else {
      if (this.inLoginProcess) {
        if (sessionStorage.getItem(SessionStorageKey.MfaTempUser)) {
          sessionStorage.removeItem(SessionStorageKey.MfaTempUser);
        }
      }
      this.selectedOption = MFAOption.Email;
      this.sendCode('Email', '', MFAStep.EnterCode);
    }
  }

  private clearOutErrorMessages(): void {
    this.errorMessage = "";
    this.isOTPFieldBlank = false;
  }

  cancel(): void {
    this.backToLogIn.emit();
  }

  backToPreviousStep(): void {
    this.clearOutErrorMessages();
    switch (this.step) {
      case MFAStep.SelectEmail:
        if (this.inLoginProcess) {
          this.step = MFAStep.EnterSsnNumber;
        } else {
          this.backToTermsAndConditions.emit();
        }
        break;
      case MFAStep.AddNewEmail:
        this.isPreferenceEmailSelected = false;
        this.step = MFAStep.SelectEmail;
        break;
      case MFAStep.EnterCodeForAddNewEmail:
        if (!this.inLoginProcess && !this.isExistingEmail) {
          this.isPreferenceEmailSelected = false;
          this.step = MFAStep.SelectEmail;
        } else {
          this.step = MFAStep.AddNewEmail;
        }
        break;
      case MFAStep.AddNewPhone:
        if (this.inLoginProcess) {
          if (this.enableMFAEmail) {
            this.isPreferenceEmailSelected = false;
            this.step = MFAStep.SelectEmail;
          } else {
            this.step = MFAStep.EnterSsnNumber;
          }
        } else if (this.enableMFAEmail) {
          this.isPreferenceEmailSelected = false;
          this.step = MFAStep.SelectEmail;
        } else {
          this.backToTermsAndConditions.emit();
        }
        break;
      case MFAStep.EnterCodeForAddNewPhone:
        this.step = MFAStep.AddNewPhone;
        break;
      case MFAStep.SelectOption:
        if (!this.inLoginProcess && !this.uiConfig.uiConfiguration.enableOverrideMFAData) {
          this.backToTermsAndConditions.emit();
        } else if (this.enableOverrideMFAData === true && this.isMFARegistered === false) {
          if (this.enableMFAPhone) {
            this.step = MFAStep.AddNewPhone;
          } else if (this.enableMFAEmail) {
            this.isPreferenceEmailSelected = false;
            this.step = MFAStep.SelectEmail;
          } else {
            this.step = MFAStep.EnterSsnNumber;
          }
        } else if (this.isMFARegistered === true) {
          this.cancel();
        } else {
          this.cancel();
        }
        break;
      case MFAStep.EnterCode:
        if (this.inLoginProcess) {
          this.step = MFAStep.SelectOption;
        } else if (this.enableOverrideMFAData === true && this.isMFARegistered === false) {
          if (this.enableMFAPhone) {
            this.step = MFAStep.AddNewPhone;
          } else if (this.enableMFAEmail) {
            this.isPreferenceEmailSelected = false;
            this.step = MFAStep.SelectEmail;
          }
        }
        break;
    }
  }

  validateLength(isValidateBothTheChannelCode: boolean = false) {
    const maxLength: number = 6;
    if (isValidateBothTheChannelCode) {
      return (this.sentNewChannelCodesTo[MFAOption.Email] && this.otpCode[MFAOption.Email]?.length < maxLength) || (this.sentNewChannelCodesTo[MFAOption.SMS] && this.otpCode[MFAOption.SMS]?.length < maxLength);
    }
    return !this.otpCode[this.selectedOption] || this.otpCode[this.selectedOption]?.length < maxLength;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  displayBtnEmailCode() {
    if (this.selectedOption == MFAOption.SMS && this.mfaOptions.length > 1 && this.mfaOptions.findIndex((item) => item.name == 'Email') >= 0)
      return true;
    else return false;
  }
  displayBtnSMSCode() {
    return this.selectedOption == MFAOption.Email && this.mfaOptions.length > 1 && this.mfaOptions.findIndex((item) => item.name == 'SMS') >= 0;
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  isFieldValid(field: string) {
    if (this.step == MFAStep.AddNewEmail) {
      return !this.mfaAddNewEmail.get(field).valid && this.mfaAddNewEmail.get(field).touched;
    } else if (this.step == MFAStep.AddNewPhone) {
      return !this.mfaAddNewPhone.get(field).valid && this.mfaAddNewPhone.get(field).touched;
    }
  }

  getmfaData() {
    return this.mfaService.getMFAData().pipe(tap((data: any) => {
      if (data) {
        this.userEmail = data.mfaEmail;
        this.userPhone = data.mfaPhone;
        this.userPhoneCode = data.mfaCountryCode_RecordID;
      }
    }));
  }

  sendSsnCode() {
    if (this.checkInputfieldisBlank(this.enterUniqueCode)) {
      this.isUniqueIdentifierFieldBlank = true
    } else {
      this.inputIdentifier = window.btoa(this.enterUniqueCode);
      this.isUniqueIdentifierFieldBlank = false
      this.isUniIdenVerifying = true;
      this.errorMessage = '';
      this.mfaService.validateMfaUniqueIdentifier(this.mfaUniqueIdeIndex, this.inputIdentifier).subscribe({
        next: (result) => {
          if (result) {
            this.isUniIdenVerifying = false;
            this.ismfaRegistering = false;
            this.step = MFAStep.FirstTimeUser;
            if (this.enableMFAEmail) {
              this.isPreferenceEmailSelected = false;
              this.step = MFAStep.SelectEmail;
            } else if (this.enableMFAPhone) {
              this.step = MFAStep.AddNewPhone;
              this.loadMetaData();
            }
          }
          else {
            this.enterUniqueCode = '';
            this.isUniIdenVerifying = false;
            this.errorMessage = this.resourceStrings['AON.MFA.InvalidUniIdentifierMessage'];
          }
        },
        error: (error) => {
          this.enterUniqueCode = '';
          this.isUniIdenVerifying = false;
          this.ismfaRegistering = false;
          this.errorMessage = this.resourceStrings['Aon.MFA.SystemErrorMessage'];
        }
      });
    }
  }

  selectPreferenceEmail(preferenceEmail) {
    this.isPreferenceEmailSelected = true;
    this.preferenceEmail = preferenceEmail;
  }

  checkUniqueLength() {
    return this.mfaUniqueIdeIndex == '1' ? this.enterUniqueCode?.length < 4 : this.enterUniqueCode?.length < 1;
  }

  showBackButton() {
    return this.step == MFAStep.SelectEmail || this.step == MFAStep.AddNewEmail || this.step == MFAStep.EnterCodeForAddNewEmail
      || this.step == MFAStep.AddNewPhone || this.step == MFAStep.EnterCodeForAddNewPhone
      || this.step == MFAStep.SelectOption || this.step == MFAStep.EnterCode;
  }

  isOneMFA() {
    return (this.enableMFAEmail || this.enableMFAPhone) && !(this.enableMFAEmail && this.enableMFAPhone)
  }

  checkInputfieldisBlank(value) {
    if (value === "") {
      return true;
    }
    return false
  }

  useSameEmailForMarketingCommunication() {
    this.useSameEmailForMarketingCommunicationChecked = !this.useSameEmailForMarketingCommunicationChecked;
  }

  setDefaultPreferenceEmail() {
    if (this.workEmail) {
      this.selectPreferenceEmail(MFAPreferenceEmail.WorkEmail);
    } else if (this.personalEmail) {
      this.selectPreferenceEmail(MFAPreferenceEmail.PersonalEmail);
    } else if (this.alternateEmail) {
      this.selectPreferenceEmail(MFAPreferenceEmail.AlternateEmail);
    } else {
      this.selectPreferenceEmail(MFAPreferenceEmail.NewEmail);
    }
  }

  getSelectedPreferenceEmail() {
    switch (this.preferenceEmail) {
      case MFAPreferenceEmail.WorkEmail:
        return this.workEmailForRegistration;
      case MFAPreferenceEmail.PersonalEmail:
        return this.homeEmailForRegistration;
      case MFAPreferenceEmail.AlternateEmail:
        return this.alternateEmailForRegistration;
      default:
        return "";
    }
  }
}
