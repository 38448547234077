import { Injectable, Inject } from '@angular/core';
import { ContentItemModel } from '../../shared/models/contentItem.model';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SaveResult } from '../../shared/models/saveResult.model';
import { BootstrapRequestContext } from 'tbs-typings';
import { LanguageSelectionData } from '../../shared/models/languageSelectionData.model';
import { HttpClient } from '@angular/common/http';
import { MediaFileModel } from 'src/app/shared/models/mediaFile.model';
import { Observable, Subject } from 'rxjs';
import { CTAModel } from 'src/app/shared/models/callToAction.model';
import { MediaFileUploadModel } from 'src/app/shared/models/mediaFileUpload.model';
@Injectable({
  providedIn: 'root'
})
export class SelfServiceService {
  onPopulationChange: Subject<string> = new Subject();
  //#region Properties
  modalOption: NgbModalOptions = {};
  curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
  apiBaseUrl: string;
  //#endregion
  constructor(
    private readonly modalService: NgbModal,
    private readonly http: HttpClient,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }

  openModal(content: any, titleID, size): NgbModalRef {
    this.modalOption.backdrop = 'static';
    this.modalOption.size = size;
    this.modalOption.keyboard = true;
    this.modalOption.ariaLabelledBy = 'app-modal-anchor';
    this.modalOption.container = '#app-modal-anchor';
    this.modalOption.modalDialogClass = 'content-editor-modal';
    let modalTitleId = titleID;
    this.modalOption.ariaLabelledBy = modalTitleId;
    return this.modalService.open(content, this.modalOption);
  }
  public getStagingTasks() {
    let requestUrl = this.apiBaseUrl + 'stagingtasks';
    return this.http.get(requestUrl);
  }

  addContentItem(contentItem: ContentItemModel, path: string) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/' + this.bootstrapRequestContext.siteName + '/contentitem/add?path=' + path;
    return this.http.post<SaveResult>(requestUrl, contentItem);
  }

  updateContentItem(contentItem: ContentItemModel) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/' + this.bootstrapRequestContext.siteName + '/contentitem/update';
    return this.http.post<SaveResult>(requestUrl, contentItem);
  }

  deleteContentItem(contentItem: ContentItemModel) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/contentitem/' + contentItem.system.nodeGUID;
    return this.http.delete<SaveResult>(requestUrl);
  }

  getAllCTAs(culture: string, siteName: string, parentPath: string): Observable<CTAModel.CallToActionFields[]> {
    const url = `${this.apiBaseUrl + culture + '/' + siteName + '/'}CTAs?parentPath=${parentPath}`;
    return this.http.get<CTAModel.CallToActionFields[]>(url);
  }
  //Parent path .e.g Home/Hero/
  getMediaFiles(culture: string, parentPath: string) {
    const url = `${this.apiBaseUrl + culture + '/'}MediaFiles?parentPath=${parentPath}`;
    return this.http.get<MediaFileModel[]>(url);
  }

  uploadMediaFiles(mediaFiles: MediaFileUploadModel[]) {
    const requestUrl = this.apiBaseUrl + 'MediaFiles/upload';
    return this.http.post<SaveResult>(requestUrl, mediaFiles);
  }

  addCTA(cta: CTAModel.CallToActionFields, rootPath: string) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/' + this.bootstrapRequestContext.siteName + '/cta/add?path=' + rootPath;
    return this.http.post<SaveResult>(requestUrl, cta);
  }

  updateCTA(cta: CTAModel.CallToActionFields, isFromExternalInstance: boolean) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/' + this.bootstrapRequestContext.siteName + '/cta/update?isFromExternalInstance=' + isFromExternalInstance;
    return this.http.post<SaveResult>(requestUrl, cta);
  }

  deleteCTA(nodeGUID: string) {
    const languageCode = this.curLanguageCode.cultureCode;
    const requestUrl = this.apiBaseUrl + languageCode + '/' + this.bootstrapRequestContext.siteName + '/cta/' + nodeGUID;
    return this.http.delete<SaveResult>(requestUrl);
  }

  synchronizeTasks(taskIds: string[]) {
    const requestUrl = this.apiBaseUrl + 'synchronizetasks';
    return this.http.post<SaveResult>(requestUrl, taskIds);
  }
}
