@if (dependantsData) {
  <div [ngClass]="displayInBlock ? '' : 'align-items-center'">
    @if (currentProduct == null) {
      <div>
        @if (!whosCoveredLabel) {
          <p class="strong m-b-0 d-inline">{{resourceStrings['Aon.Dependents.Header']}}</p>
        }
        @if (whosCoveredLabel) {
          <p class="strong m-b-0 pdf-allow-apostrophe">
          {{resourceStrings['Aon.BenefitDetails.CoveredTitle']}}</p>
        }
        @for (dependant of dependantsData; track dependant; let islast = $last) {
          <span class="m-b-0">{{dependant.firstName + ' ' + dependant.lastName + ' (' + dependant.relationshipType_Name + ')'}}@if (dependant.futureDeletedDate) {({{resourceStrings['Aon.Dependants.FutureExpire']}}&nbsp;{{dependant.futureDeletedDate | customdate}})}@if (!islast) {,&nbsp;}</span>
        }
      </div>
    } @else {
      @if (currentProduct !== null && currentProduct.whosCoveredMode != 3 && currentProduct.whosCoveredMode != null) {
        @if (!whosCoveredLabel) {
          <p class="strong m-b-0">{{resourceStrings['Aon.Dependents.Header']}}</p>
        }
        @if (whosCoveredLabel && !displayInBlock && (!product?.isNoCoverage || product?.eoiInformation)) {
          <p class="strong m-b-0 pdf-allow-apostrophe"
            >
          {{resourceStrings['Aon.BenefitDetails.CoveredTitle']}}</p>
        }
        @if (whosCoveredLabel && displayInBlock && dependantsData?.length >0 && (!product?.isNoCoverage || product?.eoiInformation)) {
          <label
            [innerHTML]="resourceStrings['Aon.BenefitDetails.CoveredTitle'] | safeHtml"
          class="pdf-allow-apostrophe"></label>
        }
        @if ((dependantsData?.length >0 && (!product?.isNoCoverage || product?.eoiInformation))) {
          <ng-container class="m-t-20">
              @if (currentProduct.whosCoveredMode == 1) {
              <p class="m-b-0">
                  @for (dependant of dependantsData; track dependant; let islast = $last) {{{dependant.firstName + ' ' + dependant.lastName + ' (' + dependant.relationshipType_Name + ')'}}@if (dependant.futureDeletedDate) {({{resourceStrings['Aon.Dependants.FutureExpire']}}&nbsp;{{dependant.futureDeletedDate | customdate}})}@if (!islast) {,&nbsp;}}
              </p>
              }
              @if (currentProduct.whosCoveredMode == 2 && product.benefitPrinciple) {
              <span class="lg-para" [innerHTML]="product.benefitPrinciple | safeHtml"></span>
              } @else if(currentProduct.whosCoveredMode == 2 && product.benefitPrinciples?.length > 0) {
              <span class="lg-para">
                  @for(benefitPrinciple of product?.benefitPrinciples; track $index; let lastIndex = $last) {
                  {{benefitPrinciple?.trim() | striphtml}}@if (!lastIndex) {,}
                  }
              </span>
              }
          </ng-container>
        }
      }
    }
  </div>
}
