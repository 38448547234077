export class HttpError {
  static readonly BadRequest = 400;
  static readonly Unauthorized = 401;
  static readonly Forbidden = 403;
  static readonly NotFound = 404;
  static readonly TimeOut = 408;
  static readonly Conflict = 409;
  static readonly TooManyRequest = 429;
  static readonly InternalServerError = 500;
}

export class SessionStorageKey {
  //Keys for Payment
  static readonly ReadyToCheckoutPurchases = "ReadyToCheckoutPurchases";
  static readonly RemediationFailedTransaction = "RemediationFailedTransaction";
  static readonly PaymentConfirmData = "PaymentConfirmData";
  static readonly StartPaymentResult = "StartPaymentResult";
  static readonly PaymentProviderPaymentTypeRecordID = "PaymentProviderPaymentTypeRecordID";
  static readonly PaymentFailedAlert = "PaymentFailedAlert";
  static readonly PaymentAutoRenewalAlert = "PaymentAutoRenewalAlert";
  static readonly FollowUpItems = "FollowUpItems";
  static readonly ActiveEvent = "ActiveEvent";
  static readonly AonSettings = "AonSettings";
  static readonly SaveCardPaymentRequest = "SaveCardPaymentRequest";
  static readonly EnabledRolesForUiStrings = "EnabledRolesForUiStrings";
  static readonly RolesRoutingPathsForUiStrings = "RolesRoutingPathsForUiStrings";
  // static SaveCardPaymentResult = "SaveCardPaymentResult";
  static readonly ClaimItems = "ClaimItems";
  static readonly HasMismatchedParticipants = "HasMismatchedParticipants";
  static readonly MfaTempUser = "MfaTempUser";
  static readonly TaxCode = "taxCode";
  static readonly SsoClientList = "SsoClientList";
  static readonly InTwoWaySSOProcess = "InTwoWaySSOProcess";
  static readonly BenefitFlowObject = "BenefitFlowObject";
  static readonly CachedBenefitReviewData = "CachedBenefitReviewData";
  static readonly ClientContentPageCallToActions = "ccpcta";
  static readonly LifeStyleReasonAdditionalData = "LSRAD";
  static readonly PreviousPageInfo = "PreviousPageInfo";
  static readonly isInsideAndroidApp = "isInsideAndroidApp";
  static readonly CallbackObject = "CallbackObject";
  static readonly SsoInteractionIncomplete = "SsoInteractionIncomplete";
  static readonly ReferrerPathForTheQuoteFlow = "RPFQF";
  static readonly isSableProcessFirstStep = "ISPFS";
  static readonly IsFromMobileApp = "IsFromMobileApp";
  static readonly IsFromAndroidApp = "IsFromAndroidApp";
  static readonly IsExpressLoginInFlow = "IsExpressLoginInFlow";
  static readonly TimeWarpDate = "TimeWarpDate";
  static readonly GroupTermItems = "groupTermItems";
  static readonly PasswordExpiredUser = "PasswordExpiredUser";
  static readonly ClaimSelectedCurrency = "ClaimSelectedCurrency";
  static readonly LifestyleReasons = "LifestyleReasons";
  static readonly ActDelegateEmployee = "ActDelegateEmployee"; 
  static readonly IsSelfServiceSite = "IsSelfServiceSite";
}

export class ContentItemKey {
  static readonly MyBenefitsNeedHelp = "MyBenefits.NeedHelp";
  static readonly MyBenefitsClaims = "MyBenefits.Claims";
  static readonly MyBenefitsAonBrokerLicenseInfo = "MyBenefits.AonBrokerLicenseInfo";
  static readonly ProductContactInfo = "Product.ContactInfo";
  static readonly ClaimContactInfo = "Claims.ContactInfo";
  static readonly ConfirmationStatementHeader = "Confirmation.Statement.Header";
  static readonly ConfirmationStatementFooter = "Confirmation.Statement.Footer";
  static readonly ConfirmationStatementContent = "BenefitConfirmContent";
}

export class EmployeeEventStatus {
  static readonly Pending = 1;
  static readonly Submitted = 2;
  static readonly Approved = 3;
  static readonly Declined = 4;
}

export class KenticoPagePath {
  static readonly Products = "/products";
}

export class Constants {
  static readonly True = "True";
  static readonly False = "False";
  static readonly GuidEmpty = "00000000-0000-0000-0000-000000000000";
  static readonly GuidAll = "11111111-1111-1111-1111-111111111111";
  static readonly MaxDate = new Date("9999-12-31");
}

export class CallToActionType {
  static readonly QuoteAction = "QuoteAction";
  static readonly RedirectAction = "RedirectAction";
}

export class QuoteSummaryTemplate {
  static readonly SableView = 0;
  static readonly Standard = 1;
}

export class ThailandPaymentEnabled {
  static readonly None = 0;
  static readonly Demo = 1;
  static readonly Production = 2;
}

export class ErrorOptions {
  static readonly AbortNewPurchase = "ABORT_NEW_PURCHASE";
  static readonly ReplaceExistingPurchase = "REPLACE_EXISTING_PURCHASE";
  static readonly ResumeExistingPurchase = "RESUME_EXISTING_PURCHASE";
  static readonly Cancel = "CANCEL";
  static readonly ExitOnBack = "EXIT_ON_BACK";
}

export class ProductTypes {
  static readonly Pet = "PET";
  static readonly Home = "HOME";
  static readonly Auto = "AUTO";
  static readonly Caregiver = "CAREGIVER";
  static readonly Life = "LIFE";
}

export class QuoteDetailsDisplay {
  static readonly LinkOnly = 0;
  static readonly SummaryOnly = 1;
  static readonly Both = 2;
  static readonly None = 3;
}

export class CoverageSelectionTemplate {
  static readonly Default = 0;
  static readonly Standard = 1;
  static readonly USAdhoc = 2;
}

export class ReviewPurchaseTemplate {
  static readonly Default = 0;
  static readonly Standard = 1;
  static readonly USAdhoc = 2;
}

export class PaymentCompleteTemplate {
  static readonly Default = 0;
  static readonly Standard = 1;
  static readonly USAdhoc = 2;
}

export class EmployeeRelationshipType {
  static readonly Employee = 0;
  static readonly Dependant = 1;
  static readonly Beneficiary = 2;
  static readonly Trust = 3;
  static readonly Estate = 4;
  static readonly Organization = 5;
}

export class BeneficiaryType {
  static readonly NonDesignated = 0;
  static readonly Primary = 1;
  static readonly Contingent = 2;
}

export class PageStyleMode {
  static readonly AlmostDone = 1;
  static readonly PersonalInfo = 2;
  static readonly DepInfo = 3;
}

export class ProductQuoteSummaryTemplate {
  static readonly SableView = 1;
  static readonly Standard = 2;
  static readonly Both = 3;
}

export class LookupBasicInputTypeRecordID {
  static readonly Radio = 0;
  static readonly Toggle = 1;
}

export class EmployeeRelationshipTypeNames {
  static readonly Employee = 0; //self
  static readonly Spouse = 1;
  static readonly DomesticPartner = 2;
  static readonly Child = 3;
  static readonly Other = 4; //parent
  static readonly Unborn = 5;
  static readonly Parents = 6;
  static readonly ParentsInLaw = 7;
}

export class OtpVerificationOption {
  static readonly Email = 'Email';
  static readonly SMS = 'Sms';
}

export class RoutingPathsSpecailCheck {
  static readonly PathsWithID = [
    '/product/{id}',
    '/product/{id}/details/{id}',
    '/product/{id}/coverages',
    '/product/{id}/article/{key}',
    '/quote/{id}/details',
    '/quote/{id}/terms',
    '/quote/{id}/coverage',
    '/quote/{id}/review',
    '/quote/{id}/itemlist',
    '/quote/{id}/checkout',
    '/quote/{id}/complate',
    '/quote/{id}/callagent',
    '/quote/{id}/selection'
  ];
  static readonly StartWithQuote = '/quote/';
  static readonly StartWithProduct = '/product/';
  static readonly StartWithCategory = '/category/';
  static readonly StartWithClaim = '/claim/';
  static readonly StartWithArticle = '/article/';
  static readonly StartWithMyBenefits = '/mybenefits';
  static readonly Id = '{id}';
  static readonly Article = '/article';
  static readonly CommonArticle = '{common-article}';
  static readonly ProductArticleKey = '/article/{key}';
  static readonly Details = '/details';
  static readonly DetailsId = '/details/{id}';
  static readonly Header = '{header}';
  static readonly Footer = '{footer}';
}

export class SummaryUIKey {
  static readonly AboutYourPetSummary = "AboutYourPetSummary";
  static readonly PetHealthHistorySummary = "PetHealthHistorySummary";
}

export class ExpressLoginSteps {
  static readonly ProvideEmailAddress = "ProvideEmailAddress";
  static readonly GenerateOTP = "GenerateOTP";
}

export class RegularExpression {
  static readonly Email = "^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$";
  static readonly EmailWithMask = "^[a-zA-Z0-9.*'_%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$";
  static readonly HomeMobile = "^[0-9]*$";
}

export enum PaymentProviderCode {
  ZETA = "ZETA",
  MY2C2P = "2C2P",
  AON = "AON",
  INGENICO = "INGENICO"
}

export enum PaymentProviderPaymentTypeCode {
  AON_PAYROLLDEDUCTION = "PD",
  INGENICO_VISA = "1",
  INGENICO_AMEX = "2",
  INGENICO_MASTERCARD = "3",
  INGENICO_UNIONPAY = "56",
  INGENICO_APPLE = "302",
  INGENICO_GOOGLE = "320",
  INGENICO_ALIPAY = "861",
  INGENICO_WECHAT = "863",
  INGENICO_ACH = "730",
  INGENICO_DISCOVER = "128",
  MY2C2P_MASTERCARD = "Mastercard",
  MY2C2P_VISA = "Visa",
  ZETA_CASH = "PG",
  ZETA_ZETA = "ZETA",
  ZETA_POINTS = "FLEX"
}

export enum PaymentProviderPaymentTypeName {
  AON_PAYROLLDEDUCTION = "PayrollDeduction",
  INGENICO_VISA = "VISA",
  INGENICO_AMEX = "AMEX",
  INGENICO_MASTERCARD = "MASTERCARD",
  INGENICO_UNIONPAY = "UNIONPAY",
  INGENICO_APPLE = "APPLE",
  INGENICO_GOOGLE = "GOOGLE",
  INGENICO_ALIPAY = "ALIPAY",
  INGENICO_WECHAT = "WECHAT",
  INGENICO_ACH = "ACH",
  INGENICO_DISCOVER = "DISCOVER",
  MY2C2P_MASTERCARD = "MASTERCARD",
  MY2C2P_VISA = "VISA",
  ZETA_CASH = "Cash",
  ZETA_ZETA = "Zeta",
  ZETA_POINTS = "Points"
}

export class SsoTpRequestItem {
  static readonly SSOClientID = "SSOClientID";
  static readonly SSOPlanID = "SSOPlanID";
  static readonly SSOCoverageID = "SSOCoverageID";
  static readonly SSOWindowStyle = "SSOWindowStyle";
  static readonly SSOContentLink = "SSOContentLink";
  static readonly SSOButtonLink = "SSOButtonLink";
  static readonly BenefitRecordID = "BenefitRecordID";
  static readonly EmployeeBenefitID = "EmployeeBenefitID";
}
export class LegalContentCategory {
  static readonly OnlineSecurity = "OnlineSecurity";
  static readonly PrivacyPolicy = "PrivacyPolicy";
  static readonly TermsOfUse = "TermsOfUse";
  static readonly ParticipantConsent = "ParticipantConsent";
  static readonly BeneficiaryDisclaimer = "BeneficiaryDisclaimer";
}

export class RouteRootPrefix {
  static readonly Product = "product";
  static readonly Quote = "quote";
  static readonly Profile = "profile";
}
export class ProfileTabFragements {
  static readonly AboutMe = "aboutme";
  static readonly Benefits = "activebenefits";
  static readonly LifeEvents = "lifeevents";
  static readonly SavedQuotes = "savedquote";
  static readonly Account = "account";
  static readonly DependentsAndBeneficiaries = "dependentsandbeneficiaries";
  static readonly Documents = "documents";
  static readonly Payment = "payment";
}

export class PositionOfExploreMoreBenefits {
  static readonly AboveFAQs = 1;
  static readonly UnderFAQs = 2;
}

export class FormDecoratorType {
  static readonly PreText = 1;
  static readonly PosText = 2;
  static readonly Info = 3;
}

export class ComponentName {
  static readonly Profile = "Profile";
  static readonly AlmostDone = "AlmostDone";
  static readonly BasicInfo = "BasicInfo";
}


export class GenderTypes {
  static readonly Melded = 1;
  static readonly Male = 2;
  static readonly Female = 3;
}

export class SableSummaryType {
  static readonly Step = 'step';
  static readonly Activity = 'activity';
  static readonly PurchaseGroup = 'purchase-group';
  static readonly EntityGroup = 'entity-group';
}

export class PasswordValidationMessage {
  static readonly NeedMeetPasswordRequirement = 'NeedMeetPasswordRequirement';
  static readonly ContainsUserName = 'ContainsUserName';
  static readonly ReuseCurPassword = 'ReuseCurPassword';
  static readonly MayReusePassword = 'MayReusePassword';
  static readonly ContainsBlockedKeyword = 'ContainsBlockedKeyword';
  static readonly ContainsSequentialkeyword = 'ContainsSequentialkeyword';
  static readonly ContainsRepetitiveChar = 'ContainsRepetitiveChar';
}
export class PasswordCharLength {
  static readonly LengthFourteen = ".{14,}";
  static readonly LengthTwelve = ".{12,}"
  static readonly LengthTen = ".{10,}"
  static readonly LengthEight = ".{8,}"
}

export class eSigningStatus {
  static readonly Unconfirmed = 'unconfirmed';
}
export class LocalStorageKey {
 
  static readonly ProfileDocument = "ProfileDocument";
  static readonly ProfileDocumentAccessDate = "ProfileDocumentAccessDate";

}
export class MFAResendDisablePeriod {
  static readonly Email = 30;
  static readonly SMS = 30;
}
export class ServicNow {
  static readonly FieleExt = "pdf|jpg|docx|png|mp4|doc|msg|xls|xlsx";
  static readonly maxSize = 10;
  static readonly error ="error"
}
export class DelegateAccess {
  static readonly SubmitClaim = 1;
  static readonly ViewClaimHistory = 2;
  static readonly ViewSpendingAccountBalance = 3;
  static readonly PrintClaimHistory = 4;
}
export enum EmailType 
{  
  WorkEmail = 1,
  AlternateEmail = 2,
  HomeEmail = 3
}
export enum PaymentProviderPaymentTypeID{ 
  ZeroPayment = "7FB4F05D-A693-4A27-A903-C5969476775B"  
}

export class WebSiteType {
  static readonly ExternalEditorSite = 'ExternalEditorSite';
  static readonly InternalEditorSite = 'InternalEditorSite';
}
export class GrantType {
  static readonly Password = 'password';
  static readonly OneTimePassword = 'onetimepassword';
  static readonly ExternalEditor = 'ExternalEditor';
  static readonly InternalEditor = 'InternalEditor';
}

export class SelfService {
  static get UserInPopulationAdornment() {
    return {
      Prefix: 'UserInPopulation("',
      Suffix: '")',
      PatternToFindMatch: /UserInPopulation\(([^)]+)\)/g,
      PatternToReplace: /^UserInPopulation\("(.*)"\)$/
    };
  }
  static readonly HomeHeroPath='Home/Hero/';
  static readonly HomePath='/Home';
}
