import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpError } from '../../shared/models/constants'
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([HttpError.Unauthorized, HttpError.Forbidden].includes(err.status) && this.authenticationService.isLogin) {
        // auto logout if 401 or 403 response returned from api
        this.authenticationService.logout().subscribe();
      }
      if ([HttpError.Unauthorized].includes(err.status) && !this.authenticationService.isLogin) {
        // auto logout if 401 or 403 response returned from api
        //sessionStorage.removeItem("currentGuest");

      }
      //  if (err.status == HttpError.NotFound) {
      //   window.location.href = '/';
      //  }
      if (err.status == HttpError.InternalServerError) {

      }
      console.log(err);
      return throwError(err);
    }))
  }
}
