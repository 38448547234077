import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({ providedIn: 'root' })
export class UrlGuard {
  baseUrl: string;
  constructor(
    private readonly router: Router,
    private readonly helperService: HelperService
  ) {
    this.baseUrl = this.helperService.getBaseUrl();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.indexOf('client=') >= 0 && route.queryParams.client) {
      const queryStrings = state.url.replace("/?", "?").replace("client=" + route.queryParams.client, "");
      window.location.href = '/' + route.queryParams.client + queryStrings;
      return false;
    }

    return true;
  }
}

