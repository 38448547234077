<div class="benefit-save-alert-modal">
  <div class="modal-header">
    <h1 class="h3 modal-title" id="leave-flow-alert-modal-title"
    [innerHTML]="resourceStrings['Aon.Alert.Title'] | safeHtml"></h1>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
      (click)="close()">
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  @if (removePurchaseErrorMessage) {
    <div class="mb-3 alert contextual error">
      <ng-container>
        <p [innerHTML]="resourceStrings['Aon.Common.Error'] | safeHtml"></p>
      </ng-container>
    </div>
  }
  <div class="modal-body p-b-20">
    <div [innerHTML]="resourceStrings['Aon.Alert.Description'] | safeHtml">
    </div>
  </div>
  <div class="button-sleeve">
    <button (click)="save()" class="prominent" [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
    <button (click)="close()" class="ghost" [innerHTML]="resourceStrings['System.Common.Cancel'] | safeHtml"></button>
  </div>
</div>