import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent extends TBSBaseComponent implements OnInit, OnDestroy {

  constructor(private readonly injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Error.PageNotFound',
      'Aon.Error.Error404',
      'Aon.Error.PageNotFoundDetail',
      'Aon.Error.ErrorId'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
        this.logPageView();
      });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

}
