import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';
import { BootstrapRequestContext } from 'tbs-typings';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { JwtHelper } from '../../core/guards/jwt.helper';
import { CommonService } from '../../core/services';
import { NotificationService } from '../../core/services/notification.service';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { HomeService } from '../../modules/home/home.service';
import { LoginComponent } from '../login';
import { RegularExpression, SessionStorageKey } from '../models/constants';
import { HelperService } from '../services/helper.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../modules/profile/profile.service';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'app-express-login',
  templateUrl: './express-login.component.html'
})
export class ExpressLoginComponent extends LoginComponent implements OnInit, OnDestroy {
  @Input() curExpressLoginStep: string;
  @Input() hideCloseButton: boolean;
  @Output() goToLoginScreen = new EventEmitter<string>();
  @Output() closeExpressLoginCallback = new EventEmitter<void>();
  @Output() expressLoginSuccessCallback = new EventEmitter<void>();
  @Output() afterSumbitCallback = new EventEmitter<void>();
  uiConfig: UIConfigurationType;
  privacyPolicyUrl: string;

  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected commonService: CommonService,
    protected jwtHelper: JwtHelper,
    public helperService: HelperService,
    public globalObjectsService: GlobalObjectsService,
    protected homeService: HomeService,
    protected notificationService: NotificationService,
    protected uiconfigrationService: UiconfigrationService,
    protected mfaService: MFAService,
    protected profileService: ProfileService,
    public activeModal: NgbActiveModal,
    private readonly elementRef: ElementRef,
    protected ngxService: NgxUiLoaderService,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
    @Inject(AON_LOCALE) protected AON_LOCALE,
    public injector: Injector
  ) {
    super(
      formBuilder,
      route,
      router,
      authenticationService,
      commonService,
      jwtHelper,
      helperService,
      globalObjectsService,
      homeService,
      uiconfigrationService,
      notificationService,
      mfaService,
      profileService,
      ngxService,
      bootstrapRequestContext,
      AON_LOCALE,
      injector);
    this.addResourceStringKeys([
      'Aon.Login.LoginText',
      'Aon.Login.ContinueToCheckout',
      'Aon.Login.GuestLoginInfo',
      'Aon.Login.GuestLoginEnterEmailToReceiveOTP',
      'Aon.Login.PrivacyPolicyReadClaim',
      'Aon.Login.PrivacyPolicyReadClaimBeforeLink',
      'Aon.Login.PrivacyPolicyReadClaimAfterLink',
      'Aon.Registration.PrivacyPolicy',
      'Aon.Login.MarketingSelection',
      'Aon.Account.Email',
      'Aon.Account.EmailIsRequired',
      'Aon.Account.InvalidEmail',
      'Aon.Login.GeneratePassword',
      'Aon.Login.VerifyEmail',
      'Aon.Login.OneTimePassword',
      'Aon.Login.ResendCode',
      'Aon.Login.InvalidCode',
      'Aon.Login.HaveAccount',
      'Aon.Login.CodeHasBeenSentSuccessfully',
      'Aon.Login.CodeExpirationSuffix',
      'Aon.Login.ContactHelpDesk',
      'Aon.Login.PasswordVisibilityControl',
      'Aon.Common.TooManyRequests',
      'Aon.Common.Edit',
      'System.Common.Error'
    ]);
  }

  ngOnInit(): void {
    this.isExpressLogin = true;
    this.isAccountExists = false;
    this.curExpressLoginStep = this.localExpressLoginSteps.ProvideEmailAddress;
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);

      });
    this.privacyPolicyUrl = this.helperService.getPrivacyPolicyUrl();
    this.loginForm.controls["username"].addValidators([Validators.email, Validators.pattern(RegularExpression.Email)]);
    this.analyticsService.trackCustomEvent({ 'event': 'express-login-poppup' });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
  }

  goToLogin() {
    this.submitted = false;
    this.error = "";
    this.isPrivacyPolicyLinkClicked = false;
    this.isPrivacyPolicyAccepted = false;
    this.showPrivacyPolicySection = true;
    this.showMarketingPreferenceSection = true;
    this.isExpressLogin = false;
    this.goToLoginScreen.emit("");
  }

  afterSumbit(): void {
    sessionStorage.setItem(SessionStorageKey.IsExpressLoginInFlow, "true");
    this.afterSumbitCallback.emit();
  }

  onClose(): void {
    this.closeExpressLoginCallback.emit();
  }

  completeValidation(): void {
    this.authenticationService.expressLoginComplete.next(true);
  }

  linkClick(): void {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.isExpressLogin = false;
    this.loginForm.controls["username"].removeValidators([Validators.email, Validators.pattern(RegularExpression.Email)]);
    this.signalUnsubscribe();
  }
}
