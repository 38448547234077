import { Component, EventEmitter, Input, OnInit, OnChanges, Output, Injector, OnDestroy, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/models/product.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { interval, take } from 'rxjs';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Component({
  selector: 'app-masthead-benefits',
  templateUrl: './masthead-benefits.component.html'
})
export class MastheadBenefitsComponent extends TBSBaseComponent implements OnInit, OnDestroy, OnChanges {
  campaignKey: string;
  @Output('hideProductPopUp') hideProductPopUp: EventEmitter<any> = new EventEmitter();
  @Output('hideMobileNavBar') hideMobileNavBar: EventEmitter<any> = new EventEmitter();
  @Output() collapseBenefitMenu: EventEmitter<any> = new EventEmitter();
  @Input() benefits: Array<{
    categoryName: string,
    icon: string,
    icon2: string,
    groupBenefits: Product[];
  }>;
  @Input() resourcestrings;
  @Input() closeBenefit;
  baseUrl: string;
  categoryToggle: boolean = false;
  showAllForCategory: string;
  active = 0;
  categoryIndex: number;
  categorySection = [];

  constructor(
    private readonly injector: Injector,
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly globalojectService: GlobalObjectsService,
    private readonly elementRef: ElementRef
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }
  ngOnChanges() {

    if (this.closeBenefit) {
      this.showAllForCategory = null;
    }
  }
  ngOnInit(): void {
    this.campaignKey = this.helperService.getCampaignKey();
    this.baseUrl = this.helperService.getBaseUrl();
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  public goToProduct(product): void {
    this.hideProductPopUp.emit();
    this.hideMobileNavBar.emit();
    this.router.navigate([this.baseUrl + '/product', product.line_GUID], { queryParams: { campaignKey: this.campaignKey } });

  }

  navigateToProductDetail(lineId: string): void {
    sessionStorage.setItem('IsFromBenefitNavMegaMenu', 'true');
    this.router.navigate([this.baseUrl + '/product/' + lineId + '/detail']);
    this.collapseBenefitMenu.emit();
  }

  showAllCards(categoryName: string, i): void {

    if (this.showAllForCategory == categoryName) {
      this.showAllForCategory = null;
    }
    else {
      this.showAllForCategory = categoryName;
    }

    // for accessability to work properly - focus the next item manually
    interval(0).pipe(take(1)).subscribe((data) => {
      let a: NodeListOf<HTMLElement> = this.elementRef.nativeElement.querySelectorAll('.group-benefit-cards-5');
      a.forEach(item => {
        item.focus()
      })
    })
  }

  showCategory(value) {
    this.categoryToggle = value;
  }

  setCategoryIndex(value) {
    this.categoryToggle = !(this.categoryToggle && this.categoryIndex == value);
    this.categoryIndex = value;
    const categorySections = document.getElementsByName('benefits-category-' + value);
    let el = categorySections[0];
    if (el) {
      setTimeout(() => {
        if (screen.width < 992)
          el.scrollIntoView();
      }, 50);
    }
  }
}
