import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[SpyScrollView]'
})
export class SpyScrollViewDirective {

  constructor(private readonly elRef: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => this.setTabindexForTheScrollView(), 500);
  }

  /**
   * Window resize or if the div has the scroll then add the tabindex to trigger focus
   */
  @HostListener('window:resize', ['$event'])
  public setTabindexForTheScrollView() {
    try {
      let div = this.elRef.nativeElement;
      let vs = (div.scrollHeight > div.clientHeight) || (div.scrollWidth > div.clientWidth);
      if (vs) {
        div.setAttribute("tabindex", "0");
        div.classList.add("focus-visibility");
      } else {
        div.removeAttribute("tabindex");
        div.classList.remove("focus-visibility");
        setTimeout(() => this.setTabindexForTheScrollView(), 1000);
      }
    } catch (error) { }
  }

}
