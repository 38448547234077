import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { EmployeeInfoModel } from 'src/app/modules/profile/models/employeeInfo.model';
import { EmployeeClaimDetailType } from '../../modules/claim/claim.model';
import { CompactEmployeeInformationModel } from '../../modules/profile/models/compactEmployeeInformation.model';
import { Product } from '../../shared/models/product.model';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { EmployeeBenefitsType } from '../models/benefitReview.model';
import { CategoryType } from '../models/categoryType.model';
import { OptionalRelationshipFilter, RelationshipFilter } from '../models/relationship.model';
import { CTAModel } from '../models/callToAction.model';
import { DelegateForEmployeeType } from '../models/actAsDelegateForType.model';
import { Populations } from '../models/populations.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalObjectsService {
  public CTAs: BehaviorSubject<CTAModel.CallToActionFields>;
  public Products: BehaviorSubject<Product[]>;
  public Categories: BehaviorSubject<CategoryType[]>;
  public Populations: Array<Populations>;
  public CategorizationEnabled: BehaviorSubject<boolean>;
  public reloadPurchaseSummary: BehaviorSubject<boolean>;
  public uiConfigs = new BehaviorSubject<UIConfigurationType>(null);
  public reloadShoppingCart: Subject<boolean>;
  public shoppingCartLoaded = new BehaviorSubject<EmployeeBenefitsType>(null);
  public quoteDeleted = new Subject<number>();
  public employeeInfo = new BehaviorSubject<EmployeeInfoModel>(null);
  public compactEmployeeInformation = new BehaviorSubject<CompactEmployeeInformationModel>(null);
  public employeeClaimInfo = new BehaviorSubject<EmployeeClaimDetailType>(null);
  public categoryItemDetails = new BehaviorSubject<any>(null);
  public failedPaymentSubject = new BehaviorSubject<boolean>(false);
  public serviceNowLoaded = new BehaviorSubject<boolean>(false);
  public hasClaimsDataForWidget = new BehaviorSubject<boolean>(null);
  public hasTRSDataForWidget = new BehaviorSubject<boolean>(null);
  public delegateEmployeeUpdated = new BehaviorSubject<DelegateForEmployeeType>(null);
  public delegateEmployeeSelfSelected = new BehaviorSubject<boolean>(null);
  public additionalContextData: any;
  public InlineParticipants: any;
  public HideFinishLaterButton: any;
  public PrimaryDependant_RecordID: any;
  public isAddAnotherParticipant: any;
  public employeeBenefitsByScheme: any;
  public goToInstruction: any;
  public returnURL: string;
  public sableInstance_RecordID: string;
  public newEntityInstance: any;
  public sableActivity_RecordID: string;
  public logoutProfile: boolean = false;
  public hasGracePeriodSubject = new BehaviorSubject<boolean>(false);
  public reloadProfileGracePeriod: Subject<boolean>;
  public showClientLogo = new Subject<boolean>();
  public resetNiceAfterLogout: Subject<boolean>;
  public furtherChange = new Subject<any>();
  public fromEnrolmentSummary: boolean = false;
  public checkHeaderFooterConfig = new BehaviorSubject<boolean>(true);
  public headerFooterConfigRefresh: boolean = true;
  public logoPath = new BehaviorSubject<string>(null);
  public suppressNavigation: boolean = false;
  public suppressBackButton = new BehaviorSubject<boolean>(false);
  public setActiveEventInstanceId: boolean = false;
  public w3ScreenReader: any = {
    pageLoadingAnnouncement: false,
    pageLoadedAnnouncement: false
  }
  public employeePaySlipTypeDataJSON: any;
  public employeePaySlipTypeTaxCode: string;
  public stopBenefitSaveAlertTrigger: boolean = false;
  public benefitConfirmationPageAccess: boolean = false;
  //RelationshipFilter will have 3 parameters: Line_RecordID, BenefitPlan_RecordID and Mode_RecordID.
  //The line and plan record ID will be null on the profile page and we'll just send in a 1 to return all dependant relationships.
  //On the participant review part of the flow we would send just a line record ID(no plan) and 1 to return all dependant relationships.
  //On the plan selection part of flow we would send both line AND selected plan and EITHER 1 for dependant relationships(if the user is adding a dependant)
  //OR 2 for beneficiary relationships(if the user is adding a beneficiary).
  //Note that if you receive a 2 you would pull those relationships(all types including deps) which intersect between the benefitplan benes and the relationships table.
  private _relationshipFilter = new RelationshipFilter();
  public RelationshipFilter = new BehaviorSubject<RelationshipFilter>(this._relationshipFilter);

  public IsStackedLine: boolean;
  public UseAmountForDisplay: boolean;
  public AlmostDoneSableFormData: any
  public sableFromTemplate_RecordID: string;
  public flowInTwoWaySsoProcess: boolean = false;
  public currencies: { [key: string]: string } = {};
  public IsParticipantEdit: boolean;
  constructor() {
    this.Products = new BehaviorSubject<Product[]>([]);
    this.Categories = new BehaviorSubject<CategoryType[]>(null);
    this.CategorizationEnabled = new BehaviorSubject<boolean>(false);
    this.reloadPurchaseSummary = new BehaviorSubject<boolean>(false);
    this.reloadShoppingCart = new Subject<boolean>();
    this.reloadProfileGracePeriod = new Subject<boolean>();
    this.CTAs = new BehaviorSubject<CTAModel.CallToActionFields>(null);
    this.resetNiceAfterLogout = new Subject<boolean>();
  }

  public SetRelationshipFilter(filter: OptionalRelationshipFilter) {
    let result = new RelationshipFilter();
    if (this._relationshipFilter) {
      Object.assign(result, this._relationshipFilter);
    }

    if (filter) {
      Object.assign(result, filter);
    }

    if (this._relationshipFilter.HasDifference(result)) {
      this._relationshipFilter = result;
      this.RelationshipFilter.next(result);
    }
  }
}
