<ng-template #content let-modal>
  <div class="custom-modal time-out">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title"
        [innerHTML]="resourceStrings['Aon.Common.TimeoutTitle'] | safeHtml"></h3>
      <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="restart()">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div>{{resourceStrings['Aon.Common.TimeoutInfoPrefix']}}
        {{count}}{{resourceStrings['Aon.Common.TimeoutInfoSuffix']}}</div>
      <button type="button" class="prominent row m-t-32 m-l-0" (click)="restart()"
        [innerHTML]="resourceStrings['Aon.Common.KeepWorking'] | safeHtml"></button>
    </div>
  </div>
</ng-template>