@if (faqs) {
  <div class="faqs-content">
    @if ((!faqTitle && !faqPage)) {
      <h2 class="faq-header focus-visibility">{{resourcestrings['Aon.Home.FaqsHeader']}}
      </h2>
    }
    @if ((faqTitle && !faqPage)) {
      <h2 class="faq-header focus-visibility">{{faqTitle | striphtml}}</h2>
    }
    <!--changed below h4 to divs for removing heading level accessibility issues-->
    @if ((!faqTitle && faqPage)) {
      <div class="h4 faq-header focus-visibility">{{resourcestrings['Aon.Home.FaqsHeader']}}</div>
    }
    @if ((faqTitle && faqPage)) {
      <div class="h4 faq-header focus-visibility">{{faqTitle | striphtml}}</div>
    }
    <div class="faq-sleeve focus-visibility" WatchDomTree (dom-changed)="domChanged()">
      <div ngbAccordion>
        @for (faq of faqs; track faq) {
          <div [ngbAccordionItem]="'p' + faq.faqID" class="expansion-panel" #accordion="ngbAccordionItem" ngbAccordionItem
            >
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="header-sleeve focus-visibility" (click)="faqclick(faq)">
                <span class="header-text">{{faq.question}}</span>
                <span class="header-controls">
                  @if (!accordion.collapsed) {
                    <i class="fal fa-minus" aria-hidden="true"></i>
                  }
                  @if (accordion.collapsed) {
                    <i class="fal fa-plus" aria-hidden="true"></i>
                  }
                </span>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <p class="expansion-panel-text" [innerHTML]="faq.answer | safeHtml"></p>
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}