import { Component, Injector, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';
import { TBSBaseComponent } from '../models/tbsBaseComponent';

@Component({
  selector: 'aon-tax-modeller-tables-modal',
  templateUrl: './aon-tax-modeller-tables-modal.component.html'
})
export class AonTaxModellerTablesModalComponent extends TBSBaseComponent implements OnInit {

  // Properties
  modellerJson: string = "";
  EmployeeTaxModelTableTypes: Array<any> = [];

  constructor(
    private readonly modalService: NgbActiveModal,
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Tax.Modeller.Tables.Modal.Header',
      'Aon.Tax.Modeller.Tables.Modal.Description'
    ]);
  }

  ngOnInit(): void {
    // Loading RS
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });

    // Parsing the JSON Data
    if (this.modellerJson != "") {
      try {
        let modelerJson = JSON.parse(this.modellerJson);
        if (Array.isArray(modelerJson.EmployeeTaxModelTableTypes)) {
          this.EmployeeTaxModelTableTypes = modelerJson.EmployeeTaxModelTableTypes;
        }
      } catch (error) { console.log(error, '--- ERROR MODELLER JSON PARSING ---') }
    }
  }

  /**
   * Close method to hanle when the keyboard ESC is pressed
   * @param event 
   */
  handlekeyDownEsc(event) {
    this.modalService.close();
    /**
     * To prevent the next key press event auto trigger otherwise the modal will reopen again and it become a loop
     */
    event.stopPropagation()
  }

  /**
   * Dismiss the opened model
   */
  onClose() {
    this.modalService.dismiss();
  }

}
