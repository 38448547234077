import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from 'src/app/shared/models/product.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BenefitSaveAlertComponent } from 'src/app/modules/benefit/benefit-save-alert/benefit-save-alert.component';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from '../../services/uiconfigration.service';

@Component({
  selector: 'app-masthead-products',
  templateUrl: './masthead-products.component.html'
})
export class MastheadProductsComponent extends TBSBaseComponent implements OnInit {
  campaignKey: string;
  @Output('hideProductPopUp') hideProductPopUp: EventEmitter<any> = new EventEmitter();
  @Output('hideMobileNavBar') hideMobileNavBar: EventEmitter<any> = new EventEmitter();
  @Input() products: Product[];
  @Input() resourcestrings;
  baseUrl: string;
  uiConfig: UIConfigurationType;

  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly uiconfigrationService: UiconfigrationService
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }

  ngOnInit(): void {
    this.campaignKey = this.helperService.getCampaignKey();
    this.baseUrl = this.helperService.getBaseUrl();
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();

  }
  public goToProduct(product): void {
    this.hideProductPopUp.emit();
    this.hideMobileNavBar.emit();
    this.analyticsService.trackCustomEvent({
      "event": "masthead-product-goto",
      "product-english-name": product?.gaTag
    });

    if (this.uiConfig.uiConfiguration.showAlertPopup && this.router.url.includes("/quote") && !this.router.url.includes(product.line_GUID)) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        ariaLabelledBy: 'leave-flow-alert-modal-title'
      };
      const modalRef = this.modalService.open(BenefitSaveAlertComponent, ngbModalOptions);
      modalRef.result.then((result) => {
        if (result) {
          this.router.navigate([this.baseUrl + '/product', product.line_GUID], { queryParams: { campaignKey: this.campaignKey } });
        }
      }, (reason) => {
        /*Leave empty or handle reject*/
        /*This is used to avoid the JS error when calling dismiss to close a modal*/
      });
    } else {
      this.router.navigate([this.baseUrl + '/product', product.line_GUID], { queryParams: { campaignKey: this.campaignKey } });
    }

  }
}
