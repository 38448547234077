<div id="aon-tax-modeller-info-modal" role="alertdialog"
  [attr.aria-label]="resourceStrings['Aon.Tax.Modeller.Tables.Modal.Header']"
  (keydown.esc)="handlekeyDownEsc($event)">
  <div class="modal-only">
    <div class="modal-header">
      <h3 class="modal-title" [attr.aria-label]="resourceStrings['Aon.Tax.Modeller.Tables.Modal.Header']"
      [innerHTML]="resourceStrings['Aon.Tax.Modeller.Tables.Modal.Header'] | striphtml"></h3>
      <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="onClose()">
        <span aria-hidden="true"><i class="fal fa-times"></i></span>
      </button>
    </div>
    <p class="m-b-20" [innerHTML]="resourceStrings['Aon.Tax.Modeller.Tables.Modal.Description'] | safeHtml"></p>
    <!-- Build Dynamic Tables -->
    <div class="modal-body" SpyScrollView>
      @for (taxModelTable of EmployeeTaxModelTableTypes; track taxModelTable) {
        <table class="table m-b-32" [ngClass]="taxModelTable?.CssClass != null ? taxModelTable.CssClass : ''">
          @if (taxModelTable.TableCaption?.length > 0) {
            <caption class="c-align-left h6"
            [innerHtml]="taxModelTable.TableCaption | safeHtml"></caption>
          }
          <ng-template let-tag="tag" let-isDescriptiveText="isDescriptiveText" let-itemValue="itemValue"
            let-itemValue2="itemValue2" let-currencyCode="currencyCode" #tableValueSection>
            @if (isDescriptiveText == false) {
              @if (tag === 'th') {
                <th class="col-3 text-break"
                  [innerHTML]="itemValue != null && (itemValue | striphtml) != '' ? (itemValue | striphtml | currency: currencyCode) : ''">
                </th>
                <th class="col-3 text-break"
                  [innerHTML]="itemValue2 != null && (itemValue2 | striphtml) != '' ? (itemValue2 | striphtml | currency: currencyCode) : ''">
                </th>
              }
              @if (tag !== 'th') {
                <td class="col-3 text-break"
                  [innerHTML]="itemValue != null && (itemValue | striphtml) != '' ? (itemValue | striphtml | currency: currencyCode) : ''">
                </td>
                <td class="col-3 text-break"
                  [innerHTML]="itemValue2 != null && (itemValue2 | striphtml) != '' ? (itemValue2 | striphtml | currency: currencyCode) : ''">
                </td>
              }
            }
            @if (isDescriptiveText == true) {
              @if (tag === 'th') {
                <th class="col-3 text-break"
                  [innerHTML]="itemValue != null && itemValue != '' ? (itemValue | safeHtml) : ''">
                </th>
                <th class="col-3 text-break"
                  [innerHTML]="itemValue2 != null && itemValue2 != '' ? (itemValue2 | safeHtml) : ''">
                </th>
              }
              @if (tag !== 'th') {
                <td class="col-3 text-break"
                  [innerHTML]="itemValue != null && itemValue != '' ? (itemValue | safeHtml) : ''">
                </td>
                <td class="col-3 text-break"
                  [innerHTML]="itemValue2 != null && itemValue2 != '' ? (itemValue2 | safeHtml) : ''">
                </td>
              }
            }
          </ng-template>
          @if (taxModelTable.EmployeeTaxModelItemTypes.length > 0) {
            @if (taxModelTable.UseHeader) {
              <thead>
                <tr class="row"
                  [ngClass]="taxModelTable.EmployeeTaxModelItemTypes[0]?.CssClass != null ? taxModelTable.EmployeeTaxModelItemTypes[0].CssClass : ''">
                  <th class="col-6 text-break"
                    [innerHTML]="taxModelTable.EmployeeTaxModelItemTypes[0].ItemCaption != null ? taxModelTable.EmployeeTaxModelItemTypes[0].ItemCaption : '' | safeHtml"
                  id="itemCaptionInHeader"></th>
                                <ng-container *ngTemplateOutlet="tableValueSection; context: {
                                    tag: 'th',
                                    isDescriptiveText: taxModelTable.EmployeeTaxModelItemTypes[0].IsDescriptiveText,
                                    itemValue: taxModelTable.EmployeeTaxModelItemTypes[0].ItemValue,
                                    itemValue2: taxModelTable.EmployeeTaxModelItemTypes[0].ItemValue2,
                                    currencyCode: taxModelTable.EmployeeTaxModelItemTypes[0].CurrencyCode
                                }"></ng-container>
                </tr>
              </thead>
            }
            <tbody>
              @for (taxModelItems of taxModelTable.EmployeeTaxModelItemTypes; track taxModelItems; let firstIndex = $first; let lastIndex = $last) {
                @if (!(firstIndex && taxModelTable.UseHeader) && !(lastIndex && taxModelTable.UseFooter)) {
                  <tr class="row"
                    [ngClass]="taxModelItems?.CssClass != null ? taxModelItems.CssClass : ''">
                    <td class="col-6 text-break"
                      [innerHTML]="taxModelItems.ItemCaption != null && taxModelItems.ItemCaption != '' ? taxModelItems.ItemCaption : taxModelItems.ItemName | safeHtml">
                    </td>
                                    <ng-container *ngTemplateOutlet="tableValueSection; context: {
                                        tag: 'td',
                                        isDescriptiveText: taxModelItems.IsDescriptiveText,
                                        itemValue: taxModelItems.ItemValue,
                                        itemValue2: taxModelItems.ItemValue2,
                                        currencyCode: taxModelItems.CurrencyCode
                                    }"></ng-container>
                  </tr>
                }
              }
            </tbody>
            @if (taxModelTable.UseFooter) {
              <tfoot>
                <tr class="row"
                  [ngClass]="taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1]?.CssClass != null ? taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].CssClass : ''">
                  <th class="col-6 text-break"
                    [innerHTML]="taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].ItemCaption != null ? taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].ItemCaption : '' | safeHtml"
                  id="itemCaptionInFooter"></th>
                                <ng-container *ngTemplateOutlet="tableValueSection; context: {
                                    tag: 'td',
                                    isDescriptiveText: taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].IsDescriptiveText,
                                    itemValue: taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].ItemValue,
                                    itemValue2: taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].ItemValue2,
                                    currencyCode: taxModelTable.EmployeeTaxModelItemTypes[taxModelTable.EmployeeTaxModelItemTypes.length-1].CurrencyCode
                                }"></ng-container>
                </tr>
              </tfoot>
            }
          }
        </table>
      }
    </div>
  </div>
  <!-- Accessibility Loading and Loaded screen announcement -->
  <aon-spin [noDarkSpin]="true"></aon-spin>
</div>