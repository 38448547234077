<table class="sable-table {{options.cssClass}}" aria-label="">
  <thead class="sable-thead">
    @for (row of options.items; track row) {
      @if (row.displayType=='tableHeader') {
        <tr class="sable-header-row {{row.cssClass}}">
          @if ((row.label != null && row.label != '')) {
            <th class="sable-header-cell {{row.cssClass}}" scope="col" role="columnheader"
            >{{row.label}}</th>
          }
          @for (item of row.items; track item) {
            <th class="{{item.cssClass}}" scope="col" role="columnheader">
              {{item.label}}
              @if (item.footerContent!=null || item.footerCssClass != null) {
                <div class="{{item.footerCssClass}}">
                  {{item.footerContent}}
                </div>
              }
            </th>
          }
        </tr>
      }
    }
  </thead>

  <tbody class="sable-tbody">
    @for (row of options.items; track row) {
      @if (row.displayType=='tableRow' && row.items != null && row.items.length > 0) {
        <tr
          class="sable-body-row {{row.cssClass}}">
          @if ((row.label != null && row.label != '') && (row.title == null || row.title == '' )) {
            <th class="sable-header-cell {{row.cssClass}}"
            [class.with-desc]="row.title != null && row.title != ''" [title]="row.title" scope="row">{{row.label}}</th>
          }
          @if ((row.label != null && row.label != '') && (row.title != null && row.title != '')) {
            <th class="sable-header-cell {{row.cssClass}}"
              [class.with-desc]="row.title != null && row.title != ''"
              [ngClass]="{'tooltip': row.title != null && row.title != ''}" scope="row">
              {{row.label}}
              @if (row.title != null && row.title != '') {
                <span class="tooltiptext">{{row.title}}</span>
              }
            </th>
          }
          @for (item of row.items; track item) {
            <td class="{{item.cssClass}}" [attr.aria-label]="item.css_aria_label">
              {{item.label}}
              @if ((item.footerContent!=null && item.footerContent != '') && item.footerCssClass != null) {
                <div class="{{item.footerCssClass}}"
                  >
                  {{item.footerContent}}
                </div>
              }
            </td>
          }
        </tr>
      }
    }
  </tbody>
</table>