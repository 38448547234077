export namespace IEditableMBRSiteToolbar {
  export interface Header {
    label?: string;
    version?: string;
    managedBy?: ManagedBy;
  }

  export enum ManagedBy {
    InternalEditor,
    ExternalEditor
  }

  export interface Controls {
    population?: {
      value: string,
      callbackFnName: string,
      args?: any[]
    },
    menus: MenuControls[];
  }

  export interface MenuControls {
    groupName?: string;
    items: {
      name: string;
      iconClass: string;
      callbackFnName: string;
      args?: any[];
    }[]
  }

  export enum ToolbarType {
    Global,
    Local
  }
}
