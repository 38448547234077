<div class="modal-header">
  <button type="button" class="close" (click)="activeModal.close()">
    <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
  </button>
</div>
@if (flowID) {
  <div aria-live="true">
    <sable-flow flowId="{{flowID}}" (loading)="handleSpinner($event)" (liveActivity)="handleLiveActivity($event)"
    [additionalContextData]="additionalContextData"></sable-flow>
  </div>
}