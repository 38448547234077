import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { FaqModel, HelpModel, HomeModel } from './home.model';
import { Product } from '../../shared/models/product.model';
import { ContentItemModel } from '../../shared/models/contentItem.model';
import { ActiveEventsType } from '../../shared/models/employeeEvent.model';
import { EmployeeTrsDashboardData } from '../../shared/models/employeeTrsDashboardData.model';
import { BehaviorSubject } from 'rxjs';
import { EmployeeClaimSummary } from '../claim/claim.model';
import { ActiveBenefitsWidgetType } from 'src/app/shared/models/benefitReview.model';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { BenefitService } from 'src/app/modules/benefit/benefit.service';
import { ResourceStringsObject } from '../../shared/models/resourceString.model';
import { SessionStorageKey } from '../../shared/models/constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  discardEventInvoked = new EventEmitter();
  apiBaseUrl: string;
  tenantFolderName: string;
  public homeResourceStrings: ResourceStringsObject = {};
  public hideHomeBanners: BehaviorSubject<boolean>;
  constructor(
    private readonly http: HttpClient,
    public uiconfigrationService: UiconfigrationService,
    private readonly benefitService: BenefitService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.tenantFolderName = bootstrapRequestContext.tenantFolderName;
    this.hideHomeBanners = new BehaviorSubject<boolean>(false);
  }

  public getHomePage(currentCulture: string, path: string, campaignKey: string, population?: string) {
    let nodePath = this.tenantFolderName + path;
    let commonPageUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/home?parentPath=' + nodePath;
    if (campaignKey != null && campaignKey.length > 0) {
      commonPageUrl += "&campaignKey=" + campaignKey;
    }
    if (population?.length > 0) {
      commonPageUrl += "&population=" + population;
    }
    return this.http.get<HomeModel>(commonPageUrl);
  }

  public GetFaqs(products: Product[]) {
    const productIds = products.map(({ recordID }) => recordID);
    let faqsUrl = this.apiBaseUrl + 'faqItems';
    return this.http.post<FaqModel>(faqsUrl, productIds);
  }

  public GetHelps(products: Product[]) {
    const productIds = products.map(({ recordID }) => recordID);
    let faqsUrl = this.apiBaseUrl + 'helpItems';
    return this.http.post<HelpModel>(faqsUrl, productIds);
  }

  public getContentItem(path: string, carrierKey: string) {
    let contentItemUrl = this.apiBaseUrl + 'contentitem';
    let nodePath = this.tenantFolderName + path;
    let carrierKeys = [];
    carrierKeys.push(carrierKey);
    return this.http.post<ContentItemModel>(contentItemUrl, { Path: nodePath, ContentKeys: carrierKeys });
  }

  public getClientContent(currentCulture: string, path: string, campaignKey: string, contentKey: string) {
    let nodePath = this.tenantFolderName + path;
    let commonPageUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/clientContent?parentPath=' + nodePath + '&contentKey=' + contentKey;
    if (campaignKey != null && campaignKey.length > 0) {
      commonPageUrl += "&campaignKey=" + campaignKey;
    }
    return this.http.get<any>(commonPageUrl);
  }

  public isUiConfigShowEventInfoInHero(): boolean {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    const activeEvents: ActiveEventsType = this.benefitService.activeEvents?.value;
    if (uiConfig?.uiConfiguration?.showEnrollmentEventInformationHero
      && ((activeEvents?.activeEventsByScheme?.length === 1
        && activeEvents?.activeEventsByScheme[0]?.activeEnrolmentEventsType?.employeeEventTypes.length === 1
        && !activeEvents?.lifeStyleInfo)
        || ((activeEvents?.activeEventsByScheme?.length === 1
          && activeEvents?.activeEventsByScheme[0]?.activeEnrolmentEventsType?.employeeEventTypes.length === 0)
          || (activeEvents?.activeEventsByScheme?.length === 0 || !activeEvents?.activeEventsByScheme)
          && activeEvents?.lifeStyleInfo
        ))) {
      return true;
    }
    return false;
  }

  public getActiveBenefits() {
    let requestUrl = this.apiBaseUrl + 'activeBenefitsForWidget';
    return this.http.get<ActiveBenefitsWidgetType>(requestUrl);
  }

  public getEmployeeClaimSummary(employeeSpendingAccountId = null) {
    let requestUrl = `${this.apiBaseUrl}claimsummary`;
    let params = new HttpParams();
    params = params.append('EmployeeSpendingAccountId', employeeSpendingAccountId);
    const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
    if (curSelDelegateEmployee != null) {
      params = params.append('ActForEmployeeID', curSelDelegateEmployee);
    }

    return this.http.get<EmployeeClaimSummary>(requestUrl, { params: params });
  }

  public checkEmployeeCorrespondenceValidity(correspondenceType_RecordID: string, noOfDaysValid: number) {
    let requestUrl = this.apiBaseUrl + 'CheckEmployeeCorrespondenceValidity?correspondenceType_RecordID=' + correspondenceType_RecordID + '&noOfDaysValid=' + noOfDaysValid;
    return this.http.get<boolean>(requestUrl);
  }
  getTRSWidgetData(trsInstanceRecordId: string, chartType: string, showPercentage: boolean, showPercentageAndCurrency: boolean) {
    let trsDataUrl = this.apiBaseUrl + 'TrsWidgetData' + '?trsInstanceRecordId=' + trsInstanceRecordId + '&chartType=' + chartType + '&displayTotalRewardPercentage=' + showPercentage + '&displayTotalRewardPercentageAndCurrency=' + showPercentageAndCurrency;
    return this.http.get<EmployeeTrsDashboardData>(trsDataUrl);
  }

  public discardEventCall() {
    this.discardEventInvoked.emit();
  }

}
