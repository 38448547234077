import { Component, Injector, OnDestroy, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { ViewportScroller } from '@angular/common';
import { HomeService } from '../home.service';

@Component({
  selector: 'app-saved-quote-info',
  templateUrl: './saved-quote-info.component.html'
})
export class SavedQuoteInfoComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  constructor(
    private readonly homeService: HomeService,
    public modalService: NgbActiveModal,
    public activeModal: NgbActiveModal,
    private readonly scroller: ViewportScroller,
    private readonly injector: Injector
  ) {
    super(injector);
    this.resourceStrings = this.homeService.homeResourceStrings;//Get resource strings from parent - home component, please check homeSummaryStringKeys in ResourceStringsService
  }

  ngOnInit(): void {
  }
  close() {
    this.modalService.close(false);
    this.scrollToTop();
  }
  save() {
    this.modalService.close(true);
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private scrollToTop() {
    this.scroller.scrollToPosition([0, 0]); //Scroll to top to show error message
  }

}
