<div class="editable-mbr-site-toolbar"
    [ngClass]="{ 'inside-card' : isInsideCard(),
'local-toolbar': type() === toolbarType?.Local, 'global-toolbar': type() === toolbarType?.Global, 'spacer': addSpacer() }" [ngStyle]="styleProperties">
    @if (header()) {
    <p class="toolbar-header">
        @if (header()?.label && header()?.label != '') {
        <span class="toolbar-header-label truncate-line-1" [innerHTML]="header()?.label | safeHtml"
            #popover="ngbPopover" ngbPopover="{{header()?.label}}" popoverClass="popover-gray"
            triggers="mouseenter:mouseleave focusin:focusout"
            [disablePopover]="!helperService.isContentOverflowHidden(headerName)" #headerName></span>
        }
        @if (header()?.version && header()?.version != '') {
        <span class="m-t-0 m-b-0 tag teal-tag-wrapper">{{header()?.version}}</span>
        }
        @switch(header()?.managedBy) {
        @case (ManagedBy.InternalEditor) {
        <span class="m-t-0 m-b-0 tag"
            [ngClass]="{'yellow-tag-wrapper': isExternalEditorSite, 'green-tag-wrapper': !isExternalEditorSite}">{{resourceStrings['Aon.SelfService.Managed.Internally']}}</span>
        }
        @case (ManagedBy.ExternalEditor) {
        <span class="m-t-0 m-b-0 tag"
            [ngClass]="{'yellow-tag-wrapper': !isExternalEditorSite, 'green-tag-wrapper': isExternalEditorSite}">{{resourceStrings['Aon.SelfService.Managed.Externally']}}</span>
        }
        }
        }
        @if (controls()?.population) {
    <div tabindex="0" type="button" id="menu-main-population-preview" class="toolbar-control"
        (click)="onClick(controls()?.population?.callbackFnName, controls()?.population?.args)">
        <span class="label xs">Selected Population:&nbsp;</span><span
            class="population-name xs">{{controls()?.population?.value}}</span><i class="fal fa-people-group"></i>
    </div>
    }
    @if (controls()?.menus && controls()?.menus?.length > 0) {
    <div ngbDropdown #myDrop="ngbDropdown" class="toolbar-control-menu-wrappper">
        <div tabindex="0" class="toolbar-control" type="button" id="menu-main-actions{{uuid}}" ngbDropdownToggle>
            <i class="fal fa-ellipsis-vertical"></i>
        </div>
        <div ngbDropdownMenu [attr.aria-labelledby]="'menu-main-actions' + uuid" class="toolbar-control-dropdown-menu">
            <ul>
                @for (menu of controls()?.menus; track menu) {
                @if (menu?.groupName && menu?.groupName != '') {
                <li ngbDropdownItem class="header"><span class="sm" [innerHTML]="menu?.groupName | safeHtml"></span>
                </li>
                }
                @for (item of menu?.items; track item) {
                <li ngbDropdownItem (click)="onClick(item?.callbackFnName, item?.args)"><span class="sm"
                        [innerHTML]="item?.name | safeHtml"></span> <i class="fal {{item?.iconClass}}"></i></li>
                }
                }
            </ul>
        </div>
    </div>
    }
</div>