import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SableFlowAdapterService, SableFlowEventBusService, SableFlowModel } from '@aon/sable-flow';
import { LoginService } from '../../../shared/services/login.service';
import { LoginModalComponent } from '../../login-modal/login-modal.component';

@Injectable({
  providedIn: 'root'
})
export class KenticoFlowAdapterService implements SableFlowAdapterService {
  instance: LoginModalComponent;
  customInteractions: Array<SableFlowModel.CustomInteractions> = [];
  constructor(protected loginService: LoginService,
    private readonly busservice: SableFlowEventBusService
  ) { }

  render(vcRef: ViewContainerRef, data: any): Observable<any> {
    vcRef.clear();

    const componentRef = vcRef.createComponent<LoginModalComponent>(LoginModalComponent, { index: 0 });
    this.instance = componentRef.instance;
    this.instance.hideCloseButton = true;

    this.instance.afterSumbit = () => {
    };
    this.instance.completeValidation = () => {
      this.busservice.next({ flag: true, saveData: null, goToInstruction: null, additionalContextData: null });

    };
    this.instance.createAccountcallback = () => {
      this.loginService.openRegistration();
    };

    return of(true);
  }


  next(): Observable<any> {
    let returnObj = {};
    returnObj['DidSave'] = true;
    return of(returnObj);
  }

  previous(): Observable<boolean> {
    return of(true);
  }

  validationCheck(): Observable<boolean> {
    return of(true);
  }
  final(): void {

  }
}
