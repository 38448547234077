@for (participant of participantData; track participant; let participantIndex = $index) {
  <div class="item participant-component">
    <div class="d-flex align-items-center m-b-4" [ngClass]="{'m-t-20': participantIndex !== 0}">
      <p class="strong m-b-0">{{participant.name}}</p>
      @if (participant.documents.length == 0) {
        @if (!participant.requiredForEnrolment) {
          <p class="m-b-0 m-l-5">
            <em>({{resourceStrings['Aon.Common.Optional']}})</em>
          </p>
        }
        @if (participant.requiredForEnrolment) {
          <p class="m-b-0 m-l-5">
            <em>({{resourceStrings['Aon.Common.Required']}})</em>
          </p>
        }
      }
      @if (participant.documents.length > 0) {
        <span class="m-t-0 m-b-0 strong tag green-tag-wrapper">{{resourceStrings['Aon.Documents.Complete']}}</span>
      }
    </div>
    @if (participant.instruction != null) {
      <p [innerHTML]="participant.instruction | safeHtml"></p>
    }
    @for (doc of participant.documents; track doc) {
      <p class="card-text m-b-0">
        @if (doc.document_FileName != null) {
          <div class="d-flex align-items-center">
            <a tabindex="0"
              [attr.aria-label]="resourceStrings['Aon.Profile.Documents.Download.Label'] +' '+  doc?.document_FileName"
              class="m-b-0" (keydown.enter)="downloadFile(doc.document_RecordID)"
              (keydown.space)="downloadFile(doc.document_RecordID)"
            (click)="downloadFile(doc.document_RecordID)">{{doc.document_FileName}}</a>
            <a class="d-flex align-items-center text-decoration-none" role="button"
              [attr.aria-label]="resourceStrings['Aon.Profile.Documents.Edit.Label']" tabindex="0"
              (keydown.enter)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);"
              (keydown.space)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);"
              (click)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);">
              <i aria-hidden="true" class="fal fa-edit m-l-8"></i>
            </a>
          </div>
          @if (errorMessage[participantIndex]) {
            <div class="alert contextual error m-t-20" role="alert" aria-live="polite">
              <div class="message">
                <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                {{errorMessage[participantIndex]}}
              </div>
            </div>
          }
          @if (successMessage[participantIndex] && !errorMessage[participantIndex]) {
            <div id="save-success-alert" class="alert contextual success m-t-20"
              role="alert" aria-live="polite">
              <div class="message justify-content-between">
                <div class="content-left d-flex align-items-center">
                  <i aria-hidden="true" class="fas fa-check-circle"></i>
                  {{doc.document_FileName }} {{successMessage[participantIndex]}}
                </div>
              </div>
            </div>
          }
        }
        @if (doc.document_FileName == null) {
          <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{participantIndex: participantIndex}"
          ></ng-container>
        }
      </p>
    }
    @if (participant.documents == null || (participant.documents != null && participant.documents.length == 0)) {
      <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{participantIndex: participantIndex}"
        >
      </ng-container>
    }
  </div>
}
<ng-template #itemTemplate let-participantIndex="participantIndex">
  <p class="card-text">{{resourceStrings['Aon.UploadDocument.NoDocuments']}}</p>
  @if (errorMessage[participantIndex]) {
    <div class="alert contextual error"><i class="fas fa-exclamation-triangle"
    aria-hidden="true"></i>{{errorMessage[participantIndex]}}</div>
  }

  <div class="m-t-20">
    <button type="button"
    (click)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=null; setParticipantIndex(participantIndex);">{{resourceStrings['Aon.UploadDocument.UploadDocumentsText']}}</button>
  </div>
</ng-template>

<kendo-fileselect #upload (select)="selectEventHandler($event)" [showFileList]="false" style="display:none;"
  name="selectfile">
</kendo-fileselect>