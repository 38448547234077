import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BootstrapRequestContext } from 'tbs-typings';
import { EmployeeBenefitsType } from '../../shared/models/benefitReview.model';
import { SaveResult } from '../../shared/models/saveResult.model';
import { HttpCacheService } from '../cache/http-request-cache';

@Injectable({
  providedIn: 'root'
})
export class MastheadService {
  private readonly refreshCartSubject = new Subject<void>();

  apiBaseUrl: string;
  constructor(private readonly  http: HttpClient,
    private readonly  httpCache: HttpCacheService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }

  //This is not needed now as there is only cart component placed, Keep this code here for reference
  /*
  @HttpRequestCache<MastheadService>(function() {
    return {
      storage: this.httpCache,
      refreshSubject: this.refreshCartSubject
    } as IHttpCacheOptions;
  })
  */
  getGlobalCartBenefits() {
    const globalCartBenefitsUrl = this.apiBaseUrl + 'globalcartbenefits';
    return this.http.get<EmployeeBenefitsType>(globalCartBenefitsUrl);
  }

  refreshCart() {
    this.refreshCartSubject.next();
  }

  public deleteEmployeeSavedQuote(eeBenefitId: number) {
    const requestUrl = this.apiBaseUrl + 'quote/' + eeBenefitId + '/delete';
    return this.http.post<SaveResult>(requestUrl, null);
  }
}
