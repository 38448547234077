import { Component, OnInit, Input, Output, EventEmitter, Inject, OnDestroy, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { UiconfigrationService } from '../../../core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { BootstrapRequestContext } from 'tbs-typings';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { AccountService } from 'src/app/modules/account/account.service';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { iif, of } from 'rxjs';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { CommonService } from '../../services';
import { ContentItemModel } from 'src/app/shared/models/contentItem.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../../modules/profile/profile.service';

@Component({
  selector: 'app-masthead-profile',
  templateUrl: './masthead-profile.component.html'
})
export class MastheadProfileComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  content: ContentItemModel;
  @Input() resourcestrings;
  @Input() actAsDelegate
  @Input() hasDelegateForEmployees;
  @Output() onCloseClick = new EventEmitter();
  @Output() onGracePeriodUpdated = new EventEmitter<boolean>();
  baseUrl: string;

  uiConfig: UIConfigurationType;
  statusofclick: boolean;
  siteType: string;
  url: string;
  constructor(
    private readonly injector: Injector,
    private readonly authenticationService: AuthenticationService,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly accountService: AccountService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly globalObjectsService: GlobalObjectsService,
    private readonly paymentService: PaymentService,
    private readonly globalojectService: GlobalObjectsService,
    private readonly commonService: CommonService,
    private readonly ngxService: NgxUiLoaderService,
    public profileService: ProfileService,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }

  ngOnInit(): void {
    this.globalojectService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.uiConfig = this.uiconfigrationService.getUIConfiguration();
        this.siteType = this.bootstrapRequestContext.siteType;

        //Grace Period Status Checking should be called for VB sites post login
        if (this.bootstrapRequestContext.showVBBanners) {
          this.authenticationService.currentUser
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(user => {
              if (this.authenticationService.isLogin) {
                this.paymentService.checkEmployeeBenefitGracePeriod()
                  .pipe(catchError(() => of(false)), takeUntil(this.unsubscribe))
                  .subscribe(data => this.loadBadge(data));
              }
            });
        }

        //Hide the account tab if the SSO user hasn't self-registered yet.
        //If error occurs getting IsSelfRegistered, hide the account tab as well
        // Just triggering the isSelfRegistered API call, based on the !selfRegistered SSO user display value will be updated to this profileService.hideProfileAccount
        this.authenticationService.currentUser.pipe(
          mergeMap((user) => iif(() => this.authenticationService.isLogin && this.authenticationService.isSSO(user), this.accountService.isSelfRegistered().pipe(
            map(selfRegistered => !selfRegistered),
            catchError(() => of(true))), of(false))),
          takeUntil(this.unsubscribe))
          .subscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  logOut(): void {
    this.url = this.router.url.valueOf();
    if (this.helperService.isSingleProductCheckOutEnabled() && this.url.includes('/payment') && this.uiConfig.uiConfiguration.showAlertPopup) {
      this.helperService.logoutRedirection();
    }
    else {
      if (!this.url.includes(this.baseUrl + "/quote") || !this.uiConfig.uiConfiguration.showAlertPopup || (this.url.includes("/complete"))) {
        this.authenticationService.logout().subscribe((x) => {
          this.helperService.logoutRedirection();
        });
      } else if (this.uiConfig.uiConfiguration.showAlertPopup) {
        this.helperService.logoutRedirection();
      }
    }
  }

  closeModal(): void {
    this.statusofclick = true;
    this.onCloseClick.emit(this.statusofclick);
  }

  loadBadge(data: boolean): void {
    this.globalObjectsService.hasGracePeriodSubject.next(data);
    this.onGracePeriodUpdated.emit(data);
  }
}
