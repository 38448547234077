@if (showSelfServiceControls) {
<app-editable-mbr-site-toolbar [instance]="this" [header]="{ label: callToAction?.itemTitle }"
  [controls]="controlsForCTAInstanceToolbar"
  [setMaxWidthBasedOnRelativeNextSiblingContainer]="true"></app-editable-mbr-site-toolbar>
}
@if (!hideCTAButton) {
@if (callToAction && !callToAction.displayStyle && (isExternal || (!isExternal && appLinkConfigured))) {
<button class="call-to-action button-style anaval-{{name}}" [ngClass]="cssClass"
  (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment" [routerLink]="">
  {{ctaDisplayText}}
  @if (loading) {
  <span class="spinner-border spinner-border-sm m-l-4"></span>
  }
</button>
}
@if (callToAction && !callToAction.displayStyle && !isExternal && !appLinkConfigured) {
<button class="call-to-action button-style anaval-{{name}}" [ngClass]="cssClass"
  (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
  [routerLink]="checkSavedQuotesBeforeLaunchingQuote ? null : ctaLink">
  {{ctaDisplayText}}
  @if (loading) {
  <span class="spinner-border spinner-border-sm m-l-4"></span>
  }
</button>
}
@if (callToAction && callToAction.displayStyle && (isExternal || (!isExternal && appLinkConfigured))) {
<a class="call-to-action link-style anaval-{{name}}" href="javascript:void(0);" tabindex="0" [ngClass]="cssClass"
  (click)="ctaFunc(callToAction.additionalParameter, $event)"
  (keydown.enter)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
  [routerLink]="">{{ctaDisplayText}}</a>
}
@if (callToAction && callToAction.displayStyle && !isExternal && !appLinkConfigured) {
<a class="call-to-action link-style anaval-{{name}}" [ngClass]="cssClass"
  (click)="ctaFunc(callToAction.additionalParameter, $event)" [fragment]="fragment"
  [routerLink]="checkSavedQuotesBeforeLaunchingQuote ? null : ctaLink">{{ctaDisplayText}}</a>
}
}