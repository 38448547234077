import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageSelectionData } from '../../../shared/models';
import { SessionStorageKey } from '../../../shared/models/constants';
import { TBSBaseComponent } from '../../../shared/models/tbsBaseComponent';
import { CTACustomFunctionService } from '../../../shared/services/ctacustom-function.service';
import { GlobalObjectsService } from '../../../shared/services/global-objects.service';
import { HelperService } from '../../../shared/services/helper.service';
import { HomeService } from '../../home/home.service';
import { CustomLoggedOffSettings } from '../../../shared/models/customLoggedOffSettings.model';
import { CTAModel } from 'src/app/shared/models/callToAction.model';

@Component({
  selector: 'app-custom-logged-off',
  templateUrl: './custom-logged-off.component.html'
})
export class CustomLoggedOffComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  campaignKey: string;
  clientContent: any;
  contentKey: string;
  callToAction: CTAModel.CallToActionFields;
  pageContentAvailable: boolean = true;
  customFunctionData: any;
  constructor(public helperService: HelperService,
    private readonly homeService: HomeService,
    private readonly globalojectService: GlobalObjectsService,
    private readonly route: ActivatedRoute,
    private readonly ctaCustomFn: CTACustomFunctionService,
    private readonly router: Router,
    private readonly injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.helperService.clearBrowserHistory();
    const baseUrl: string = this.helperService.getBaseUrl();
    this.logPageView();
    if (!sessionStorage.getItem('CustomLoggedOffSettings')) {
      this.router.navigate([baseUrl + '/login']);
      return;
    }

    let customLoggedOffSettings: CustomLoggedOffSettings = null;
    if (sessionStorage.getItem('CustomLoggedOffSettings')) {
      customLoggedOffSettings = JSON.parse(sessionStorage.getItem('CustomLoggedOffSettings'));
    }
    if (customLoggedOffSettings?.enableCustomLoggedOffPage && this.helperService.isExternalUrl(customLoggedOffSettings.customLoggedOffPagePath)) {
      sessionStorage.removeItem('CustomLoggedOffSettings');
      window.location.replace(customLoggedOffSettings.customLoggedOffPagePath);
      return;
    }

    sessionStorage.removeItem('CustomLoggedOffSettings');
    this.globalojectService.CTAs
      .subscribe(callToAction => {
        if (!callToAction) {
          try {
            this.callToAction = JSON.parse(window.atob(sessionStorage.getItem(SessionStorageKey.ClientContentPageCallToActions)));
          } catch { }
        } else {
          this.callToAction = callToAction;
        }
      });
    let key;
    this.route.queryParams.subscribe(queryParams => {
      key = queryParams['pagekey'];
    });
    this.fetchClientContent(key);
  }

  fetchClientContent(key: string = null) {
    const curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
    this.campaignKey = this.helperService.getCampaignKey();
    this.contentKey = key || this.callToAction.additionalParameter;
    this.homeService.getClientContent(curLanguageCode.cultureCode, "/client-content-page", this.campaignKey, this.contentKey).subscribe(cc => {
      if (cc.content) {
        this.clientContent = cc;
      } else {
        this.pageContentAvailable = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
    this.pageContentAvailable = true;
  }
}
