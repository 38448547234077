import { Injector, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ResourceString } from '.';
import { HelperService } from '../services/helper.service';
import { ResourceStringsService } from '../services/resource-strings.service';
import { ToastService } from '../services/toast.service';
import { ResourceStringsObject } from './resourceString.model';
import { RoutingPathsSpecailCheck } from '../models/constants';
import { ChildComponentOptions } from '../models/childComponentOptions.model';
import { AnalyticsService } from '../services/analytics.service';

export abstract class TBSBaseComponent {
  private readonly injectorImp: Injector = null;

  protected unsubscribe = new Subject<void>();
  protected myLoader: string = null; //NgxUi Loader ID for individual component.
  protected routesWithProductId: string[] = ['/quote', '/product'];
  protected routesWithCategoryId: string[] = ['/category'];
  protected preloadProductId = true;
  protected preloadCategoryId = true;
  protected stringKeys: string[] = [];
  protected isPageViewLogged: boolean;

  public helperService: HelperService = null;
  public resService: ResourceStringsService = null;
  public analyticsService: AnalyticsService = null;
  public resourceStrings: ResourceStringsObject = {};
  public baseUrl: string;
  public productId: string = null;
  public categoryId: string = null;
  public proudctIdChanged = new BehaviorSubject<string>(null);
  public categoryIdChanged = new BehaviorSubject<string>(null);
  public isCurrentPathRoleEnabled: boolean;
  public rolesForUiString: string[];
  public combinedRolesString: string;
  public childComponentOptions: ChildComponentOptions;
  public sableFormTemplate: any; // Used to get the sable form template and used in getFormConfig to read the form definition JSON from sableProcess
  public saveSableFormData: any; // Used in saveFormData to save the form data to the component adapter service

  constructor(injector: Injector, options: ChildComponentOptions = new ChildComponentOptions()) {
    this.injectorImp = injector;
    this.helperService = injector.get(HelperService);
    this.resService = injector.get(ResourceStringsService);
    this.analyticsService = injector.get(AnalyticsService);

    this.baseUrl = this.helperService.getBaseUrl();
    this.preloadProductId = options.preloadProductId;
    this.preloadCategoryId = options.preloadCategoryId;
    this.childComponentOptions = options;
    this.isPageViewLogged = false;
    this.getProductId(injector);
    this.getCategoryId(injector);
    this.getCurrentPathRoleSettingStatus(injector);

    this.stringKeys = [...this.resService.commonStringKeys];
  }

  protected getCurrentPathRoleSettingStatus(injector: Injector): void {
    if (this.helperService.enabledRolesForUiStrings()) {
      let currentPath = injector.get(Router).url.toLowerCase();
      let rolesRoutingPathsObject = this.helperService.getRolesRoutingPathsForUiStrings();
      let roleSpecificPaths = rolesRoutingPathsObject.paths;
      currentPath = this.pathCheckAndReplace(currentPath);
      if (roleSpecificPaths.includes(currentPath)) {
        this.isCurrentPathRoleEnabled = true;
        this.rolesForUiString = rolesRoutingPathsObject.roles;
        this.combinedRolesString = rolesRoutingPathsObject.combinedRolesString;
        return;
      }
    }
    this.isCurrentPathRoleEnabled = false;
  }

  private pathCheckAndReplace(currentPath: string): string {
    if (this.childComponentOptions.isHeader) {
      return RoutingPathsSpecailCheck.Header;
    }
    if (this.childComponentOptions.isFooter) {
      return RoutingPathsSpecailCheck.Footer;
    }
    //check ? in path
    if (currentPath.includes('?')) {
      currentPath = currentPath.split('?')[0].trim();
    }
    //check # in path
    if (currentPath.includes('#')) {
      currentPath = currentPath.split('#')[0].trim();
    }
    //check product,category related paths
    if (
      (currentPath.includes(RoutingPathsSpecailCheck.StartWithProduct) || currentPath.includes(RoutingPathsSpecailCheck.StartWithCategory)) &&
      currentPath.split('/').length > 2
    ) {
      let idStr = currentPath.split('/')[2];
      currentPath = currentPath.replace(idStr, RoutingPathsSpecailCheck.Id);
      //check product article
      if (currentPath.includes(RoutingPathsSpecailCheck.Article)) {
        currentPath = currentPath.split(RoutingPathsSpecailCheck.Article)[0] + RoutingPathsSpecailCheck.ProductArticleKey;
      }
    }
    else if (currentPath.includes(RoutingPathsSpecailCheck.StartWithArticle)) {//Check common article , without product article
      currentPath = currentPath.split(RoutingPathsSpecailCheck.Article)[0] + RoutingPathsSpecailCheck.CommonArticle;
    }
    //check quote ,claim related paths
    if (
      (currentPath.includes(RoutingPathsSpecailCheck.StartWithQuote) || currentPath.includes(RoutingPathsSpecailCheck.StartWithClaim)) &&
      currentPath.split('/').length > 2
    ) {
      let idStr = currentPath.split('/')[2];
      currentPath = currentPath.replace(idStr, RoutingPathsSpecailCheck.Id);
      //check sable flow
      if (currentPath.includes(RoutingPathsSpecailCheck.Details)) {
        currentPath = currentPath.split(RoutingPathsSpecailCheck.Details)[0] + RoutingPathsSpecailCheck.DetailsId;
      }
    }

    //check mybenefits ,all mybenefits path using a single path to instead.
    if (currentPath.includes(RoutingPathsSpecailCheck.StartWithMyBenefits)) {
      currentPath = currentPath.split(RoutingPathsSpecailCheck.StartWithMyBenefits)[0] + RoutingPathsSpecailCheck.StartWithMyBenefits;
    }

    return currentPath;
  }

  private getProductId(injector: Injector) {
    if (this.preloadProductId) {
      let clientCode = this.getClientCode();
      let prefix = '';
      if (clientCode) {
        prefix = `/${clientCode}`;
      }

      let router = injector.get(Router);
      let checkProductId = this.routesWithProductId.some((x) => router.url.startsWith(prefix + x));
      if (checkProductId) {
        let route = injector.get(ActivatedRoute);
        route.params.pipe(takeUntil(this.unsubscribe)).subscribe((pms) => {
          let newProductId = pms['id'] || null;
          if (newProductId && !this.helperService.isGuid(newProductId)) {
            newProductId = null;
          }

          let isProductIdChanged = false;
          if (newProductId && this.productId != newProductId) {
            isProductIdChanged = true;
          }

          this.productId = newProductId;

          if (isProductIdChanged) {
            this.proudctIdChanged.next(newProductId);
          }
        });
      }
    }
  }

  private getCategoryId(injector: Injector) {
    if (this.preloadCategoryId) {
      let clientCode = this.getClientCode();
      let prefix = '';
      if (clientCode) {
        prefix = `/${clientCode}`;
      }

      let router = injector.get(Router);
      let checkCategoryId = this.routesWithCategoryId.some((x) => router.url.startsWith(prefix + x));
      if (checkCategoryId) {
        let route = injector.get(ActivatedRoute);
        route.params.pipe(takeUntil(this.unsubscribe)).subscribe((pms) => {
          let newCategoryId = pms['id'] || null;
          if (newCategoryId && !this.helperService.isGuid(newCategoryId)) {
            newCategoryId = null;
          }

          let isCategoryIdChanged = false;
          if (newCategoryId && this.categoryId != newCategoryId) {
            isCategoryIdChanged = true;
          }

          this.categoryId = newCategoryId;

          if (isCategoryIdChanged) {
            this.categoryIdChanged.next(newCategoryId);
          }
        });
      }
    }
  }

  public addResourceStringKeys(key: string | string[]): void {
    if (key) {
      if (!Array.isArray(key)) {
        if (!this.stringKeys.some((x) => x === key)) {
          this.stringKeys.push(key);
        }
      } else {
        this.stringKeys = this.stringKeys.concat((key as string[]).filter((x) => !this.stringKeys.some((y) => x === y)));
      }

      //Initialize value for new keys
      this.stringKeys.forEach((key) => {
        if (!(key in this.resourceStrings)) {
          this.resourceStrings[key] = '';
        }
      });
    }
  }

  public loadResourceStringAsObservable(key: string | string[] = null): Observable<ResourceString[]> {
    this.addResourceStringKeys(key);
    if (this.isCurrentPathRoleEnabled) {
      return this.helperService
        .loadResourceStringWithAdditionalParamAsObservable(this.combinedRolesString, this.stringKeys, this.rolesForUiString)
        .pipe(catchError((error, caught) => of([])));
    }

    if (this.productId) {
      return this.helperService
        .loadResourceStringWithAdditionalParamAsObservable(this.productId, this.stringKeys)
        .pipe(catchError((error, caught) => of([])));
    } else {
      return this.helperService.loadResourceStringAsObservable(this.stringKeys).pipe(catchError((error, caught) => of([])));
    }
  }

  public getResouceStringsAsObject(res: ResourceString[], resolveMacros: boolean = true): void {
    if (this.isCurrentPathRoleEnabled) {
      this.resourceStrings = this.helperService.getResourceStringsWithAdditionalParamAsObject(
        this.combinedRolesString,
        this.stringKeys,
        res,
        resolveMacros
      );
      return;
    }

    if (this.productId) {
      this.resourceStrings = this.helperService.getResourceStringsWithAdditionalParamAsObject(this.productId, this.stringKeys, res, resolveMacros);
    } else {
      this.resourceStrings = this.helperService.getResouceStringsAsObject(this.stringKeys, res, resolveMacros);
    }
  }

  public reloadResoureStringAsObservable(keys: string[], productID: string = null): Observable<ResourceString[]> {
    // For reloading the Resource Strings by Product level
    // We need to delete them from 'this.resourceStrings', also need to reset them in 'uiStringsCacheDic' instead
    // Because Resource Strings are cached in the global 'uiStringsCacheDic', and it is cached by previous pages as well
    keys.forEach((key) => {
      if (key in this.resourceStrings) {
        delete this.resourceStrings[key];
      }
    });

    this.addResourceStringKeys(keys);
    this.helperService.resetStringsByKeys(productID, keys);

    return this.loadResourceStringAsObservable(keys).pipe(catchError((error, caught) => of([])));
  }

  public getString(key: string): string {
    if (key && key in this.resourceStrings) {
      return this.resourceStrings[key];
    }

    return key;
  }

  public getPayFrequency(frequencyId: number, frequencyCaption?: string): string {
    if (frequencyCaption != null && frequencyCaption != '') {
      return frequencyCaption;
    }
    switch (frequencyId) {
      case 0:
        return this.getString('Aon.PayFrequency.Daily');
      case 1:
        return this.getString('Aon.PayFrequency.Weekly');
      case 2:
        return this.getString('Aon.PayFrequency.BiWeekly');
      case 3:
        return this.getString('Aon.PayFrequency.SemiMonthly');
      case 4:
        return this.getString('Aon.PayFrequency.Monthly');
      case 5:
        return this.getString('Aon.PayFrequency.Quarterly');
      case 6:
        return this.getString('Aon.PayFrequency.SemiAnnual');
      case 7:
        return this.getString('Aon.PayFrequency.Annual');
      case 9:
        return this.getString('Aon.PayFrequency.OneTime');
      case 10:
        return this.getString('Aon.PayFrequency.FourWeekly');
      default:
        return '';
    }
  }

  public getFrequency(frequencyId: number): string {
    switch (frequencyId) {
      case 0:
        return this.getString('Aon.Frequency.Day');
      case 1:
        return this.getString('Aon.Frequency.Week');
      case 4:
        return this.getString('Aon.Frequency.Month');
      case 7:
        return this.getString('Aon.Frequency.Year');
      default:
        return '';
    }
  }

  protected signalUnsubscribe() {
    // Emit something to stop all Observables
    this.unsubscribe.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe.complete();
  }

  protected transformDate(date: string | number | Date): string | number | Date {
    return this.helperService.formatDate(date);
  }

  public showSuccess(tpl: string | TemplateRef<any>): void {
    let toastService = this.injectorImp.get(ToastService);
    toastService.show(tpl, { classname: 'bg-success text-light', delay: 10000 });
  }

  public showDanger(tpl: string | TemplateRef<any>): void {
    let toastService = this.injectorImp.get(ToastService);
    toastService.show(tpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  protected getCommonTextParameters(): Record<string, unknown> {
    let result = {
      baseUrl: this.baseUrl
    } as Record<string, unknown>;

    return result;
  }

  public goHome(): void {
    let router = this.injectorImp.get(Router);
    router.navigate([this.baseUrl + '/home']);
  }

  protected getClientCode() {
    let enableVanityURLForMultiTenant: boolean = sessionStorage.getItem('enableVanityURLForMultiTenant') === 'true';
    let clientCode = sessionStorage.getItem('clientCode');
    if (enableVanityURLForMultiTenant) {
      return clientCode;
    }

    return null;
  }

  public isQuoteRoute() {
    return this.helperService.isQuoteRoute();
  }

  public isBenefitRoute() {
    return this.helperService.isBenefitRoute();
  }

  public logPageView(sensitiveQueryString: boolean = false) {
    if (!this.isPageViewLogged && !this.analyticsService.pageViewLogged()) {
      let pageDetails = {
        'event': 'aon-page-view',
        'routerurl': this.analyticsService.getDataLayerValue('routerurl'),
        'routertitle': this.analyticsService.getDataLayerValue('routertitle'),
        'content-loading-time': this.analyticsService.navigationElapsedTime(),
        'pageViewLogged': true
      };
      this.analyticsService.recordPageView(pageDetails);
      this.isPageViewLogged = true;
      if (pageDetails.routerurl != '/') {
        this.analyticsService.trackPageView(pageDetails, sensitiveQueryString);
      }
    }
  }

  public logCustomEvent(eventCode: string, isCompleted: boolean = false, additonalData?: string) {
    if (!isCompleted) {
      let eventDetails = {
        'event': 'aon-event-start',
        'eventKey': eventCode,
        'routerurl': "Event/" + eventCode,
        'event-time': Date.now(),
      };
      this.analyticsService.recordPageView(eventDetails);
    } else {
      let eventCompleteTime = Date.now() - Number(this.analyticsService.getDataLayerValueByEventKey(eventCode, "event-time"));
      let eventDetails = {
        'event': 'aon-event-end',
        'eventKey': eventCode,
        'routerurl': "Event/" + eventCode,
        'routertitle': additonalData,
        'event-time': Date.now(),
        'content-loading-time': eventCompleteTime,
        'pre-navigation-event-time': eventCompleteTime,
      };
      this.analyticsService.recordPageView(eventDetails);
    }
  }
}
