import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DynamicFieldBase } from '../models/dynamicFieldBase.model';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
@Component({
  selector: 'aon-dynamic-input',
  templateUrl: './dynamic-input.component.html'
})
export class DynamicInputComponent extends TBSBaseComponent implements OnInit {
  @Input() formInputField: DynamicFieldBase<string>;
  @Input() IsPassword: boolean;
  @Input() form: FormGroup;

  constructor(private readonly injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Validation.Required.ErrorMessage'
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }

  minDate = '1920-01-01';
  maxDate = new Date().getFullYear() + '-12-31';

  get currentControl() {
    if (this.form) {
      return this.form.controls[this.formInputField.key];
    } else {
      return null;
    }
  }

  get isValid() {
    if (this.form) {
      return this.form.controls[this.formInputField.key].valid;
    } else {
      return false;
    }
  }


}

