import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SableFormDataService } from '@aon/sable-forms';
import { BootstrapRequestContext } from 'tbs-typings';
import { GlobalObjectsService } from '../services/global-objects.service';

@Injectable()
export class AppFormDataService extends SableFormDataService {
  private readonly apiBaseUrl: string;
  constructor(
    private readonly httpClient: HttpClient,
    @Inject('BootstrapRequestContext') private readonly bootstrapRequestContext: BootstrapRequestContext,
    private readonly globalojectService: GlobalObjectsService
  ) {
    super();
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }

  getMetadata() {
    const metadata = {
      version: 1,
      validationRules: [
        {
          name: 'required',
          defaultMessage: '{data.label} is required.',
          appliesTo: ['string', 'lookup', 'integer']
        },
        {
          name: 'minLength',
          defaultMessage: '{data.label} requires {error.requiredLength} characters, but only {error.actualLength} were entered!',
          appliesTo: ['string'],
          parameters: [
            {
              name: 'minLength',
              type: 'integer'
            }
          ]
        },
        {
          name: 'maxLength',
          defaultMessage: '{data.label} length cannot exceed {error.requiredLength} characters.',
          appliesTo: ['string'],
          parameters: [
            {
              name: 'maxLength',
              type: 'integer'
            }
          ]
        }
      ]
    };

    return of(metadata);
  }

  private sableFromTemplate_RecordID;

  private getAPI(url: string): any {
    let urlCall = this.apiBaseUrl + url;

    return this.httpClient.get<any>(urlCall).pipe(
      map((result) => {
        result.template = result.template?.template;
        this.sableFromTemplate_RecordID = result['template_RecordID'];
        return result;
      })
    );
  }

  getFormConfig(id: string, formInstanceId?: string) {
    let url = 'sableform/' + id;
    if (formInstanceId) {
      url += '/instance/' + formInstanceId;
    }
    let result = this.getAPI(url);
    return result;
  }

  getDataSource(data: any) {
    if (typeof data.source === 'string' && data.source.includes('kenticoData')) {
      return of({
        clientCode: this.bootstrapRequestContext.clientCode
      });
    } else {
      let url = 'datasource/';
      let urlCall = this.apiBaseUrl + url;
      data['EmployeeBenefitID'] = this.globalojectService.additionalContextData.employeeBenefitID;

      // not sure if need to build up separate object or what we get is in good enough state??
      /*
        let keys = Object.keys(data);
        let dataArray = [];
        for (let keyData in keys) {
          dataArray.push({ Key: keys[keyData], Value: data[keys[keyData]] });
        }
        let dataSourceRequest = {
          formRecordID: id,
          SableFormTemplate_RecordID: this.sableFromTemplate_RecordID,
          EmployeeBenefitID: this.globalojectService.additionalContextData.employeeBenefitID,
          EmployeeRelationship_RecordID: this.globalojectService.additionalContextData.employeeRelationship_RecordID,
          formdata: dataArray
        }
      */
      return this.httpClient.post<any>(urlCall, data);
    }
  }

  saveFormData(id: string, data: any): Observable<boolean> {
    let url = 'sableform/instance';
    let urlCall = this.apiBaseUrl + url;

    const parameter = {
      formRecordID: id,
      SableFormTemplate_RecordID: this.sableFromTemplate_RecordID,
      EmployeeBenefitID: this.globalojectService.additionalContextData.employeeBenefitID,
      EmployeeRelationship_RecordID: this.globalojectService.additionalContextData.employeeRelationship_RecordID,
      Data: data
    };
    return this.httpClient.post<boolean>(urlCall, parameter).pipe(
      map((result) => {
        return result['didSave'];
      })
    );
  }
}
