<ngb-progressbar class="modal-only" [type]="currentStep == 4? 'success':'primary'" [value]="currentStep * 25">
</ngb-progressbar>

<div class="row">
  <div class="container" [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
    <div class="express-registration">
      <div class="step-info" [ngClass]="(isRegistrationModal)?'':'m-b-12'">
        <div class="modal-only" [ngClass]="(isRegistrationModal)?'m-t-28 m-b-12':''">
          @if (currentStep <= 3) {
            <p class="sm strong d-inline">
              {{resourceStrings['Aon.Registration.Step']}} {{currentStep}}
              <span
              [innerHTML]="resourceStrings['Aon.Registration.StepOf'] | textTransform : {totalSteps : (stepTitles.length)} | safeHtml"></span>
            </p>
          }
          @if (currentStep == 4) {
            <p class="sm d-inline strong">
              <span class="m-r-4 successstep"><i class="fal fa-check-circle validation-success"
              aria-hidden="true"></i></span>{{resourceStrings['Aon.Common.Success']}}
            </p>
          }
          <p class="sm d-inline"> {{stepTitles[currentStep - 1]}}</p>
        </div>
        <div class="step-email">
          <div class="img-sleeve">
            <img src="/assets/images/account/curves4.svg" alt="" />
          </div>
          <form [formGroup]="loginForm">
            @if (currentStep ==1) {
              <h1 class="step-title page-only" [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml">
              </h1>
              <h3 class="step-title modal-only m-b-12"
                [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml">
              </h3>
              <p class="lg page-only" [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml">
              </p>
              <p class="modal-only m-b-20"
              [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml"></p>
              <h3 class="page-only m-t-40">
                {{resourceStrings['Aon.Registration.PersonalInformation']}}
              </h3>
              <div class="row">
                <div class="col-12 col-md-6">
                  <label for="username"
                  [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Account.Email']}}</label>
                  @if (f.username.invalid && (f.username.dirty || f.username.touched)) {
                    <div
                      class="alert contextual error">
                      @if (f.username.errors.required) {
                        <div role="alert">
                          <i class="fas fa-exclamation-triangle"
                          aria-hidden="true"></i>{{resourceStrings['Aon.Account.EmailIsRequired']}}
                        </div>
                      }
                      @if (!f.username.errors.required) {
                        <div>
                          <i class="fas fa-exclamation-triangle"
                          aria-hidden="true"></i>{{resourceStrings['Aon.Account.InvalidEmail']}}
                        </div>
                      }
                    </div>
                  }
                  <div class="form-group p-l-0 p-r-0 col-11 col-md-12">
                    <input type="text" formControlName="username" id="username" class="m-b-8"
                      [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
                    </div>
                    <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-32'">
                      @if (errorMessages.length>0) {
                        <div class="alert contextual error m-b-32">
                          <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                          @for (errorMessage of errorMessages; track errorMessage) {
                            <span [innerHTML]="errorMessage | safeHtml"></span>
                          }
                        </div>
                      }
                      <button (click)='submitForm()' class="login-submit spinner"
                        [disabled]="f.username.pristine || f.username.invalid && (f.username.dirty || f.username.touched) || (isAccountExists && !isExpressRegistered)"
                      [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
                      @if (loading) {
                        <span class="spinner-border spinner-border-sm"></span>
                      }
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm p-r-5">
                      {{resourceStrings['Aon.Account.HaveAccount']}}
                    </p>
                    <a class="sm help" [routerLink]="[baseUrl+'/login']">{{resourceStrings['Aon.Login.LoginText']}}</a>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                      [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                    </p>
                    <a [routerLink]="[baseUrl+'/home/help']" class="sm help"
                    [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
                  </div>
                </div>
              }

              @if (currentStep == 2) {
                <div class="step-username">
                  <div class="row">
                    <div class="img-sleeve">
                      <img src="/assets/images/account/curves4.svg" alt="" />
                    </div>
                    <div class="col-12">
                      <h1 class="step-title page-only"
                        [innerHTML]="resourceStrings['Aon.Registration.EnterOneTimePassword'] | safeHtml">
                      </h1>
                      <h3 class="step-title modal-only m-b-12"
                        [innerHTML]="resourceStrings['Aon.Registration.EnterOneTimePassword'] | safeHtml">
                      </h3>
                      <p class="lg m-b-40 page-only"
                        [innerHTML]="resourceStrings['Aon.Registration.OneTimePasswordTips'] | safeHtml">
                      </p>
                      <p class="m-b-20 modal-only"
                        [innerHTML]="resourceStrings['Aon.Registration.OneTimePasswordTips'] | safeHtml">
                      </p>
                    </div>
                  </div>
                  <div class="form-group" [ngClass]="(isRegistrationModal)?'m-b-0':''">
                    <div [ngClass]="(isRegistrationModal)?'':'col-12 col-md-9'">
                      <label for="password"
                      [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.OneTimePassword']}}</label>
                      @if (f.password.invalid && (f.password.dirty || f.password.touched)) {
                        <div
                          class="alert contextual error">
                          @if (f.password.errors.required) {
                            <div role="alert">
                              <i class="fas fa-exclamation-triangle"
                              aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                            </div>
                          }
                        </div>
                      }
                      <div class="form-group p-l-0 p-r-0 col-11 col-md-12">
                        <aon-input-password formControlName="password" [id]="'password'"
                          [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">
                        </aon-input-password>
                        <div class="d-flex align-items-center m-t-8 resend-code">
                          <a href="javascript:void(0);" (click)="getOTP()" role="button" class="m-r-8 btn"
                            [ngClass]="isResendCodeButtonDisabled? 'disabled':''">{{resourceStrings['Aon.Login.ResendCode']}}</a><span>{{countDownTimerForDisplay}}</span>
                          </div>
                        </div>
                        <ng-container>
                          @if (showPrivacyPolicySection) {
                            <div tabindex="0" (click)="acceptPrivacyPolicy()" class="checkbox-wrapper"
                              >
                              <input type="checkbox" [disabled]="!isPrivacyPolicyLinkClicked"
                                [checked]="isPrivacyPolicyAccepted"
                                [attr.aria-label]="resourceStrings['Aon.Login.PrivacyPolicyReadClaim']" />
                                <label class="secondary">
                                  <span class="check-toggle" [ngClass]="{'error':(!isPrivacyPolicyAccepted)}"></span>
                                  <span class="label content">{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimBeforeLink']}}<a
                                    href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                                  target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimAfterLink']}}<a
                                  href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                                target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a></span>
                              </label>
                            </div>
                          }
                          @if (showMarketingPreferenceSection && employeeServiceCommsPreferences?.length > 0) {
                            <div
                              (click)="isMarketingSelectionSel=!isMarketingSelectionSel" tabindex="0" class="checkbox-wrapper"
                              [ngClass]="(isRegistrationModal)?'m-b-32':''">
                              <input type="checkbox" [checked]="isMarketingSelectionSel"
                                [attr.aria-label]="resourceStrings['Aon.Login.MarketingSelection']" />
                                <label class="secondary">
                                  <span class="check-toggle" [ngClass]="{'error':(!isMarketingSelectionSel)}"></span>
                                  <span class="label content"
                                  [innerHTML]="resourceStrings['Aon.Login.MarketingSelection'] | safeHtml"></span>
                                </label>
                              </div>
                            }
                          </ng-container>
                          <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-32'">
                            @if (errorMessages.length>0) {
                              <div class="alert contextual error m-b-32">
                                <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                                @for (errorMessage of errorMessages; track errorMessage) {
                                  <span [innerHTML]="errorMessage | safeHtml"></span>
                                }
                              </div>
                            }
                            <button (click)='submitForm()' class="col-md-3 col-12 registration-submit-2"
                            [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 d-flex col-12 align-items-baseline">
                            <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                              [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                            </p>
                            <a [routerLink]="[baseUrl+'/home/help']" class="sm"
                            [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </form>
                @if (currentStep == 3) {
                  <div class="step-create-password">
                    <div class="row">
                      <div class="img-sleeve">
                        <img src="/assets/images/account/curves4.svg" alt="" />
                      </div>
                      <div class="col-12">
                        <h1 class="step-title page-only "
                        [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></h1>
                        <h3 class="step-title modal-only m-b-12"
                        [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></h3>
                        <p class="lg m-b-40 page-only"
                        [innerHTML]="resourceStrings['Aon.Registration.ChooseUserName'] | safeHtml"></p>
                        <p class="m-b-20 modal-only"
                        [innerHTML]="resourceStrings['Aon.Registration.ChooseUserName'] | safeHtml"></p>
                      </div>
                    </div>
                    <div class="form-row">
                      <div [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
                        <label class="m-b-4" [innerHTML]="resourceStrings['Aon.Registration.PrimaryEmail'] | safeHtml"></label>
                        <label class="secondary m-b-20">{{NewUserName}}</label>
                        <div class="sub-section">
                          <h3 class="m-b-12 page-only" [innerHTML]="resourceStrings['Aon.Login.Password'] | safeHtml"></h3>
                          <h5 class="m-b-12 modal-only" [innerHTML]="resourceStrings['Aon.Login.Password'] | safeHtml"></h5>
                          <app-new-password [(NewPassword)]="NewPassword" [(NewPasswordRepeat)]="NewPasswordRepeat"
                          [(IsValid)]="IsNewPasswordValid" [IsResetSuccess]="PasswordRestSuccess"></app-new-password>
                        </div>
                        <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-32'">
                          @if (errorMessages.length>0) {
                            <div class="alert contextual error m-b-32">
                              <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                              @for (errorMessage of errorMessages; track errorMessage) {
                                <span [innerHTML]="errorMessage | safeHtml"></span>
                              }
                            </div>
                          }
                          <button (click)='submitForm()' class="login-submit spinner" [disabled]="!IsNewPasswordValid"
                          [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex col-12 align-items-baseline">
                        <p class="sm p-r-5">
                          {{resourceStrings['Aon.Account.HaveAccount']}}
                        </p>
                        <a [routerLink]="[baseUrl+'/login']">{{resourceStrings['Aon.Login.LoginText']}}</a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex col-12 align-items-baseline">
                        <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                          [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                        </p>
                        <a [routerLink]="[baseUrl+'/home/help']" class="sm help"
                        [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
                      </div>
                    </div>
                  </div>
                }
                @if (currentStep == 4) {
                  <div class="step-success">
                    <div class="row">
                      <div class="img-sleeve">
                        <img src="/assets/images/account/curves4.svg" alt="" />
                      </div>
                      <div class="col-12">
                        <h1 class="step-title page-only ">
                          {{ resourceStrings['Aon.Registration.Welcome'] }} {{resourceStrings['Aon.Registration.ClientName']}},
                          {{FirstName}}
                        </h1>
                        <h3 class="step-title modal-only m-b-12">
                          {{ resourceStrings['Aon.Registration.Welcome'] }} {{resourceStrings['Aon.Registration.ClientName']}},
                          {{FirstName}}!
                        </h3>
                        <p class="lg m-b-20 page-only"
                          [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistration'] | safeHtml">
                        </p>
                        <p class="lg m-b-40 page-only"
                        [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistrationAdditionalInfo'] | safeHtml"></p>
                        <p class="modal-only"
                          [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistration'] | safeHtml">
                        </p>
                        <p class="m-b-32 modal-only"
                        [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistrationAdditionalInfo'] | safeHtml"></p>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12">
                        <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-40'">
                          <button (click)='submitForm()' class="registration-submit-3"
                          [innerHTML]="resourceStrings['Aon.Registration.BackToHome'] | safeHtml"></button>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex col-12 align-items-baseline">
                        <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                          [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                        </p>
                        <a [routerLink]="[baseUrl+'/home/help']" class="sm help"
                        [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>