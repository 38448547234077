<div class="login-main d-lg-none"
  [ngStyle]="{'content': (loginImagePath !== '' && !mfaInProgress) ? 'url(' + loginImagePath + ')' : 'none'}">
</div>
@if (showSsoErrorMsg && this.uiConfig.uiConfiguration.enableExternalLoginPage) {
  <div>
    <div class="alert banner error">
      <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorLoginToSite'] | safeHtml"></span>
      <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorEmployerMessage'] | safeHtml"></span>
      <a href="javascript:void(0)" (click)="callExternalApi()">{{resourceStrings['Aon.Navigation.Login']}}</a>
      <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorVisitAgain'] | safeHtml">
      </span>
    </div>
  </div>
} @else {
  @if (((this.uiConfig === undefined) || !this.uiConfig.uiConfiguration.enableExternalLoginPage || this.isMonitor)&&!mfaInProgress) {
    <div class="d-none d-lg-block login inverse"
      [ngStyle]="{'background-image': loginImagePath !== '' ? 'url(' + loginImagePath + ')' : 'none'}"
      >
      <ng-container *ngTemplateOutlet="Login; context: {idName: ''}"></ng-container>
    </div>
  }
  @if (((this.uiConfig === undefined) || !this.uiConfig.uiConfiguration.enableExternalLoginPage  || this.isMonitor)&&!mfaInProgress) {
    <div class="d-lg-none d-block login inverse"
      >
      <ng-container *ngTemplateOutlet="Login; context: {idName: 'mv'}"></ng-container>
    </div>
  }
  @if (mfaInProgress) {
    <div class="mfa inverse"
      [ngStyle]="{'background-image': (loginImagePath !== '' && !isMobileView) ? 'url(' + loginImagePath + ')' : 'none'}">
      <div class="mfa-sleeve">
        <app-mfa-authentication [inLoginProcess]="true" (afterPassedVerify)="afterSubmitSuccess()"
        (backToLogIn)="backToLogIn()"></app-mfa-authentication>
      </div>
    </div>
  }
}
<ng-template #Login let-idName="idName">
  <div class="login-sleeve">
    <div class="page-content-sleeve">
      @if (!isLoginFunctionProvided(resourceStrings['Aon.Common.NoAccountFunctionProvided'])) {
        <div class="row">
          <div class="col-12">
            <div class="alert banner info" role="alert" aria-live="polite">
              <div class="title">
                <div class="title-content">
                  <i class="fas fa-bell" aria-hidden="true"></i>
                  {{resourceStrings['Aon.Common.BannerTitle.Info']}}
                </div>
              </div>
              <p [innerHTML]="resourceStrings['Aon.Common.NoAccountFunctionProvided'] | striphtml"></p>
            </div>
          </div>
        </div>
      }

      @if (isLoginFunctionProvided(resourceStrings['Aon.Common.NoAccountFunctionProvided'])) {
        <div class="row">
          <div class="container col-12 col-md-6">
            @if (isNormalLogin()) {
            <div class="row">
              <div class="col-12">
                <div class="img-sleeve">
                  <img src="/assets/images/account/curves4.svg" alt="" />
                </div>
                <h1 [ngClass]="enableRegistration()?'m-b-8':'m-b-32'">{{resourceStrings['Aon.Login.LoginTitle']}}</h1>
              </div>
            </div>
            @if (enableRegistration()) {
            <!-- @if (uiConfig.uiConfiguration.selfRegistrationEnabled) { -->
            <div class="row">
              <div class="col-12 d-flex">
                <p class="xl m-b-32">
                  {{resourceStrings['Aon.Login.IsNewUser']}} <a class="ff-inherit"
                                                                [routerLink]="[baseUrl+'/account/registration']">{{resourceStrings['Aon.Login.CreateAccount']}}</a>
                </p>
              </div>
            </div>
            <!-- } -->
            }
            @if (uiConfig?.uiConfiguration?.showLoginWelcomeText) {
            <div class="row">
              <div class="col-12 d-flex">
                <h5>
                  {{resourceStrings['Aon.Login.Welcome']}}
                </h5>
              </div>
            </div>
            }
            @if (isFromForgotPassword || submitted && error || showSsoErrorMsg) {
            <div class="row">
              <div class="col-12">
                @if (isFromForgotPassword) {
                <div AlertBannerCloseButtonFocus class="alert banner success" id="{{idName}}change-successful-message"
                     role="alert" aria-live="polite">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-check-circle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Success']}}
                    </div>
                    <i class="fal fa-times focus-visibility" tabindex="0" role="button"
                       (keydown.enter)="helperService.closeInfo('change-successful-message')"
                       (click)="helperService.closeInfo('change-successful-message')"
                       [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="resourceStrings['Aon.Common.ChangePwdSuccessfully'] | safeHtml"
                     [attr.aria-label]="resourceStrings['Aon.Common.ChangePwdSuccessfully']"></p>
                </div>
                }
                @if (submitted && error) {
                <div AlertBannerCloseButtonFocus class="alert banner error" id="{{idName}}submit-error-message"
                     role="alert">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i id="submit-error-message-closebtn" class="fal fa-times focus-visibility" tabindex="0"
                       role="button" (keydown.enter)="helperService.closeInfo('submit-error-message')"
                       (click)="helperService.closeInfo('submit-error-message')"
                       [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="error | safeHtml" [attr.aria-label]="resourceStrings['Aon.Common.BannerTitle.Error']">
                  </p>
                </div>
                }
                @if (showSsoErrorMsg) {
                <div AlertBannerCloseButtonFocus class="alert banner error" id="{{idName}}sso-error-message"
                     role="alert">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.SSO.SsoLoginError']}}
                    </div>
                    <i id="sso-error-message-closebtn" class="fal fa-times focus-visibility" tabindex="0" role="button"
                       (keydown.enter)="helperService.closeInfo('sso-error-message')"
                       (click)="helperService.closeInfo('sso-error-message')"
                       [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="error | safeHtml" [attr.aria-label]="resourceStrings['Aon.Common.BannerTitle.Error']">
                  </p>
                </div>
                }
              </div>
            </div>
            }
            <div class="row">
              <form [formGroup]="loginForm" class="col-12" autocomplete="off">
                @if (!uiConfig?.uiConfiguration?.loginByMitIDOnly) {
                <div class="form-group">
                  <label for="{{idName}}username" [ngClass]="{ 'error': isFieldValid('username') }">
                    {{resourceStrings['Aon.Login.UserName']}}
                  </label>
                  @if (isFieldValid('username')) {
                  <div class="alert contextual error">
                    @if (f.username.errors.required) {
                    <div id="{{idName}}error-message-userName-is-required"
                         role="alert">
                      <i class="fas fa-exclamation-triangle"
                         aria-hidden="true"></i>{{resourceStrings['Aon.Login.UserNameIsRequired']}}
                    </div>
                    }
                  </div>
                  }
                  <input aria-describedby="UserNameIsRequired" type="text" formControlName="username"
                         id="{{idName}}username" [ngClass]="{ 'error': isFieldValid('username') }" />
                  @if (!isFromForgotPassword && uiConfig?.uiConfiguration?.showForgotUsernameLinkonLoginPage) {
                  <p class="sm m-t-8">
                    <a [routerLink]="[baseUrl+'/account/forgotusername']">{{resourceStrings['Aon.Login.ForgotUserName']}}</a>
                  </p>
                  }
                </div>
                }
                @if (!uiConfig?.uiConfiguration?.loginByMitIDOnly) {
                <div class="form-group">
                  <label for="{{idName}}password" [ngClass]="{ 'error': isFieldValid('password') }">
                    {{resourceStrings['Aon.Login.Password']}}
                  </label>
                  @if (isFieldValid('password')) {
                  <div class="alert contextual error">
                    @if (f.password.errors.required) {
                    <div id="{{idName}}error-message-password-is-required"
                         role="alert">
                      <i class="fas fa-exclamation-triangle"
                         aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                    </div>
                    }
                  </div>
                  }
                  <aon-input-password formControlName="password" [id]="idName+'password'"
                                      [aria-describedby]="'PasswordIsRequired'" [ngClass]="{ 'error': isFieldValid('password') }"
                                      [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']">
                  </aon-input-password>
                  @if (!isFromForgotPassword) {
                  <p class="sm m-t-8">
                    <a [routerLink]="[baseUrl+'/account/forgotpassword']">{{resourceStrings['Aon.Login.Forgotpassword']}}</a>
                  </p>
                  }
                </div>
                }
                <div class="form-group m-b-20">
                  <div class="row">
                    <div class="col-md-6">
                      <button id="login-submit-btn" class="login-submit spinner prominent" type="submit"
                              (click)='onSubmit()'>
                        {{resourceStrings['Aon.Login.LoginText']}}
                        @if (loading) {
                        <span class="spinner-border spinner-border-sm m-l-4"></span>
                        }
                      </button>
                    </div>
                  </div>
                </div>
                @if (isLoginSuccess) {
                <div role="alert" class="visually-hidden">
                  {{resourceStrings['Aon.Login.LoginSuccessMessage']}}
                </div>
                }
                @if (isExpressCheckoutEnabled()) {
                <div class="row">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm">
                      {{resourceStrings['Aon.Login.NoPassword']}} <a href="javascript:void(0);"
                                                                     (click)='goToExpressLogin()'>{{resourceStrings['Aon.Login.LoginWithOTP']}}</a>
                    </p>
                  </div>
                </div>
                }
                @if (uiConfig.uiConfiguration.enableCustomLoginLinks && customLoginLinks) {
                <div>
                  @for (cstmLink of customLoginLinks; track cstmLink; let cstmLoginLinkIndex = $index) {
                  @if (cstmLink.contentItems) {
                  <div class="aon-custom-login-links">
                    @for (cstmLinkContentItem of cstmLink.contentItems; track cstmLinkContentItem; let cstmLinkIndex = $index) {
                    <div id="{{idName}}customLoginLinks-{{cstmLinkIndex}}">
                      @if (cstmLinkContentItem?.contentItemBody && cstmLinkIndex <=1) {
                      <p class="sm"
                         [innerHTML]="cstmLinkContentItem?.contentItemBody | striphtml: whiteListTagsForCustomLogIn">
                      </p>
                      }
                    </div>
                    }
                  </div>
                  }
                  }
                </div>
                }
                <div class="row">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm m-b-0">
                      {{resourceStrings['Aon.Account.NeedHelp']}} <a [routerLink]="[baseUrl+'/home/help']">{{resourceStrings['Aon.Login.ContactUs']}}</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
            }
            @if (!isNormalLogin()) {
            <div class="express-login">
              <div class="alert contextual info col-12">
                <div class="message">
                  <i class="fas fa-bell" aria-hidden="true"></i>
                  <p>{{resourceStrings['Aon.Login.GuestLoginInfo']}}</p>
                </div>
              </div>
              <div class="email-info m-b-20 col-12">
                {{resourceStrings['Aon.Login.GuestLoginEnterEmailToReceiveOTP']}}
              </div>
              <div class="">
                <form [formGroup]="loginForm">
                  <div class="form-group col-12 col-lg-6">
                    @if (curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress) {
                    <label for="{{idName}}username"
                           [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Account.Email']}}</label>
                    @if (f.username.invalid && (f.username.dirty || f.username.touched)) {
                    <div class="alert contextual error">
                      @if (f.username.errors.required) {
                      <div role="alert">
                        <i class="fas fa-exclamation-triangle"
                           aria-hidden="true"></i>{{resourceStrings['Aon.Account.EmailIsRequired']}}
                      </div>
                      }
                      @if (!f.username.errors.required) {
                      <div>
                        <i class="fas fa-exclamation-triangle"
                           aria-hidden="true"></i>{{resourceStrings['Aon.Account.InvalidEmail']}}
                      </div>
                      }
                    </div>
                    }
                    <input type="text" formControlName="username" id="{{idName}}username"
                           [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
                    }
                    @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
                    <div>
                      <label>
                        {{resourceStrings['Aon.Account.Email']}}<i class="edit-email fal fa-edit m-l-10 focus-visibility" role="button" tabindex="0"
                                                                   (keyup.enter)="editEmail()" (click)="editEmail()"
                                                                   [attr.aria-label]="resourceStrings['Aon.Common.Edit']"></i>
                      </label>
                      <span>{{f.username.value}}</span>
                    </div>
                    }
                  </div>
                  @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
                  <div class="form-group m-b-20 col-12 col-lg-6">
                    <label for="{{idName}}password"
                           [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.OneTimePassword']}}</label>
                    @if (f.password.invalid && (f.password.dirty || f.password.touched)) {
                    <div class="alert contextual error">
                      @if (f.password.errors.required) {
                      <div id="{{idName}}PasswordIsRequired" role="alert">
                        <i class="fas fa-exclamation-triangle"
                           aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                      </div>
                      }
                    </div>
                    }
                    <aon-input-password formControlName="password" [id]="idName+'password'"
                                        [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
                                        [aria-describedby]="'PasswordIsRequired'"
                                        [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
                    <div class="d-flex align-items-center m-t-8 resend-code">
                      <a href="javascript:void(0);" (click)="getOTP()" role="button" class="login-get-otp m-r-8 btn"
                         [ngClass]="isResendCodeButtonDisabled? 'disabled':''">{{resourceStrings['Aon.Login.ResendCode']}}</a><span>{{countDownTimerForDisplay}}</span>
                    </div>
                  </div>
                  }
                  @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
                  @if (showPrivacyPolicySection) {
                  <div tabindex="0" (click)="acceptPrivacyPolicy()" class="checkbox-wrapper p-l-15 p-r-15">
                    <input type="checkbox" [disabled]="!isPrivacyPolicyLinkClicked"
                           [checked]="isPrivacyPolicyAccepted"
                           [attr.aria-label]="resourceStrings['Aon.Login.PrivacyPolicyReadClaim']" />
                    <label class="secondary">
                      <span class="check-toggle" [ngClass]="{'error':(!isPrivacyPolicyAccepted)}"></span>
                      <span class="label content">
                        {{resourceStrings['Aon.Login.PrivacyPolicyReadClaimBeforeLink']}}<a href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                                                                                            target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimAfterLink']}}<a href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()" rel="noopener norefferrer"
                                                                                                                                                                                                                                        target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>
                      </span>
                    </label>
                  </div>
                  }
                  @if (showMarketingPreferenceSection && employeeServiceCommsPreferences?.length > 0 && uiConfig.uiConfiguration.enableMarketingConsent) {
                  <div (click)="isMarketingSelectionSel=!isMarketingSelectionSel" tabindex="0"
                       class="checkbox-wrapper p-l-15 p-r-15">
                    <input type="checkbox" [checked]="isMarketingSelectionSel"
                           [attr.aria-label]="resourceStrings['Aon.Login.MarketingSelection']" />
                    <label class="secondary">
                      <span class="check-toggle" [ngClass]="{'error':(!isMarketingSelectionSel)}"></span>
                      <span class="label content"
                            [innerHTML]="resourceStrings['Aon.Login.MarketingSelection'] | safeHtml"></span>
                    </label>
                  </div>
                  }
                  }
                  @if (submitted && error) {
                  <div class="col-12 col-lg-6">
                    @if (error) {
                    <div class="alert contextual error mt-3 mb-2" role="alert">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{error}}
                    </div>
                    }
                  </div>
                  }
                </form>
              </div>
              <div class="m-b-40 col-12 col-lg-6">
                @if (curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress) {
                <div>
                  <button class="login-submit login-generate-password spinner"
                          [disabled]="f.username.pristine || (f.username.invalid && (f.username.dirty || f.username.touched))"
                          (click)='getOTP()'>
                    {{resourceStrings['Aon.Login.GeneratePassword']}}
                    @if (loading) {
                    <span class="spinner-border spinner-border-sm"></span>
                    }
                  </button>
                </div>
                }
                @if (curExpressLoginStep == localExpressLoginSteps.GenerateOTP) {
                <div>
                  <button id="login-submit-verify-email-btn" class="login-submit login-verify-email spinner"
                          [disabled]="loading || !isPrivacyPolicyAccepted" (click)='onSubmit()'>
                    {{resourceStrings['Aon.Login.VerifyEmail']}}
                    @if (loading) {
                    <span class="spinner-border spinner-border-sm"></span>
                    }
                  </button>
                </div>
                }
              </div>
              <div class="col-12 col-lg-6">
                <div>
                  <p class="sm" id="{{idName}}not-registered">
                    {{resourceStrings['Aon.Login.HaveAccount']}} <a id="{{idName}}go-to-login" href="javascript:void(0);" (click)="goToLogin()"
                                                                    aria-labelledby="registered-go-to-login">{{resourceStrings['Aon.Login.LoginText']}}</a>
                  </p>
                </div>
                <div>
                  <p class="sm" id="{{idName}}need-help">
                    {{resourceStrings['Aon.Account.NeedHelp']}} <a id="{{idName}}contact-help" [routerLink]="[baseUrl+'/home/help']"
                                                                   aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a>
                  </p>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
