import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { UIConfigurationType } from '../models/uiconfigration.interface';
import { takeUntil } from 'rxjs/operators';
import { GlobalObjectsService } from '../../shared/services/global-objects.service'

@Component({
  selector: 'app-benefit-details-available',
  templateUrl: './benefit-details-available.component.html',
  styles: [
  ]
})
export class BenefitDetailsAvailableComponent extends TBSBaseComponent implements OnInit {
  @Input() product: any;
  @Output() benefitInfoPopup = new EventEmitter();
  @Output() goSableFlow = new EventEmitter();
  @Input() ssoBenefittag: boolean
  baseUrl: string;
  uiConfig: UIConfigurationType;

  constructor(
    private readonly injector: Injector,
    private readonly uiconfigrationService: UiconfigrationService,
    private readonly globalObjectService: GlobalObjectsService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.BenefitMatrix.ViewBenefit',
      'Aon.BenefitMatrix.Enrol',
      'Aon.SSO.BenefitTag',
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.baseUrl = this.helperService.getBaseUrl();
    this.globalObjectService.Products.subscribe(data => {
      this.checkForBenefitDetails(data);
    });
  }
  checkForBenefitDetails(data) {
    this.product.showBenefitDetails = true;

    data.forEach(benefitDetail => {
      if (benefitDetail.recordID == this.product.line_RecordID && benefitDetail.hideProductDetails) {
        this.product.showBenefitDetails = false;
      }
    });
  }

  openBenefitInfoPopup(line_RecordID, name, event) {
    event.preventDefault();
    setTimeout(() => {
      this.benefitInfoPopup.emit({ line_RecordID, name })
    }, 200);
  }

  goToSableFlow(product) {
    this.goSableFlow.emit(product);
  }

}
