<div id="enrolment-panel" [attr.aria-label]="resourceStrings['Aon.EnrolmentSummary.PageTitle.Enrolment']" role="dialog"
  class="page enrolment-summary app-content-sleeve" [ngClass]="isPrintMode ? '' : 'inverse'" (keydown.esc)="onClose()">
  <div class="page-content-sleeve enrolment-summary-content" [ngClass]="{'kendo-no-left-right-padding': isPrintMode}">
    <div class="modal-header">
      <h2 class="modal-title theme-color"
        [attr.aria-label]="resourceStrings['Aon.EnrolmentSummary.PageTitle.Enrolment']">
      {{resourceStrings['Aon.EnrolmentSummary.PageTitle.Enrolment'] }}</h2>
      <button type="button" class="close close-btn"
        [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']" (click)="onClose()"
        [ngClass]="{'ignore-from-media-print' : isPrintMode}">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    </div>
    <!-- Accessibility Loading and Loaded screen announcement -->
    <aon-spin [noDarkSpin]="true"></aon-spin>
    @if (benefitSummary && uiConfig) {
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 benefit-content-wrapper">
              <p class="m-b-30"
                [innerHTML]="isWithinTheFlow ? resourceStrings['Aon.BenefitMatrix.IntroText.Review'] : resourceStrings['Aon.BenefitMatrix.IntroText.Review.NonFlow'] | striphtml">
              </p>
            </div>
          </div>
          @if (uiConfig && uiConfig.uiConfiguration.showAnnualPayPeriodDisplayToggle) {
            <div class="row"
              [ngClass]="{'ignore-from-media-print' : isPrintMode}">
              <div class="col-12 benefit-content-wrapper">
                <div class="form-group m-b-20 d-flex">
                  <label class="m-r-20" for="costFrequencySelection">{{ resourceStrings['Aon.BenefitReview.CostFrequency']
                  }}</label>
                  <div class="aon-form-input-wrapper select flex-grow-1">
                    <select class="aon-form-input" [(ngModel)]="isPerPay" (ngModelChange)="costFrequencyChange()"
                      id="costFrequencySelection">
                      <option
                        [attr.aria-label]="resourceStrings['Aon.BenefitReview.permonth'] + ' ' + resourceStrings['Aon.Common.Selected']"
                      [ngValue]="true">{{resourceStrings['Aon.BenefitReview.permonth']}}</option>
                      <option
                        [attr.aria-label]="resourceStrings['Aon.BenefitReview.peryear'] + ' ' + resourceStrings['Aon.Common.Selected']"
                      [ngValue]="false">{{resourceStrings['Aon.BenefitReview.peryear']}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          }
          <div>
            @for (_benefitByScheme of benefitByScheme; track _benefitByScheme) {
              @if (benefitByScheme.length > 1 && (_benefitByScheme.eESpend.length>0 || _benefitByScheme.eRSpend.length>0) && (_benefitByScheme.schemeName || _benefitByScheme.schemeDescription)) {
                <div
                  >
                  @if (_benefitByScheme.schemeName) {
                    <h2 class="theme-color h4 p-t-12"
                      [attr.aria-label]="_benefitByScheme.schemeName">
                    {{_benefitByScheme.schemeName}}</h2>
                  }
                  @if (_benefitByScheme.schemeDescription) {
                    <div class="row">
                      <div class="col-12 benefit-content-wrapper">
                        <p class="m-b-12" [innerHTML]="_benefitByScheme.schemeDescription"></p>
                      </div>
                    </div>
                  }
                </div>
              }
              <div class="non-data-view-accordions">
                <ng-container
                  *ngTemplateOutlet="commonSection; context: {i: _benefitByScheme.index, employeeBenefits:_benefitByScheme.eESpend,employerBenefits:_benefitByScheme.eRSpend, isTransformNegativeEmployeeCost: _benefitByScheme.isTransformNegativeEmployeeCost, erHasTax: _benefitByScheme.erHasTax, eeHasTax: _benefitByScheme.eeHasTax, paymentSourceCount: _benefitByScheme.paymentSourceSummaries?.length, totalEmployeeCost: isPerPay ? _benefitByScheme.totalEmployeeCost_PayPeriod : _benefitByScheme.totalEmployeeCost_Annual, totalEmployerCost: isPerPay ? _benefitByScheme.totalEmployerCost_PayPeriod : _benefitByScheme.totalEmployerCost_Annual}">
                </ng-container>
                @if (_benefitByScheme.paymentSourceSummaries?.length > 0) {
                  @for (psst of _benefitByScheme.paymentSourceSummaries; track psst; let i = $index) {
                    <ng-container>
                      <div ngbAccordion #accordionyForPaymentSourceType="ngbAccordion"
                        (shown)="togglePanelPaymentSource($event, _benefitByScheme.index+'_'+i)"
                        (hidden)="togglePanelPaymentSource($event, _benefitByScheme.index+'_'+i)">
                        <div [ngbAccordionItem]="'p-payment-source-type' + i + '_' + _benefitByScheme.index"
                          #accordion="ngbAccordionItem" class="accordion-item expansion-panel">
                          <div ngbAccordionHeader>
                            <button ngbAccordionButton class="header-sleeve focus-visibility">
                              <ng-template #psstHeaderContent>
                                <span>{{ psst.name }}</span>
                                <span class="m-r-20 float-end">{{ (isPerPay ? psst.initialBalance_PayPeriod :
                                psst.initialBalance_Annual) | currency: _benefitByScheme.currencyCode }}</span>
                              </ng-template>
                              @if (!psst.allocatedBenefitItems || psst.allocatedBenefitItems?.length == 0) {
                                <span class="header-text d-flex justify-content-between"
                                  >
                                  <ng-container *ngTemplateOutlet="psstHeaderContent"></ng-container>
                                </span>
                              }
                              @if (psst.allocatedBenefitItems?.length > 0) {
                                <span class="header-text d-flex justify-content-between"
                                  [attr.aria-expanded]="openById['p-payment-source-type' + i + '_' + _benefitByScheme.index] ? true : false"
                                  tabindex="0" class="header-text"
                                  [ngClass]="{ expanded: openById['p-payment-source-type' + i + '_' + _benefitByScheme.index] }"
                                  >
                                  <ng-container *ngTemplateOutlet="psstHeaderContent"></ng-container>
                                </span>
                              }
                              <span class="header-controls ignore-from-media-print">
                                @if (!accordion.collapsed) {
                                  <i class="fal fa-chevron-up" aria-hidden="true"></i>
                                }
                                @if (accordion.collapsed) {
                                  <i class="fal fa-chevron-down" aria-hidden="true"></i>
                                }
                              </span>
                            </button>
                          </div>
                          <div ngbAccordionCollapse>
                            <div ngbAccordionBody>
                              <ng-template>
                                <ng-template #psstPanelInnerContent let-name="name" let-amount="amount"
                                  let-excludeCreditTag="excludeCreditTag">
                                  <div class="row">
                                    <div class="col-8">
                                      <p [ngClass]="{'d-flex align-items-start': !excludeCreditTag && (amount < 0)}">
                                        {{ name | striphtml }}
                                        @if (!excludeCreditTag && (amount < 0)) { <span class="tag teal-tag-wrapper">
                                        {{resourceStrings['Aon.Tag.Credit']}}</span>
                                      }
                                    </p>
                                  </div>
                                  <div class="col-4 text-end">
                                    <p>{{ amount | currency: _benefitByScheme.currencyCode }}</p>
                                  </div>
                                </div>
                              </ng-template>
                              @for (abi of psst.allocatedBenefitItems; track abi) {
                                <ng-container
                                *ngTemplateOutlet="psstPanelInnerContent; context: { name: abi.name, amount: isPerPay ? abi.allocationAmount_PayPeriod : abi.allocationAmount_Annual }"></ng-container>
                              }
                              @if (psst.allocatedBenefitItems?.length > 1) {
                                <ng-container
                                *ngTemplateOutlet="psstPanelInnerContent; context: { name: resourceStrings['Aon.Payment.Source.AllocatedSpend'], amount: isPerPay ? psst.totalAllocationAmount_PayPeriod : psst.totalAllocationAmount_Annual }"></ng-container>
                              }
                              <ng-container
                              *ngTemplateOutlet="psstPanelInnerContent; context: { name: resourceStrings['Aon.Payment.Source.RemainingBalance'], amount: isPerPay ? psst.remainingBalance_PayPeriod : psst.remainingBalance_Annual, excludeCreditTag: true }"></ng-container>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                }
              }
            </div>
          }
          <!-- Tax Code Block -->
          @if (uiConfig?.uiConfiguration?.showTaxCodeInputModelling) {
            <div class="tax-code-input-form"
              [ngClass]="{'ignore-from-media-print' : isPrintMode}">
              <div class="form-row m-t-20 m-b-20">
                @if (taxCodeInputTextErrorFlag.isRequired == true && (taxCodeInputText == '' || taxCodeInputText == null || taxCodeInputText == undefined)) {
                  <div class="alert contextual error"
                    >
                    <div role="alert">
                      <i class="fas fa-exclamation-triangle"
                      aria-hidden="true"></i>{{resourceStrings['Aon.EnrolmentSummary.TaxCode.Error.IsRequired']}}
                    </div>
                  </div>
                }
                @if (taxCodeInputTextErrorFlag.isPatternInvalid == true) {
                  <div class="alert contextual error">
                    <div role="alert">
                      <i class="fas fa-exclamation-triangle"
                      aria-hidden="true"></i>{{resourceStrings['Aon.EnrolmentSummary.TaxCode.Error.isPatternInvalid']}}
                    </div>
                  </div>
                }
                <ng-template #taxCodeInput let-id="id"><input id="{{id}}" type="text"
                  [ngClass]="{'error': ((taxCodeInputTextErrorFlag.isRequired || taxCodeInputTextErrorFlag.isPatternInvalid) && uiConfig?.uiConfiguration?.enableDarkMode === false)}"
                  (keyup)="taxCodeInputTextErrorFlag.isRequired = taxCodeInputTextErrorFlag.isPatternInvalid = false"
                  [(ngModel)]="taxCodeInputText" class="k-input-inner"
                placeholder="{{resourceStrings['Aon.EnrolmentSummary.TaxCode.PlaceHolder']}}"></ng-template>
                <div class="d-flex align-items-center w-100">
                  <label class="tax-code-label m-b-0 d-none d-md-block" for="taxCodeLabelText"
                  [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode']"></label>
                  <label class="tax-code-label m-b-0 d-md-none" for="taxCodeLabelTextMd"
                  [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode']"></label>
                  @if (resourceStrings['Aon.EnrolmentSummary.TaxCode.ToolTip'] && resourceStrings['Aon.EnrolmentSummary.TaxCode.ToolTip'] != '') {
                    <i
                      tabindex="0" class="fal fa-info-circle focus-visibility lg" placement="bottom" #popover="ngbPopover"
                      ngbPopover="{{resourceStrings['Aon.EnrolmentSummary.TaxCode.ToolTip']}}"
                      (keyup.enter)="helperService.openPop(popover)"
                      [attr.aria-label]="resourceStrings['Aon.EnrolmentSummary.TaxCode'] + ' ' + resourceStrings['Aon.Arialabel.Info']"
                    popoverClass="popover-gray" role="button"></i>
                  }
                  <div class="input-text tax-code-input m-r-10 d-none d-md-block">
                    <ng-container *ngTemplateOutlet="taxCodeInput; context: {id: 'taxCodeLabelText'}"></ng-container>
                  </div>
                  <div class="input-text tax-code-input w-100 d-md-none">
                    <ng-container *ngTemplateOutlet="taxCodeInput; context: {id: 'taxCodeLabelTextMd'}"></ng-container>
                  </div>
                  <button class="prominent tax-code-input-calculate-button d-none d-md-block"
                    (click)="calculateTaxCode(taxCodeInputText)"
                  [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode.Action.Calculate']"></button>
                </div>
                <button class="prominent tax-code-input-calculate-button m-t-20 d-md-none"
                  (click)="calculateTaxCode(taxCodeInputText)"
                [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode.Action.Calculate']"></button>
              </div>
              @if (resourceStrings['Aon.EnrolmentSummary.TaxCode.Heading'] != '' && resourceStrings['Aon.EnrolmentSummary.TaxCode.Description'] != '') {
                <div
                  >
                  <p class="strong m-b-8" [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode.Heading']"></p>
                  <p class="sm" [innerHTML]="resourceStrings['Aon.EnrolmentSummary.TaxCode.Description']"></p>
                </div>
              }
            </div>
          }
          @for (_benefitByScheme of benefitByScheme; track _benefitByScheme) {
            @if (_benefitByScheme?.isDataViewInfoHasItems) {
              <div class="data-view-accordions">
                @if (!isWithinTheFlow && benefitByScheme.length > 1 && _benefitByScheme.schemeName) {
                  <h2
                    class="theme-color h4 p-t-12" [attr.aria-label]="_benefitByScheme.schemeName">
                  {{_benefitByScheme.schemeName}}</h2>
                }
                <ng-template #relatedDataLinkPointer let-relatedDataLinkIcon="relatedDataLinkIcon"
                  let-relatedDataLinkPointer="relatedDataLinkPointer" let-relatedDataLinkTitle="relatedDataLinkTitle">
                  @if (globalObjService?.employeePaySlipTypeDataJSON && globalObjService?.employeePaySlipTypeDataJSON[relatedDataLinkPointer] != undefined) {
                    <div class="no-export m-t-20"
                      >
                      @if (relatedDataLinkIcon != '') {
                        <i class="icon-related-data-link fal sm {{relatedDataLinkIcon}}"
                        aria-hidden="true">&nbsp;</i>
                      }
                      <a [attr.aria-label]="relatedDataLinkTitle +' '+ resourceStrings['Aon.EnrolmentSummary.TaxModeler.RelatedLink']"
                        role="button"
                        (click)="openAonTaxModellerModel($event, globalObjService.employeePaySlipTypeDataJSON[relatedDataLinkPointer])"
                      class="sm" href="javascript:;">{{relatedDataLinkTitle}}</a>
                    </div>
                  }
                </ng-template>
                <sable-dataview
                [dataConfig]="parseData(_benefitByScheme?.dataViewInfo.dataJSON, relatedDataLinkPointer)"></sable-dataview>
              </div>
            }
          }
        </div>
      </div>
    </div>
  }
</div>
</div>
<ng-template #commonSection let-i="i" let-employeeBenefits="employeeBenefits" let-employerBenefits="employerBenefits"
  let-isTransformNegativeEmployeeCost="isTransformNegativeEmployeeCost" let-eeHasTax="eeHasTax" let-erHasTax="erHasTax"
  let-totalEmployeeCost="totalEmployeeCost" let-totalEmployerCost="totalEmployerCost">
  <div ngbAccordion>
    <!-- Existing Property -->
    @if (benefitEmployeeSummaryToggle(i)) {
      <div [ngbAccordionItem]="'p-employee' + i" #accordion="ngbAccordionItem" class="accordion-item expansion-panel"
        >
        <div ngbAccordionHeader [attr.aria-level]="i+1">
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span class="header-text d-flex justify-content-between">
              <span [ngClass]="{'d-flex align-items-start': totalEmployeeCost < 0}">
                {{ resourceStrings['Aon.EnrolmentSummary.BenefitSpend'] }}
                @if (totalEmployeeCost < 0) {
                  <span class="tag teal-tag-wrapper">
                    {{resourceStrings['Aon.Tag.Credit']}}
                  </span>
                }
              </span>
              <span class="m-r-20">{{ benefitService.transformNegativeCost(isTransformNegativeEmployeeCost,
                totalEmployeeCost) | currency:
                benefitSummary[i].currencyCode!=null?benefitSummary[i].currencyCode:benefitSummary[i].employeeBenefitGroups[0]?.currencyCode
                }}
              </span>
            </span>
            <span class="header-controls ignore-from-media-print">
              @if (!accordion.collapsed) {
                <i class="fal fa-chevron-up" aria-hidden="true"></i>
              }
              @if (accordion.collapsed) {
                <i class="fal fa-chevron-down" aria-hidden="true"></i>
              }
            </span>
          </button>
          <p class="sm">
            {{ resourceStrings['Aon.EnrolmentSummary.EmployeeDescription'] }}
            @if (eeHasTax) {
              &nbsp;{{ resourceStrings['Aon.Summary.TaxNote'] }}
            }
          </p>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              @for (employeeBenefit of employeeBenefits; track employeeBenefit) {
                <div class="row">
                  @if (benefitSpendFilter(employeeBenefit)) {
                    <div class="col-8">
                      <p [ngClass]="{'d-flex align-items-start': employeeBenefit.benefitValuePerPay < 0}">
                        {{ employeeBenefit.benefitName | striphtml }}
                        @if (employeeBenefit.benefitValuePerPay < 0) {
                          <span class="tag teal-tag-wrapper">
                            {{resourceStrings['Aon.Tag.Credit']}}
                          </span>
                        }
                      </p>
                    </div>
                    <div class="col-4 text-end">
                      <p>{{ benefitService.transformNegativeCost(employeeBenefit.transformNegativeEmployeeCost, isPerPay ?
                        employeeBenefit.benefitValuePerPay :
                        employeeBenefit.benefitValueAnnual) | dash |
                      currency: employeeBenefit.currencyCode }}</p>
                    </div>
                  }
                </div>
              }
            </ng-template>
          </div>
        </div>
      </div>
    }
    @if(!uiConfig.uiConfiguration.hideEmployerCostForAllBenefit){
      @if (benefitEmployerSummaryToggle(i)) {
        <div [ngbAccordionItem]="'p-employer' + (i+1)" #accordion="ngbAccordionItem" class="accordion-item expansion-panel"
          >
          <div ngbAccordionHeader>
            <button ngbAccordionButton class="header-sleeve focus-visibility">
              <span class="header-text d-flex justify-content-between">
                <span>{{ resourceStrings['Aon.EnrolmentSummary.EmployerCost'] }}</span>
                <span class="m-r-20">{{ totalEmployerCost | currency:
                  benefitSummary[i].currencyCode!=null?benefitSummary[i].currencyCode:benefitSummary[i].employeeBenefitGroups[0]?.currencyCode
                }}</span>
              </span>
              <span class="header-controls ignore-from-media-print">
                @if (!accordion.collapsed) {
                  <i class="fal fa-chevron-up" aria-hidden="true"></i>
                }
                @if (accordion.collapsed) {
                  <i class="fal fa-chevron-down" aria-hidden="true"></i>
                }
              </span>
            </button>
            <p class="sm">
              {{ resourceStrings['Aon.EnrolmentSummary.EmployerDescription'] }}
              @if (erHasTax) {
                &nbsp;{{ resourceStrings['Aon.Summary.TaxNote'] }}
              }
            </p>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                @for (employerBenefit of employerBenefits; track employerBenefit) {
                  <div class="row">
                    @if (employerBenefit.benefitValuePerPay > 0 && benefitEmployerSpendFilter(employerBenefit)) {
                      <div class="col-8">
                        <p>
                          {{ employerBenefit.benefitName | striphtml }}
                        </p>
                      </div>
                      <div class="col-4 text-end">
                        <p>{{ (isPerPay ? employerBenefit.benefitValuePerPay : employerBenefit.benefitValueAnnual) | dash |
                        currency: employerBenefit.currencyCode }}</p>
                      </div>
                    }
                  </div>
                }
              </ng-template>
            </div>
          </div>
        </div>
      }
    }
  </div>

</ng-template>